/* purgecss start ignore */
*, *::after, *::before {
  box-sizing: inherit;
}

* {
  font: inherit;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, hr {
  margin: 0;
  padding: 0;
  border: 0;
}

html {
  box-sizing: border-box;
}

body {
  background-color: var(--color-bg, white);
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, main, form legend {
  display: block;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

button, input, textarea, select {
  margin: 0;
}

.btn, .form-control, .link, .reset {
  background-color: transparent;
  padding: 0;
  border: 0;
  border-radius: 0;
  color: inherit;
  line-height: inherit;
  appearance: none;
}

select.form-control::-ms-expand {
  display: none;
}

textarea {
  resize: vertical;
  overflow: auto;
  vertical-align: top;
}

input::-ms-clear {
  display: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img, video, svg {
  max-width: 100%;
}

/* purgecss end ignore */
[data-theme] {
  background-color: var(--color-bg, white);
  color: var(--color-contrast-high, #313135);
}

:root {
  --space-unit:  1em;
  --space-xxxxs: calc(0.125 * var(--space-unit));
  --space-xxxs:  calc(0.25 * var(--space-unit));
  --space-xxs:   calc(0.375 * var(--space-unit));
  --space-xs:    calc(0.5 * var(--space-unit));
  --space-sm:    calc(0.75 * var(--space-unit));
  --space-md:    calc(1.25 * var(--space-unit));
  --space-lg:    calc(2 * var(--space-unit));
  --space-xl:    calc(3.25 * var(--space-unit));
  --space-xxl:   calc(5.25 * var(--space-unit));
  --space-xxxl:  calc(8.5 * var(--space-unit));
  --space-xxxxl: calc(13.75 * var(--space-unit));
  --component-padding: var(--space-md);
}

@media (min-width: 64rem) {
  :root {
    --space-unit:  1.25em;
  }
}

.grid {
  --gap: 0px;
  --offset: var(--gap);
  display: flex;
  flex-wrap: wrap;
}

.grid > * {
  flex-basis: 100%;
  max-width: 100%;
}

[class*="gap-xxxxs"], [class*="gap-xxxs"], [class*="gap-xxs"], [class*="gap-xs"], [class*="gap-sm"], [class*="gap-md"], [class*="gap-lg"], [class*="gap-xl"], [class*="gap-xxl"], [class*="gap-xxxl"], [class*="gap-xxxxl"], [class*="grid-gap-"], [class*="flex-gap-"] {
  margin-bottom: -0.75em;
  margin-left: -0.75em;
}

[class*="gap-xxxxs"] > *, [class*="gap-xxxs"] > *, [class*="gap-xxs"] > *, [class*="gap-xs"] > *, [class*="gap-sm"] > *, [class*="gap-md"] > *, [class*="gap-lg"] > *, [class*="gap-xl"] > *, [class*="gap-xxl"] > *, [class*="gap-xxxl"] > *, [class*="gap-xxxxl"] > *, [class*="grid-gap-"] > *, [class*="flex-gap-"] > * {
  margin-bottom: 0.75em;
  margin-left: 0.75em;
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-1 {
  flex-basis: calc( 8.33% - 0.01px - var(--gap, 0.75em));
  max-width: calc( 8.33% - 0.01px - var(--gap, 0.75em));
}

.col-2 {
  flex-basis: calc( 16.66% - 0.01px - var(--gap, 0.75em));
  max-width: calc( 16.66% - 0.01px - var(--gap, 0.75em));
}

.col-3 {
  flex-basis: calc( 25% - 0.01px - var(--gap, 0.75em));
  max-width: calc( 25% - 0.01px - var(--gap, 0.75em));
}

.col-4 {
  flex-basis: calc( 33.33% - 0.01px - var(--gap, 0.75em));
  max-width: calc( 33.33% - 0.01px - var(--gap, 0.75em));
}

.col-5 {
  flex-basis: calc( 41.66% - 0.01px - var(--gap, 0.75em));
  max-width: calc( 41.66% - 0.01px - var(--gap, 0.75em));
}

.col-6 {
  flex-basis: calc( 50% - 0.01px - var(--gap, 0.75em));
  max-width: calc( 50% - 0.01px - var(--gap, 0.75em));
}

.col-7 {
  flex-basis: calc( 58.33% - 0.01px - var(--gap, 0.75em));
  max-width: calc( 58.33% - 0.01px - var(--gap, 0.75em));
}

.col-8 {
  flex-basis: calc( 66.66% - 0.01px - var(--gap, 0.75em));
  max-width: calc( 66.66% - 0.01px - var(--gap, 0.75em));
}

.col-9 {
  flex-basis: calc( 75% - 0.01px - var(--gap, 0.75em));
  max-width: calc( 75% - 0.01px - var(--gap, 0.75em));
}

.col-10 {
  flex-basis: calc( 83.33% - 0.01px - var(--gap, 0.75em));
  max-width: calc( 83.33% - 0.01px - var(--gap, 0.75em));
}

.col-11 {
  flex-basis: calc( 91.66% - 0.01px - var(--gap, 0.75em));
  max-width: calc( 91.66% - 0.01px - var(--gap, 0.75em));
}

.col-12 {
  flex-basis: calc( 100% - 0.01px - var(--gap, 0.75em));
  max-width: calc( 100% - 0.01px - var(--gap, 0.75em));
}

.col-content {
  flex-basis: initial;
  max-width: initial;
}

.offset-1 {
  --offset: calc(8.33% + var(--gap, 0.75em));
}

.offset-2 {
  --offset: calc(16.66% + var(--gap, 0.75em));
}

.offset-3 {
  --offset: calc(25% + var(--gap, 0.75em));
}

.offset-4 {
  --offset: calc(33.33% + var(--gap, 0.75em));
}

.offset-5 {
  --offset: calc(41.66% + var(--gap, 0.75em));
}

.offset-6 {
  --offset: calc(50% + var(--gap, 0.75em));
}

.offset-7 {
  --offset: calc(58.33% + var(--gap, 0.75em));
}

.offset-8 {
  --offset: calc(66.66% + var(--gap, 0.75em));
}

.offset-9 {
  --offset: calc(75% + var(--gap, 0.75em));
}

.offset-10 {
  --offset: calc(83.33% + var(--gap, 0.75em));
}

.offset-11 {
  --offset: calc(91.66% + var(--gap, 0.75em));
}

@media (min-width: 40rem) {
  .col\@xs {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-1\@xs {
    flex-basis: calc( 8.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 8.33% - 0.01px - var(--gap, 0.75em));
  }
  .col-2\@xs {
    flex-basis: calc( 16.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 16.66% - 0.01px - var(--gap, 0.75em));
  }
  .col-3\@xs {
    flex-basis: calc( 25% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 25% - 0.01px - var(--gap, 0.75em));
  }
  .col-4\@xs {
    flex-basis: calc( 33.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 33.33% - 0.01px - var(--gap, 0.75em));
  }
  .col-5\@xs {
    flex-basis: calc( 41.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 41.66% - 0.01px - var(--gap, 0.75em));
  }
  .col-6\@xs {
    flex-basis: calc( 50% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 50% - 0.01px - var(--gap, 0.75em));
  }
  .col-7\@xs {
    flex-basis: calc( 58.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 58.33% - 0.01px - var(--gap, 0.75em));
  }
  .col-8\@xs {
    flex-basis: calc( 66.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 66.66% - 0.01px - var(--gap, 0.75em));
  }
  .col-9\@xs {
    flex-basis: calc( 75% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 75% - 0.01px - var(--gap, 0.75em));
  }
  .col-10\@xs {
    flex-basis: calc( 83.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 83.33% - 0.01px - var(--gap, 0.75em));
  }
  .col-11\@xs {
    flex-basis: calc( 91.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 91.66% - 0.01px - var(--gap, 0.75em));
  }
  .col-12\@xs {
    flex-basis: calc( 100% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 100% - 0.01px - var(--gap, 0.75em));
  }
  .col-content\@xs {
    flex-basis: initial;
    max-width: initial;
  }
  .offset-1\@xs {
    --offset: calc(8.33% + var(--gap, 0.75em));
  }
  .offset-2\@xs {
    --offset: calc(16.66% + var(--gap, 0.75em));
  }
  .offset-3\@xs {
    --offset: calc(25% + var(--gap, 0.75em));
  }
  .offset-4\@xs {
    --offset: calc(33.33% + var(--gap, 0.75em));
  }
  .offset-5\@xs {
    --offset: calc(41.66% + var(--gap, 0.75em));
  }
  .offset-6\@xs {
    --offset: calc(50% + var(--gap, 0.75em));
  }
  .offset-7\@xs {
    --offset: calc(58.33% + var(--gap, 0.75em));
  }
  .offset-8\@xs {
    --offset: calc(66.66% + var(--gap, 0.75em));
  }
  .offset-9\@xs {
    --offset: calc(75% + var(--gap, 0.75em));
  }
  .offset-10\@xs {
    --offset: calc(83.33% + var(--gap, 0.75em));
  }
  .offset-11\@xs {
    --offset: calc(91.66% + var(--gap, 0.75em));
  }
}

@media (min-width: 48rem) {
  .col\@sm {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-1\@sm {
    flex-basis: calc( 8.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 8.33% - 0.01px - var(--gap, 0.75em));
  }
  .col-2\@sm {
    flex-basis: calc( 16.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 16.66% - 0.01px - var(--gap, 0.75em));
  }
  .col-3\@sm {
    flex-basis: calc( 25% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 25% - 0.01px - var(--gap, 0.75em));
  }
  .col-4\@sm {
    flex-basis: calc( 33.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 33.33% - 0.01px - var(--gap, 0.75em));
  }
  .col-5\@sm {
    flex-basis: calc( 41.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 41.66% - 0.01px - var(--gap, 0.75em));
  }
  .col-6\@sm {
    flex-basis: calc( 50% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 50% - 0.01px - var(--gap, 0.75em));
  }
  .col-7\@sm {
    flex-basis: calc( 58.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 58.33% - 0.01px - var(--gap, 0.75em));
  }
  .col-8\@sm {
    flex-basis: calc( 66.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 66.66% - 0.01px - var(--gap, 0.75em));
  }
  .col-9\@sm {
    flex-basis: calc( 75% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 75% - 0.01px - var(--gap, 0.75em));
  }
  .col-10\@sm {
    flex-basis: calc( 83.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 83.33% - 0.01px - var(--gap, 0.75em));
  }
  .col-11\@sm {
    flex-basis: calc( 91.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 91.66% - 0.01px - var(--gap, 0.75em));
  }
  .col-12\@sm {
    flex-basis: calc( 100% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 100% - 0.01px - var(--gap, 0.75em));
  }
  .col-content\@sm {
    flex-basis: initial;
    max-width: initial;
  }
  .offset-1\@sm {
    --offset: calc(8.33% + var(--gap, 0.75em));
  }
  .offset-2\@sm {
    --offset: calc(16.66% + var(--gap, 0.75em));
  }
  .offset-3\@sm {
    --offset: calc(25% + var(--gap, 0.75em));
  }
  .offset-4\@sm {
    --offset: calc(33.33% + var(--gap, 0.75em));
  }
  .offset-5\@sm {
    --offset: calc(41.66% + var(--gap, 0.75em));
  }
  .offset-6\@sm {
    --offset: calc(50% + var(--gap, 0.75em));
  }
  .offset-7\@sm {
    --offset: calc(58.33% + var(--gap, 0.75em));
  }
  .offset-8\@sm {
    --offset: calc(66.66% + var(--gap, 0.75em));
  }
  .offset-9\@sm {
    --offset: calc(75% + var(--gap, 0.75em));
  }
  .offset-10\@sm {
    --offset: calc(83.33% + var(--gap, 0.75em));
  }
  .offset-11\@sm {
    --offset: calc(91.66% + var(--gap, 0.75em));
  }
}

@media (min-width: 64rem) {
  .col\@md {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-1\@md {
    flex-basis: calc( 8.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 8.33% - 0.01px - var(--gap, 0.75em));
  }
  .col-2\@md {
    flex-basis: calc( 16.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 16.66% - 0.01px - var(--gap, 0.75em));
  }
  .col-3\@md {
    flex-basis: calc( 25% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 25% - 0.01px - var(--gap, 0.75em));
  }
  .col-4\@md {
    flex-basis: calc( 33.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 33.33% - 0.01px - var(--gap, 0.75em));
  }
  .col-5\@md {
    flex-basis: calc( 41.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 41.66% - 0.01px - var(--gap, 0.75em));
  }
  .col-6\@md {
    flex-basis: calc( 50% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 50% - 0.01px - var(--gap, 0.75em));
  }
  .col-7\@md {
    flex-basis: calc( 58.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 58.33% - 0.01px - var(--gap, 0.75em));
  }
  .col-8\@md {
    flex-basis: calc( 66.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 66.66% - 0.01px - var(--gap, 0.75em));
  }
  .col-9\@md {
    flex-basis: calc( 75% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 75% - 0.01px - var(--gap, 0.75em));
  }
  .col-10\@md {
    flex-basis: calc( 83.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 83.33% - 0.01px - var(--gap, 0.75em));
  }
  .col-11\@md {
    flex-basis: calc( 91.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 91.66% - 0.01px - var(--gap, 0.75em));
  }
  .col-12\@md {
    flex-basis: calc( 100% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 100% - 0.01px - var(--gap, 0.75em));
  }
  .col-content\@md {
    flex-basis: initial;
    max-width: initial;
  }
  .offset-1\@md {
    --offset: calc(8.33% + var(--gap, 0.75em));
  }
  .offset-2\@md {
    --offset: calc(16.66% + var(--gap, 0.75em));
  }
  .offset-3\@md {
    --offset: calc(25% + var(--gap, 0.75em));
  }
  .offset-4\@md {
    --offset: calc(33.33% + var(--gap, 0.75em));
  }
  .offset-5\@md {
    --offset: calc(41.66% + var(--gap, 0.75em));
  }
  .offset-6\@md {
    --offset: calc(50% + var(--gap, 0.75em));
  }
  .offset-7\@md {
    --offset: calc(58.33% + var(--gap, 0.75em));
  }
  .offset-8\@md {
    --offset: calc(66.66% + var(--gap, 0.75em));
  }
  .offset-9\@md {
    --offset: calc(75% + var(--gap, 0.75em));
  }
  .offset-10\@md {
    --offset: calc(83.33% + var(--gap, 0.75em));
  }
  .offset-11\@md {
    --offset: calc(91.66% + var(--gap, 0.75em));
  }
}

@media (min-width: 80rem) {
  .col\@lg {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-1\@lg {
    flex-basis: calc( 8.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 8.33% - 0.01px - var(--gap, 0.75em));
  }
  .col-2\@lg {
    flex-basis: calc( 16.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 16.66% - 0.01px - var(--gap, 0.75em));
  }
  .col-3\@lg {
    flex-basis: calc( 25% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 25% - 0.01px - var(--gap, 0.75em));
  }
  .col-4\@lg {
    flex-basis: calc( 33.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 33.33% - 0.01px - var(--gap, 0.75em));
  }
  .col-5\@lg {
    flex-basis: calc( 41.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 41.66% - 0.01px - var(--gap, 0.75em));
  }
  .col-6\@lg {
    flex-basis: calc( 50% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 50% - 0.01px - var(--gap, 0.75em));
  }
  .col-7\@lg {
    flex-basis: calc( 58.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 58.33% - 0.01px - var(--gap, 0.75em));
  }
  .col-8\@lg {
    flex-basis: calc( 66.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 66.66% - 0.01px - var(--gap, 0.75em));
  }
  .col-9\@lg {
    flex-basis: calc( 75% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 75% - 0.01px - var(--gap, 0.75em));
  }
  .col-10\@lg {
    flex-basis: calc( 83.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 83.33% - 0.01px - var(--gap, 0.75em));
  }
  .col-11\@lg {
    flex-basis: calc( 91.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 91.66% - 0.01px - var(--gap, 0.75em));
  }
  .col-12\@lg {
    flex-basis: calc( 100% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 100% - 0.01px - var(--gap, 0.75em));
  }
  .col-content\@lg {
    flex-basis: initial;
    max-width: initial;
  }
  .offset-1\@lg {
    --offset: calc(8.33% + var(--gap, 0.75em));
  }
  .offset-2\@lg {
    --offset: calc(16.66% + var(--gap, 0.75em));
  }
  .offset-3\@lg {
    --offset: calc(25% + var(--gap, 0.75em));
  }
  .offset-4\@lg {
    --offset: calc(33.33% + var(--gap, 0.75em));
  }
  .offset-5\@lg {
    --offset: calc(41.66% + var(--gap, 0.75em));
  }
  .offset-6\@lg {
    --offset: calc(50% + var(--gap, 0.75em));
  }
  .offset-7\@lg {
    --offset: calc(58.33% + var(--gap, 0.75em));
  }
  .offset-8\@lg {
    --offset: calc(66.66% + var(--gap, 0.75em));
  }
  .offset-9\@lg {
    --offset: calc(75% + var(--gap, 0.75em));
  }
  .offset-10\@lg {
    --offset: calc(83.33% + var(--gap, 0.75em));
  }
  .offset-11\@lg {
    --offset: calc(91.66% + var(--gap, 0.75em));
  }
}

@media (min-width: 90rem) {
  .col\@xl {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-1\@xl {
    flex-basis: calc( 8.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 8.33% - 0.01px - var(--gap, 0.75em));
  }
  .col-2\@xl {
    flex-basis: calc( 16.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 16.66% - 0.01px - var(--gap, 0.75em));
  }
  .col-3\@xl {
    flex-basis: calc( 25% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 25% - 0.01px - var(--gap, 0.75em));
  }
  .col-4\@xl {
    flex-basis: calc( 33.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 33.33% - 0.01px - var(--gap, 0.75em));
  }
  .col-5\@xl {
    flex-basis: calc( 41.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 41.66% - 0.01px - var(--gap, 0.75em));
  }
  .col-6\@xl {
    flex-basis: calc( 50% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 50% - 0.01px - var(--gap, 0.75em));
  }
  .col-7\@xl {
    flex-basis: calc( 58.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 58.33% - 0.01px - var(--gap, 0.75em));
  }
  .col-8\@xl {
    flex-basis: calc( 66.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 66.66% - 0.01px - var(--gap, 0.75em));
  }
  .col-9\@xl {
    flex-basis: calc( 75% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 75% - 0.01px - var(--gap, 0.75em));
  }
  .col-10\@xl {
    flex-basis: calc( 83.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 83.33% - 0.01px - var(--gap, 0.75em));
  }
  .col-11\@xl {
    flex-basis: calc( 91.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 91.66% - 0.01px - var(--gap, 0.75em));
  }
  .col-12\@xl {
    flex-basis: calc( 100% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 100% - 0.01px - var(--gap, 0.75em));
  }
  .col-content\@xl {
    flex-basis: initial;
    max-width: initial;
  }
  .offset-1\@xl {
    --offset: calc(8.33% + var(--gap, 0.75em));
  }
  .offset-2\@xl {
    --offset: calc(16.66% + var(--gap, 0.75em));
  }
  .offset-3\@xl {
    --offset: calc(25% + var(--gap, 0.75em));
  }
  .offset-4\@xl {
    --offset: calc(33.33% + var(--gap, 0.75em));
  }
  .offset-5\@xl {
    --offset: calc(41.66% + var(--gap, 0.75em));
  }
  .offset-6\@xl {
    --offset: calc(50% + var(--gap, 0.75em));
  }
  .offset-7\@xl {
    --offset: calc(58.33% + var(--gap, 0.75em));
  }
  .offset-8\@xl {
    --offset: calc(66.66% + var(--gap, 0.75em));
  }
  .offset-9\@xl {
    --offset: calc(75% + var(--gap, 0.75em));
  }
  .offset-10\@xl {
    --offset: calc(83.33% + var(--gap, 0.75em));
  }
  .offset-11\@xl {
    --offset: calc(91.66% + var(--gap, 0.75em));
  }
}

:root {
  --radius-sm: calc(var(--radius, 0.25em)/2);
  --radius-md: var(--radius, 0.25em);
  --radius-lg: calc(var(--radius, 0.25em)*2);
  --shadow-xs:  0 0.1px 0.3px rgba(0, 0, 0, 0.06),
                  0 1px 2px rgba(0, 0, 0, 0.12);
  --shadow-sm:  0 0.3px 0.4px rgba(0, 0, 0, 0.025),
                  0 0.9px 1.5px rgba(0, 0, 0, 0.05),
                  0 3.5px 6px rgba(0, 0, 0, 0.1);
  --shadow-md:  0 0.9px 1.5px rgba(0, 0, 0, 0.03),
                  0 3.1px 5.5px rgba(0, 0, 0, 0.08),
                  0 14px 25px rgba(0, 0, 0, 0.12);
  --shadow-lg:  0 1.2px 1.9px -1px rgba(0, 0, 0, 0.014),
                  0 3.3px 5.3px -1px rgba(0, 0, 0, 0.038),
                  0 8.5px 12.7px -1px rgba(0, 0, 0, 0.085),
                  0 30px 42px -1px rgba(0, 0, 0, 0.15);
  --shadow-xl:  0 1.5px 2.1px -6px rgba(0, 0, 0, 0.012),
                  0 3.6px 5.2px -6px rgba(0, 0, 0, 0.035),
                  0 7.3px 10.6px -6px rgba(0, 0, 0, 0.07),
                  0 16.2px 21.9px -6px rgba(0, 0, 0, 0.117),
                  0 46px 60px -6px rgba(0, 0, 0, 0.2);
  --bounce: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  --ease-in-out: cubic-bezier(0.645, 0.045, 0.355, 1);
  --ease-in: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  --ease-out: cubic-bezier(0.215, 0.61, 0.355, 1);
  --ease-out-back: cubic-bezier(0.34, 1.56, 0.64, 1);
}

:root {
  --zindex-header: 3;
  --zindex-popover: 5;
  --zindex-fixed-element: 10;
  --zindex-overlay: 15;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-column {
  flex-direction: column;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.flex-grow {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-basis-0 {
  flex-basis: 0;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.items-center {
  align-items: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-baseline {
  align-items: baseline;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.margin-xxxxs {
  margin: var(--space-xxxxs);
}

.margin-xxxs {
  margin: var(--space-xxxs);
}

.margin-xxs {
  margin: var(--space-xxs);
}

.margin-xs {
  margin: var(--space-xs);
}

.margin-sm {
  margin: var(--space-sm);
}

.margin-md {
  margin: var(--space-md);
}

.margin-lg {
  margin: var(--space-lg);
}

.margin-xl {
  margin: var(--space-xl);
}

.margin-xxl {
  margin: var(--space-xxl);
}

.margin-xxxl {
  margin: var(--space-xxxl);
}

.margin-xxxxl {
  margin: var(--space-xxxxl);
}

.margin-auto {
  margin: auto;
}

.margin-0 {
  margin: 0;
}

.margin-top-xxxxs {
  margin-top: var(--space-xxxxs);
}

.margin-top-xxxs {
  margin-top: var(--space-xxxs);
}

.margin-top-xxs {
  margin-top: var(--space-xxs);
}

.margin-top-xs {
  margin-top: var(--space-xs);
}

.margin-top-sm {
  margin-top: var(--space-sm);
}

.margin-top-md {
  margin-top: var(--space-md);
}

.margin-top-lg {
  margin-top: var(--space-lg);
}

.margin-top-xl {
  margin-top: var(--space-xl);
}

.margin-top-xxl {
  margin-top: var(--space-xxl);
}

.margin-top-xxxl {
  margin-top: var(--space-xxxl);
}

.margin-top-xxxxl {
  margin-top: var(--space-xxxxl);
}

.margin-top-auto {
  margin-top: auto;
}

.margin-top-0 {
  margin-top: 0;
}

.margin-bottom-xxxxs {
  margin-bottom: var(--space-xxxxs);
}

.margin-bottom-xxxs {
  margin-bottom: var(--space-xxxs);
}

.margin-bottom-xxs {
  margin-bottom: var(--space-xxs);
}

.margin-bottom-xs {
  margin-bottom: var(--space-xs);
}

.margin-bottom-sm {
  margin-bottom: var(--space-sm);
}

.margin-bottom-md {
  margin-bottom: var(--space-md);
}

.margin-bottom-lg {
  margin-bottom: var(--space-lg);
}

.margin-bottom-xl {
  margin-bottom: var(--space-xl);
}

.margin-bottom-xxl {
  margin-bottom: var(--space-xxl);
}

.margin-bottom-xxxl {
  margin-bottom: var(--space-xxxl);
}

.margin-bottom-xxxxl {
  margin-bottom: var(--space-xxxxl);
}

.margin-bottom-auto {
  margin-bottom: auto;
}

.margin-bottom-0 {
  margin-bottom: 0;
}

.margin-right-xxxxs {
  margin-right: var(--space-xxxxs);
}

.margin-right-xxxs {
  margin-right: var(--space-xxxs);
}

.margin-right-xxs {
  margin-right: var(--space-xxs);
}

.margin-right-xs {
  margin-right: var(--space-xs);
}

.margin-right-sm {
  margin-right: var(--space-sm);
}

.margin-right-md {
  margin-right: var(--space-md);
}

.margin-right-lg {
  margin-right: var(--space-lg);
}

.margin-right-xl {
  margin-right: var(--space-xl);
}

.margin-right-xxl {
  margin-right: var(--space-xxl);
}

.margin-right-xxxl {
  margin-right: var(--space-xxxl);
}

.margin-right-xxxxl {
  margin-right: var(--space-xxxxl);
}

.margin-right-auto {
  margin-right: auto;
}

.margin-right-0 {
  margin-right: 0;
}

.margin-left-xxxxs {
  margin-left: var(--space-xxxxs);
}

.margin-left-xxxs {
  margin-left: var(--space-xxxs);
}

.margin-left-xxs {
  margin-left: var(--space-xxs);
}

.margin-left-xs {
  margin-left: var(--space-xs);
}

.margin-left-sm {
  margin-left: var(--space-sm);
}

.margin-left-md {
  margin-left: var(--space-md);
}

.margin-left-lg {
  margin-left: var(--space-lg);
}

.margin-left-xl {
  margin-left: var(--space-xl);
}

.margin-left-xxl {
  margin-left: var(--space-xxl);
}

.margin-left-xxxl {
  margin-left: var(--space-xxxl);
}

.margin-left-xxxxl {
  margin-left: var(--space-xxxxl);
}

.margin-left-auto {
  margin-left: auto;
}

.margin-left-0 {
  margin-left: 0;
}

.margin-x-xxxxs {
  margin-left: var(--space-xxxxs);
  margin-right: var(--space-xxxxs);
}

.margin-x-xxxs {
  margin-left: var(--space-xxxs);
  margin-right: var(--space-xxxs);
}

.margin-x-xxs {
  margin-left: var(--space-xxs);
  margin-right: var(--space-xxs);
}

.margin-x-xs {
  margin-left: var(--space-xs);
  margin-right: var(--space-xs);
}

.margin-x-sm {
  margin-left: var(--space-sm);
  margin-right: var(--space-sm);
}

.margin-x-md {
  margin-left: var(--space-md);
  margin-right: var(--space-md);
}

.margin-x-lg {
  margin-left: var(--space-lg);
  margin-right: var(--space-lg);
}

.margin-x-xl {
  margin-left: var(--space-xl);
  margin-right: var(--space-xl);
}

.margin-x-xxl {
  margin-left: var(--space-xxl);
  margin-right: var(--space-xxl);
}

.margin-x-xxxl {
  margin-left: var(--space-xxxl);
  margin-right: var(--space-xxxl);
}

.margin-x-xxxxl {
  margin-left: var(--space-xxxxl);
  margin-right: var(--space-xxxxl);
}

.margin-x-auto {
  margin-left: auto;
  margin-right: auto;
}

.margin-x-0 {
  margin-left: 0;
  margin-right: 0;
}

.margin-y-xxxxs {
  margin-top: var(--space-xxxxs);
  margin-bottom: var(--space-xxxxs);
}

.margin-y-xxxs {
  margin-top: var(--space-xxxs);
  margin-bottom: var(--space-xxxs);
}

.margin-y-xxs {
  margin-top: var(--space-xxs);
  margin-bottom: var(--space-xxs);
}

.margin-y-xs {
  margin-top: var(--space-xs);
  margin-bottom: var(--space-xs);
}

.margin-y-sm {
  margin-top: var(--space-sm);
  margin-bottom: var(--space-sm);
}

.margin-y-md {
  margin-top: var(--space-md);
  margin-bottom: var(--space-md);
}

.margin-y-lg {
  margin-top: var(--space-lg);
  margin-bottom: var(--space-lg);
}

.margin-y-xl {
  margin-top: var(--space-xl);
  margin-bottom: var(--space-xl);
}

.margin-y-xxl {
  margin-top: var(--space-xxl);
  margin-bottom: var(--space-xxl);
}

.margin-y-xxxl {
  margin-top: var(--space-xxxl);
  margin-bottom: var(--space-xxxl);
}

.margin-y-xxxxl {
  margin-top: var(--space-xxxxl);
  margin-bottom: var(--space-xxxxl);
}

.margin-y-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.margin-y-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.padding-xxxxs {
  padding: var(--space-xxxxs);
}

.padding-xxxs {
  padding: var(--space-xxxs);
}

.padding-xxs {
  padding: var(--space-xxs);
}

.padding-xs {
  padding: var(--space-xs);
}

.padding-sm {
  padding: var(--space-sm);
}

.padding-md {
  padding: var(--space-md);
}

.padding-lg {
  padding: var(--space-lg);
}

.padding-xl {
  padding: var(--space-xl);
}

.padding-xxl {
  padding: var(--space-xxl);
}

.padding-xxxl {
  padding: var(--space-xxxl);
}

.padding-xxxxl {
  padding: var(--space-xxxxl);
}

.padding-0 {
  padding: 0;
}

.padding-component {
  padding: var(--component-padding);
}

.padding-top-xxxxs {
  padding-top: var(--space-xxxxs);
}

.padding-top-xxxs {
  padding-top: var(--space-xxxs);
}

.padding-top-xxs {
  padding-top: var(--space-xxs);
}

.padding-top-xs {
  padding-top: var(--space-xs);
}

.padding-top-sm {
  padding-top: var(--space-sm);
}

.padding-top-md {
  padding-top: var(--space-md);
}

.padding-top-lg {
  padding-top: var(--space-lg);
}

.padding-top-xl {
  padding-top: var(--space-xl);
}

.padding-top-xxl {
  padding-top: var(--space-xxl);
}

.padding-top-xxxl {
  padding-top: var(--space-xxxl);
}

.padding-top-xxxxl {
  padding-top: var(--space-xxxxl);
}

.padding-top-0 {
  padding-top: 0;
}

.padding-top-component {
  padding-top: var(--component-padding);
}

.padding-bottom-xxxxs {
  padding-bottom: var(--space-xxxxs);
}

.padding-bottom-xxxs {
  padding-bottom: var(--space-xxxs);
}

.padding-bottom-xxs {
  padding-bottom: var(--space-xxs);
}

.padding-bottom-xs {
  padding-bottom: var(--space-xs);
}

.padding-bottom-sm {
  padding-bottom: var(--space-sm);
}

.padding-bottom-md {
  padding-bottom: var(--space-md);
}

.padding-bottom-lg {
  padding-bottom: var(--space-lg);
}

.padding-bottom-xl {
  padding-bottom: var(--space-xl);
}

.padding-bottom-xxl {
  padding-bottom: var(--space-xxl);
}

.padding-bottom-xxxl {
  padding-bottom: var(--space-xxxl);
}

.padding-bottom-xxxxl {
  padding-bottom: var(--space-xxxxl);
}

.padding-bottom-0 {
  padding-bottom: 0;
}

.padding-bottom-component {
  padding-bottom: var(--component-padding);
}

.padding-right-xxxxs {
  padding-right: var(--space-xxxxs);
}

.padding-right-xxxs {
  padding-right: var(--space-xxxs);
}

.padding-right-xxs {
  padding-right: var(--space-xxs);
}

.padding-right-xs {
  padding-right: var(--space-xs);
}

.padding-right-sm {
  padding-right: var(--space-sm);
}

.padding-right-md {
  padding-right: var(--space-md);
}

.padding-right-lg {
  padding-right: var(--space-lg);
}

.padding-right-xl {
  padding-right: var(--space-xl);
}

.padding-right-xxl {
  padding-right: var(--space-xxl);
}

.padding-right-xxxl {
  padding-right: var(--space-xxxl);
}

.padding-right-xxxxl {
  padding-right: var(--space-xxxxl);
}

.padding-right-0 {
  padding-right: 0;
}

.padding-right-component {
  padding-right: var(--component-padding);
}

.padding-left-xxxxs {
  padding-left: var(--space-xxxxs);
}

.padding-left-xxxs {
  padding-left: var(--space-xxxs);
}

.padding-left-xxs {
  padding-left: var(--space-xxs);
}

.padding-left-xs {
  padding-left: var(--space-xs);
}

.padding-left-sm {
  padding-left: var(--space-sm);
}

.padding-left-md {
  padding-left: var(--space-md);
}

.padding-left-lg {
  padding-left: var(--space-lg);
}

.padding-left-xl {
  padding-left: var(--space-xl);
}

.padding-left-xxl {
  padding-left: var(--space-xxl);
}

.padding-left-xxxl {
  padding-left: var(--space-xxxl);
}

.padding-left-xxxxl {
  padding-left: var(--space-xxxxl);
}

.padding-left-0 {
  padding-left: 0;
}

.padding-left-component {
  padding-left: var(--component-padding);
}

.padding-x-xxxxs {
  padding-left: var(--space-xxxxs);
  padding-right: var(--space-xxxxs);
}

.padding-x-xxxs {
  padding-left: var(--space-xxxs);
  padding-right: var(--space-xxxs);
}

.padding-x-xxs {
  padding-left: var(--space-xxs);
  padding-right: var(--space-xxs);
}

.padding-x-xs {
  padding-left: var(--space-xs);
  padding-right: var(--space-xs);
}

.padding-x-sm {
  padding-left: var(--space-sm);
  padding-right: var(--space-sm);
}

.padding-x-md {
  padding-left: var(--space-md);
  padding-right: var(--space-md);
}

.padding-x-lg {
  padding-left: var(--space-lg);
  padding-right: var(--space-lg);
}

.padding-x-xl {
  padding-left: var(--space-xl);
  padding-right: var(--space-xl);
}

.padding-x-xxl {
  padding-left: var(--space-xxl);
  padding-right: var(--space-xxl);
}

.padding-x-xxxl {
  padding-left: var(--space-xxxl);
  padding-right: var(--space-xxxl);
}

.padding-x-xxxxl {
  padding-left: var(--space-xxxxl);
  padding-right: var(--space-xxxxl);
}

.padding-x-0 {
  padding-left: 0;
  padding-right: 0;
}

.padding-x-component {
  padding-left: var(--component-padding);
  padding-right: var(--component-padding);
}

.padding-y-xxxxs {
  padding-top: var(--space-xxxxs);
  padding-bottom: var(--space-xxxxs);
}

.padding-y-xxxs {
  padding-top: var(--space-xxxs);
  padding-bottom: var(--space-xxxs);
}

.padding-y-xxs {
  padding-top: var(--space-xxs);
  padding-bottom: var(--space-xxs);
}

.padding-y-xs {
  padding-top: var(--space-xs);
  padding-bottom: var(--space-xs);
}

.padding-y-sm {
  padding-top: var(--space-sm);
  padding-bottom: var(--space-sm);
}

.padding-y-md {
  padding-top: var(--space-md);
  padding-bottom: var(--space-md);
}

.padding-y-lg {
  padding-top: var(--space-lg);
  padding-bottom: var(--space-lg);
}

.padding-y-xl {
  padding-top: var(--space-xl);
  padding-bottom: var(--space-xl);
}

.padding-y-xxl {
  padding-top: var(--space-xxl);
  padding-bottom: var(--space-xxl);
}

.padding-y-xxxl {
  padding-top: var(--space-xxxl);
  padding-bottom: var(--space-xxxl);
}

.padding-y-xxxxl {
  padding-top: var(--space-xxxxl);
  padding-bottom: var(--space-xxxxl);
}

.padding-y-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.padding-y-component {
  padding-top: var(--component-padding);
  padding-bottom: var(--component-padding);
}

.align-baseline {
  vertical-align: baseline;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.truncate, .text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-replace {
  overflow: hidden;
  color: transparent;
  text-indent: 100%;
  white-space: nowrap;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.letter-spacing-sm {
  letter-spacing: -0.05em;
}

.letter-spacing-md {
  letter-spacing: 0.05em;
}

.letter-spacing-lg {
  letter-spacing: 0.1em;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold, .text-bold {
  font-weight: 700;
}

.font-italic {
  font-style: italic;
}

.font-smooth {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-primary {
  font-family: var(--font-primary);
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.text-line-through {
  text-decoration: line-through;
}

.text-underline {
  text-decoration: underline;
}

.text-decoration-none {
  text-decoration: none;
}

.v-space-xxs {
  --text-vspace-multiplier: 0.25 !important;
}

.v-space-xs {
  --text-vspace-multiplier: 0.5 !important;
}

.v-space-sm {
  --text-vspace-multiplier: 0.75 !important;
}

.v-space-md {
  --text-vspace-multiplier: 1.25 !important;
}

.v-space-lg {
  --text-vspace-multiplier: 1.5 !important;
}

.v-space-xl {
  --text-vspace-multiplier: 1.75 !important;
}

.v-space-xxl {
  --text-vspace-multiplier: 2 !important;
}

.line-height-xs {
  --heading-line-height: 1;
  --body-line-height: 1;
}

.line-height-xs:not(.text-component) {
  line-height: 1;
}

.line-height-sm {
  --heading-line-height: 1.1;
  --body-line-height: 1.2;
}

.line-height-sm:not(.text-component) {
  line-height: 1.2;
}

.line-height-md {
  --heading-line-height: 1.15;
  --body-line-height: 1.4;
}

.line-height-md:not(.text-component) {
  line-height: 1.4;
}

.line-height-lg {
  --heading-line-height: 1.22;
  --body-line-height: 1.58;
}

.line-height-lg:not(.text-component) {
  line-height: 1.58;
}

.line-height-xl {
  --heading-line-height: 1.3;
  --body-line-height: 1.72;
}

.line-height-xl:not(.text-component) {
  line-height: 1.72;
}

.line-height-body {
  line-height: var(--body-line-height);
}

.line-height-heading {
  line-height: var(--heading-line-height);
}

.ws-nowrap, .text-nowrap {
  white-space: nowrap;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.pointer-events-auto {
  pointer-events: auto;
}

.pointer-events-none {
  pointer-events: none;
}

.user-select-none {
  user-select: none;
}

.user-select-all {
  user-select: all;
}

[class*="color-"] {
  --color-o: 1;
}

.color-inherit {
  color: inherit;
}

.color-bg {
  color: hsla(var(--color-bg-h), var(--color-bg-s), var(--color-bg-l), var(--color-o, 1));
}

.color-contrast-lower {
  color: hsla(var(--color-contrast-lower-h), var(--color-contrast-lower-s), var(--color-contrast-lower-l), var(--color-o, 1));
}

.color-contrast-low {
  color: hsla(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l), var(--color-o, 1));
}

.color-contrast-medium {
  color: hsla(var(--color-contrast-medium-h), var(--color-contrast-medium-s), var(--color-contrast-medium-l), var(--color-o, 1));
}

.color-contrast-high {
  color: hsla(var(--color-contrast-high-h), var(--color-contrast-high-s), var(--color-contrast-high-l), var(--color-o, 1));
}

.color-contrast-higher {
  color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), var(--color-o, 1));
}

.color-primary-darker {
  color: hsla(var(--color-primary-darker-h), var(--color-primary-darker-s), var(--color-primary-darker-l), var(--color-o, 1));
}

.color-primary-dark {
  color: hsla(var(--color-primary-dark-h), var(--color-primary-dark-s), var(--color-primary-dark-l), var(--color-o, 1));
}

.color-primary {
  color: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), var(--color-o, 1));
}

.color-primary-light {
  color: hsla(var(--color-primary-light-h), var(--color-primary-light-s), var(--color-primary-light-l), var(--color-o, 1));
}

.color-primary-lighter {
  color: hsla(var(--color-primary-lighter-h), var(--color-primary-lighter-s), var(--color-primary-lighter-l), var(--color-o, 1));
}

.color-accent-darker {
  color: hsla(var(--color-accent-darker-h), var(--color-accent-darker-s), var(--color-accent-darker-l), var(--color-o, 1));
}

.color-accent-dark {
  color: hsla(var(--color-accent-dark-h), var(--color-accent-dark-s), var(--color-accent-dark-l), var(--color-o, 1));
}

.color-accent {
  color: hsla(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l), var(--color-o, 1));
}

.color-accent-light {
  color: hsla(var(--color-accent-light-h), var(--color-accent-light-s), var(--color-accent-light-l), var(--color-o, 1));
}

.color-accent-lighter {
  color: hsla(var(--color-accent-lighter-h), var(--color-accent-lighter-s), var(--color-accent-lighter-l), var(--color-o, 1));
}

.color-success-darker {
  color: hsla(var(--color-success-darker-h), var(--color-success-darker-s), var(--color-success-darker-l), var(--color-o, 1));
}

.color-success-dark {
  color: hsla(var(--color-success-dark-h), var(--color-success-dark-s), var(--color-success-dark-l), var(--color-o, 1));
}

.color-success {
  color: hsla(var(--color-success-h), var(--color-success-s), var(--color-success-l), var(--color-o, 1));
}

.color-success-light {
  color: hsla(var(--color-success-light-h), var(--color-success-light-s), var(--color-success-light-l), var(--color-o, 1));
}

.color-success-lighter {
  color: hsla(var(--color-success-lighter-h), var(--color-success-lighter-s), var(--color-success-lighter-l), var(--color-o, 1));
}

.color-warning-darker {
  color: hsla(var(--color-warning-darker-h), var(--color-warning-darker-s), var(--color-warning-darker-l), var(--color-o, 1));
}

.color-warning-dark {
  color: hsla(var(--color-warning-dark-h), var(--color-warning-dark-s), var(--color-warning-dark-l), var(--color-o, 1));
}

.color-warning {
  color: hsla(var(--color-warning-h), var(--color-warning-s), var(--color-warning-l), var(--color-o, 1));
}

.color-warning-light {
  color: hsla(var(--color-warning-light-h), var(--color-warning-light-s), var(--color-warning-light-l), var(--color-o, 1));
}

.color-warning-lighter {
  color: hsla(var(--color-warning-lighter-h), var(--color-warning-lighter-s), var(--color-warning-lighter-l), var(--color-o, 1));
}

.color-error-darker {
  color: hsla(var(--color-error-darker-h), var(--color-error-darker-s), var(--color-error-darker-l), var(--color-o, 1));
}

.color-error-dark {
  color: hsla(var(--color-error-dark-h), var(--color-error-dark-s), var(--color-error-dark-l), var(--color-o, 1));
}

.color-error {
  color: hsla(var(--color-error-h), var(--color-error-s), var(--color-error-l), var(--color-o, 1));
}

.color-error-light {
  color: hsla(var(--color-error-light-h), var(--color-error-light-s), var(--color-error-light-l), var(--color-o, 1));
}

.color-error-lighter {
  color: hsla(var(--color-error-lighter-h), var(--color-error-lighter-s), var(--color-error-lighter-l), var(--color-o, 1));
}

.color-white {
  color: hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), var(--color-o, 1));
}

.color-black {
  color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), var(--color-o, 1));
}

.color-opacity-0 {
  --color-o: 0;
}

.color-opacity-10\% {
  --color-o: 0.1;
}

.color-opacity-20\% {
  --color-o: 0.2;
}

.color-opacity-30\% {
  --color-o: 0.3;
}

.color-opacity-40\% {
  --color-o: 0.4;
}

.color-opacity-50\% {
  --color-o: 0.5;
}

.color-opacity-60\% {
  --color-o: 0.6;
}

.color-opacity-70\% {
  --color-o: 0.7;
}

.color-opacity-80\% {
  --color-o: 0.8;
}

.color-opacity-90\% {
  --color-o: 0.9;
}

.width-xxxxs {
  width: var(--size-xxxxs, 0.25rem);
}

.width-xxxs {
  width: var(--size-xxxs, 0.5rem);
}

.width-xxs {
  width: var(--size-xxs, 0.75rem);
}

.width-xs {
  width: var(--size-xs, 1rem);
}

.width-sm {
  width: var(--size-sm, 1.5rem);
}

.width-md {
  width: var(--size-md, 2rem);
}

.width-lg {
  width: var(--size-lg, 3rem);
}

.width-xl {
  width: var(--size-xl, 4rem);
}

.width-xxl {
  width: var(--size-xxl, 6rem);
}

.width-xxxl {
  width: var(--size-xxxl, 8rem);
}

.width-xxxxl {
  width: var(--size-xxxxl, 16rem);
}

.width-0 {
  width: 0;
}

.width-10\% {
  width: 10%;
}

.width-20\% {
  width: 20%;
}

.width-25\% {
  width: 25%;
}

.width-30\% {
  width: 30%;
}

.width-33\% {
  width: calc(100% / 3);
}

.width-40\% {
  width: 40%;
}

.width-50\% {
  width: 50%;
}

.width-60\% {
  width: 60%;
}

.width-66\% {
  width: calc(100% / 1.5);
}

.width-70\% {
  width: 70%;
}

.width-75\% {
  width: 75%;
}

.width-80\% {
  width: 80%;
}

.width-90\% {
  width: 90%;
}

.width-100\% {
  width: 100%;
}

.width-100vw {
  width: 100vw;
}

.width-auto {
  width: auto;
}

.height-xxxxs {
  height: var(--size-xxxxs, 0.25rem);
}

.height-xxxs {
  height: var(--size-xxxs, 0.5rem);
}

.height-xxs {
  height: var(--size-xxs, 0.75rem);
}

.height-xs {
  height: var(--size-xs, 1rem);
}

.height-sm {
  height: var(--size-sm, 1.5rem);
}

.height-md {
  height: var(--size-md, 2rem);
}

.height-lg {
  height: var(--size-lg, 3rem);
}

.height-xl {
  height: var(--size-xl, 4rem);
}

.height-xxl {
  height: var(--size-xxl, 6rem);
}

.height-xxxl {
  height: var(--size-xxxl, 8rem);
}

.height-xxxxl {
  height: var(--size-xxxxl, 16rem);
}

.height-0 {
  height: 0;
}

.height-10\% {
  height: 10%;
}

.height-20\% {
  height: 20%;
}

.height-25\% {
  height: 25%;
}

.height-30\% {
  height: 30%;
}

.height-33\% {
  height: calc(100% / 3);
}

.height-40\% {
  height: 40%;
}

.height-50\% {
  height: 50%;
}

.height-60\% {
  height: 60%;
}

.height-66\% {
  height: calc(100% / 1.5);
}

.height-70\% {
  height: 70%;
}

.height-75\% {
  height: 75%;
}

.height-80\% {
  height: 80%;
}

.height-90\% {
  height: 90%;
}

.height-100\% {
  height: 100%;
}

.height-100vh {
  height: 100vh;
}

.height-auto {
  height: auto;
}

.min-width-0 {
  min-width: 0;
}

.min-width-25\% {
  min-width: 25%;
}

.min-width-33\% {
  min-width: calc(100% / 3);
}

.min-width-50\% {
  min-width: 50%;
}

.min-width-66\% {
  min-width: calc(100% / 1.5);
}

.min-width-75\% {
  min-width: 75%;
}

.min-width-100\% {
  min-width: 100%;
}

.min-width-100vw {
  min-width: 100vw;
}

.min-height-100\% {
  min-height: 100%;
}

.min-height-100vh {
  min-height: 100vh;
}

:root {
  --max-width-xxxxs: 20rem;
  --max-width-xxxs:  26rem;
  --max-width-xxs:   32rem;
  --max-width-xs:    38rem;
  --max-width-sm:    48rem;
  --max-width-md:    64rem;
  --max-width-lg:    80rem;
  --max-width-xl:    90rem;
  --max-width-xxl:   100rem;
  --max-width-xxxl:  120rem;
  --max-width-xxxxl: 150rem;
}

.max-width-xxxxs {
  max-width: var(--max-width-xxxxs);
}

.max-width-xxxs {
  max-width: var(--max-width-xxxs);
}

.max-width-xxs {
  max-width: var(--max-width-xxs);
}

.max-width-xs {
  max-width: var(--max-width-xs);
}

.max-width-sm {
  max-width: var(--max-width-sm);
}

.max-width-md {
  max-width: var(--max-width-md);
}

.max-width-lg {
  max-width: var(--max-width-lg);
}

.max-width-xl {
  max-width: var(--max-width-xl);
}

.max-width-xxl {
  max-width: var(--max-width-xxl);
}

.max-width-xxxl {
  max-width: var(--max-width-xxxl);
}

.max-width-xxxxl {
  max-width: var(--max-width-xxxxl);
}

.max-width-100\% {
  max-width: 100%;
}

[class*="max-width-adaptive"] {
  max-width: 40rem;
}

@media (min-width: 48rem) {
  .max-width-adaptive-sm, .max-width-adaptive-md, .max-width-adaptive-lg, .max-width-adaptive-xl, .max-width-adaptive {
    max-width: 48rem;
  }
}

@media (min-width: 64rem) {
  .max-width-adaptive-md, .max-width-adaptive-lg, .max-width-adaptive-xl, .max-width-adaptive {
    max-width: 64rem;
  }
}

@media (min-width: 80rem) {
  .max-width-adaptive-lg, .max-width-adaptive-xl, .max-width-adaptive {
    max-width: 80rem;
  }
}

@media (min-width: 90rem) {
  .max-width-adaptive-xl {
    max-width: 90rem;
  }
}

.max-height-100\% {
  max-height: 100%;
}

.max-height-100vh {
  max-height: 100vh;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

.position-sticky {
  position: sticky;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.z-index-header {
  z-index: var(--zindex-header);
}

.z-index-popover {
  z-index: var(--zindex-popover);
}

.z-index-fixed-element {
  z-index: var(--zindex-fixed-element);
}

.z-index-overlay {
  z-index: var(--zindex-overlay);
}

.zindex-1, .z-index-1 {
  z-index: 1;
}

.zindex-2, .z-index-2 {
  z-index: 2;
}

.zindex-3, .z-index-3 {
  z-index: 3;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.momentum-scrolling {
  -webkit-overflow-scrolling: touch;
}

.overscroll-contain {
  overscroll-behavior: contain;
}

.opacity-0 {
  opacity: 0;
}

.opacity-10\% {
  opacity: 0.1;
}

.opacity-20\% {
  opacity: 0.2;
}

.opacity-30\% {
  opacity: 0.3;
}

.opacity-40\% {
  opacity: 0.4;
}

.opacity-50\% {
  opacity: 0.5;
}

.opacity-60\% {
  opacity: 0.6;
}

.opacity-70\% {
  opacity: 0.7;
}

.opacity-80\% {
  opacity: 0.8;
}

.opacity-90\% {
  opacity: 0.9;
}

.media-wrapper {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
}

.media-wrapper > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.media-wrapper--4\:3 {
  padding-bottom: calc((3 / 4) * 100%);
}

.media-wrapper--1\:1 {
  padding-bottom: calc((1 / 1) * 100%);
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

[class*="border-"] {
  --border-o: 1;
}

.border {
  border: 1px solid hsla(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l), var(--border-o, 1));
}

.border-top {
  border-top: 1px solid hsla(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l), var(--border-o, 1));
}

.border-bottom {
  border-bottom: 1px solid hsla(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l), var(--border-o, 1));
}

.border-left {
  border-left: 1px solid hsla(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l), var(--border-o, 1));
}

.border-right {
  border-right: 1px solid hsla(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l), var(--border-o, 1));
}

.border-2 {
  border-width: 2px;
}

.border-3 {
  border-width: 3px;
}

.border-4 {
  border-width: 4px;
}

.border-bg {
  border-color: hsla(var(--color-bg-h), var(--color-bg-s), var(--color-bg-l), var(--border-o, 1));
}

.border-contrast-lower {
  border-color: hsla(var(--color-contrast-lower-h), var(--color-contrast-lower-s), var(--color-contrast-lower-l), var(--border-o, 1));
}

.border-contrast-low {
  border-color: hsla(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l), var(--border-o, 1));
}

.border-contrast-medium {
  border-color: hsla(var(--color-contrast-medium-h), var(--color-contrast-medium-s), var(--color-contrast-medium-l), var(--border-o, 1));
}

.border-contrast-high {
  border-color: hsla(var(--color-contrast-high-h), var(--color-contrast-high-s), var(--color-contrast-high-l), var(--border-o, 1));
}

.border-contrast-higher {
  border-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), var(--border-o, 1));
}

.border-primary-darker {
  border-color: hsla(var(--color-primary-darker-h), var(--color-primary-darker-s), var(--color-primary-darker-l), var(--border-o, 1));
}

.border-primary-dark {
  border-color: hsla(var(--color-primary-dark-h), var(--color-primary-dark-s), var(--color-primary-dark-l), var(--border-o, 1));
}

.border-primary {
  border-color: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), var(--border-o, 1));
}

.border-primary-light {
  border-color: hsla(var(--color-primary-light-h), var(--color-primary-light-s), var(--color-primary-light-l), var(--border-o, 1));
}

.border-primary-lighter {
  border-color: hsla(var(--color-primary-lighter-h), var(--color-primary-lighter-s), var(--color-primary-lighter-l), var(--border-o, 1));
}

.border-accent-darker {
  border-color: hsla(var(--color-accent-darker-h), var(--color-accent-darker-s), var(--color-accent-darker-l), var(--border-o, 1));
}

.border-accent-dark {
  border-color: hsla(var(--color-accent-dark-h), var(--color-accent-dark-s), var(--color-accent-dark-l), var(--border-o, 1));
}

.border-accent {
  border-color: hsla(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l), var(--border-o, 1));
}

.border-accent-light {
  border-color: hsla(var(--color-accent-light-h), var(--color-accent-light-s), var(--color-accent-light-l), var(--border-o, 1));
}

.border-accent-lighter {
  border-color: hsla(var(--color-accent-lighter-h), var(--color-accent-lighter-s), var(--color-accent-lighter-l), var(--border-o, 1));
}

.border-success-darker {
  border-color: hsla(var(--color-success-darker-h), var(--color-success-darker-s), var(--color-success-darker-l), var(--border-o, 1));
}

.border-success-dark {
  border-color: hsla(var(--color-success-dark-h), var(--color-success-dark-s), var(--color-success-dark-l), var(--border-o, 1));
}

.border-success {
  border-color: hsla(var(--color-success-h), var(--color-success-s), var(--color-success-l), var(--border-o, 1));
}

.border-success-light {
  border-color: hsla(var(--color-success-light-h), var(--color-success-light-s), var(--color-success-light-l), var(--border-o, 1));
}

.border-success-lighter {
  border-color: hsla(var(--color-success-lighter-h), var(--color-success-lighter-s), var(--color-success-lighter-l), var(--border-o, 1));
}

.border-warning-darker {
  border-color: hsla(var(--color-warning-darker-h), var(--color-warning-darker-s), var(--color-warning-darker-l), var(--border-o, 1));
}

.border-warning-dark {
  border-color: hsla(var(--color-warning-dark-h), var(--color-warning-dark-s), var(--color-warning-dark-l), var(--border-o, 1));
}

.border-warning {
  border-color: hsla(var(--color-warning-h), var(--color-warning-s), var(--color-warning-l), var(--border-o, 1));
}

.border-warning-light {
  border-color: hsla(var(--color-warning-light-h), var(--color-warning-light-s), var(--color-warning-light-l), var(--border-o, 1));
}

.border-warning-lighter {
  border-color: hsla(var(--color-warning-lighter-h), var(--color-warning-lighter-s), var(--color-warning-lighter-l), var(--border-o, 1));
}

.border-error-darker {
  border-color: hsla(var(--color-error-darker-h), var(--color-error-darker-s), var(--color-error-darker-l), var(--border-o, 1));
}

.border-error-dark {
  border-color: hsla(var(--color-error-dark-h), var(--color-error-dark-s), var(--color-error-dark-l), var(--border-o, 1));
}

.border-error {
  border-color: hsla(var(--color-error-h), var(--color-error-s), var(--color-error-l), var(--border-o, 1));
}

.border-error-light {
  border-color: hsla(var(--color-error-light-h), var(--color-error-light-s), var(--color-error-light-l), var(--border-o, 1));
}

.border-error-lighter {
  border-color: hsla(var(--color-error-lighter-h), var(--color-error-lighter-s), var(--color-error-lighter-l), var(--border-o, 1));
}

.border-white {
  border-color: hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), var(--border-o, 1));
}

.border-black {
  border-color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), var(--border-o, 1));
}

.border-opacity-0 {
  --border-o: 0;
}

.border-opacity-10\% {
  --border-o: 0.1;
}

.border-opacity-20\% {
  --border-o: 0.2;
}

.border-opacity-30\% {
  --border-o: 0.3;
}

.border-opacity-40\% {
  --border-o: 0.4;
}

.border-opacity-50\% {
  --border-o: 0.5;
}

.border-opacity-60\% {
  --border-o: 0.6;
}

.border-opacity-70\% {
  --border-o: 0.7;
}

.border-opacity-80\% {
  --border-o: 0.8;
}

.border-opacity-90\% {
  --border-o: 0.9;
}

.radius-sm {
  border-radius: var(--radius-sm);
}

.radius-md {
  border-radius: var(--radius-md);
}

.radius-lg {
  border-radius: var(--radius-lg);
}

.radius-50\% {
  border-radius: 50%;
}

.radius-full {
  border-radius: 50em;
}

.shadow-xs {
  box-shadow: var(--shadow-xs);
}

.shadow-sm {
  box-shadow: var(--shadow-sm);
}

.shadow-md {
  box-shadow: var(--shadow-md);
}

.shadow-lg {
  box-shadow: var(--shadow-lg);
}

.shadow-xl {
  box-shadow: var(--shadow-xl);
}

.bg, [class*="bg-"] {
  --bg-o: 1;
}

.bg-transparent {
  background-color: transparent;
}

.bg-inherit {
  background-color: inherit;
}

.bg {
  background-color: hsla(var(--color-bg-h), var(--color-bg-s), var(--color-bg-l), var(--bg-o));
}

.bg-contrast-lower {
  background-color: hsla(var(--color-contrast-lower-h), var(--color-contrast-lower-s), var(--color-contrast-lower-l), var(--bg-o, 1));
}

.bg-contrast-low {
  background-color: hsla(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l), var(--bg-o, 1));
}

.bg-contrast-medium {
  background-color: hsla(var(--color-contrast-medium-h), var(--color-contrast-medium-s), var(--color-contrast-medium-l), var(--bg-o, 1));
}

.bg-contrast-high {
  background-color: hsla(var(--color-contrast-high-h), var(--color-contrast-high-s), var(--color-contrast-high-l), var(--bg-o, 1));
}

.bg-contrast-higher {
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), var(--bg-o, 1));
}

.bg-primary-darker {
  background-color: hsla(var(--color-primary-darker-h), var(--color-primary-darker-s), var(--color-primary-darker-l), var(--bg-o, 1));
}

.bg-primary-dark {
  background-color: hsla(var(--color-primary-dark-h), var(--color-primary-dark-s), var(--color-primary-dark-l), var(--bg-o, 1));
}

.bg-primary {
  background-color: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), var(--bg-o, 1));
}

.bg-primary-light {
  background-color: hsla(var(--color-primary-light-h), var(--color-primary-light-s), var(--color-primary-light-l), var(--bg-o, 1));
}

.bg-primary-lighter {
  background-color: hsla(var(--color-primary-lighter-h), var(--color-primary-lighter-s), var(--color-primary-lighter-l), var(--bg-o, 1));
}

.bg-accent-darker {
  background-color: hsla(var(--color-accent-darker-h), var(--color-accent-darker-s), var(--color-accent-darker-l), var(--bg-o, 1));
}

.bg-accent-dark {
  background-color: hsla(var(--color-accent-dark-h), var(--color-accent-dark-s), var(--color-accent-dark-l), var(--bg-o, 1));
}

.bg-accent {
  background-color: hsla(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l), var(--bg-o, 1));
}

.bg-accent-light {
  background-color: hsla(var(--color-accent-light-h), var(--color-accent-light-s), var(--color-accent-light-l), var(--bg-o, 1));
}

.bg-accent-lighter {
  background-color: hsla(var(--color-accent-lighter-h), var(--color-accent-lighter-s), var(--color-accent-lighter-l), var(--bg-o, 1));
}

.bg-success-darker {
  background-color: hsla(var(--color-success-darker-h), var(--color-success-darker-s), var(--color-success-darker-l), var(--bg-o, 1));
}

.bg-success-dark {
  background-color: hsla(var(--color-success-dark-h), var(--color-success-dark-s), var(--color-success-dark-l), var(--bg-o, 1));
}

.bg-success {
  background-color: hsla(var(--color-success-h), var(--color-success-s), var(--color-success-l), var(--bg-o, 1));
}

.bg-success-light {
  background-color: hsla(var(--color-success-light-h), var(--color-success-light-s), var(--color-success-light-l), var(--bg-o, 1));
}

.bg-success-lighter {
  background-color: hsla(var(--color-success-lighter-h), var(--color-success-lighter-s), var(--color-success-lighter-l), var(--bg-o, 1));
}

.bg-warning-darker {
  background-color: hsla(var(--color-warning-darker-h), var(--color-warning-darker-s), var(--color-warning-darker-l), var(--bg-o, 1));
}

.bg-warning-dark {
  background-color: hsla(var(--color-warning-dark-h), var(--color-warning-dark-s), var(--color-warning-dark-l), var(--bg-o, 1));
}

.bg-warning {
  background-color: hsla(var(--color-warning-h), var(--color-warning-s), var(--color-warning-l), var(--bg-o, 1));
}

.bg-warning-light {
  background-color: hsla(var(--color-warning-light-h), var(--color-warning-light-s), var(--color-warning-light-l), var(--bg-o, 1));
}

.bg-warning-lighter {
  background-color: hsla(var(--color-warning-lighter-h), var(--color-warning-lighter-s), var(--color-warning-lighter-l), var(--bg-o, 1));
}

.bg-error-darker {
  background-color: hsla(var(--color-error-darker-h), var(--color-error-darker-s), var(--color-error-darker-l), var(--bg-o, 1));
}

.bg-error-dark {
  background-color: hsla(var(--color-error-dark-h), var(--color-error-dark-s), var(--color-error-dark-l), var(--bg-o, 1));
}

.bg-error {
  background-color: hsla(var(--color-error-h), var(--color-error-s), var(--color-error-l), var(--bg-o, 1));
}

.bg-error-light {
  background-color: hsla(var(--color-error-light-h), var(--color-error-light-s), var(--color-error-light-l), var(--bg-o, 1));
}

.bg-error-lighter {
  background-color: hsla(var(--color-error-lighter-h), var(--color-error-lighter-s), var(--color-error-lighter-l), var(--bg-o, 1));
}

.bg-white {
  background-color: hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), var(--bg-o, 1));
}

.bg-black {
  background-color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), var(--bg-o, 1));
}

.bg-opacity-0 {
  --bg-o: 0;
}

.bg-opacity-10\% {
  --bg-o: 0.1;
}

.bg-opacity-20\% {
  --bg-o: 0.2;
}

.bg-opacity-30\% {
  --bg-o: 0.3;
}

.bg-opacity-40\% {
  --bg-o: 0.4;
}

.bg-opacity-50\% {
  --bg-o: 0.5;
}

.bg-opacity-60\% {
  --bg-o: 0.6;
}

.bg-opacity-70\% {
  --bg-o: 0.7;
}

.bg-opacity-80\% {
  --bg-o: 0.8;
}

.bg-opacity-90\% {
  --bg-o: 0.9;
}

.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.backdrop-blur-10 {
  backdrop-filter: blur(10px);
}

.backdrop-blur-20 {
  backdrop-filter: blur(20px);
}

.isolate {
  isolation: isolate;
}

.blend-multiply {
  mix-blend-mode: multiply;
}

.blend-overlay {
  mix-blend-mode: overlay;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.flip {
  transform: scale(-1);
}

.flip-x {
  transform: scaleX(-1);
}

.flip-y {
  transform: scaleY(-1);
}

@media (min-width: 40rem) {
  .flex\@xs {
    display: flex;
  }
  .inline-flex\@xs {
    display: inline-flex;
  }
  .flex-wrap\@xs {
    flex-wrap: wrap;
  }
  .flex-column\@xs {
    flex-direction: column;
  }
  .flex-column-reverse\@xs {
    flex-direction: column-reverse;
  }
  .flex-row\@xs {
    flex-direction: row;
  }
  .flex-row-reverse\@xs {
    flex-direction: row-reverse;
  }
  .flex-center\@xs {
    justify-content: center;
    align-items: center;
  }
  .flex-grow-0\@xs {
    flex-grow: 0;
  }
  .flex-grow\@xs {
    flex-grow: 1;
  }
  .flex-shrink-0\@xs {
    flex-shrink: 0;
  }
  .flex-shrink\@xs {
    flex-shrink: 1;
  }
  .justify-start\@xs {
    justify-content: flex-start;
  }
  .justify-end\@xs {
    justify-content: flex-end;
  }
  .justify-center\@xs {
    justify-content: center;
  }
  .justify-between\@xs {
    justify-content: space-between;
  }
  .items-center\@xs {
    align-items: center;
  }
  .items-start\@xs {
    align-items: flex-start;
  }
  .items-end\@xs {
    align-items: flex-end;
  }
  .items-baseline\@xs {
    align-items: baseline;
  }
  .order-1\@xs {
    order: 1;
  }
  .order-2\@xs {
    order: 2;
  }
  .order-3\@xs {
    order: 3;
  }
  .block\@xs {
    display: block;
  }
  .inline-block\@xs {
    display: inline-block;
  }
  .inline\@xs {
    display: inline;
  }
  .text-center\@xs {
    text-align: center;
  }
  .text-left\@xs {
    text-align: left;
  }
  .text-right\@xs {
    text-align: right;
  }
  .text-justify\@xs {
    text-align: justify;
  }
  .text-xs\@xs {
    font-size: 0.694em;
  }
  .text-sm\@xs {
    font-size: 0.833em;
  }
  .text-base\@xs {
    font-size: 1em;
  }
  .text-md\@xs {
    font-size: 1.2em;
  }
  .text-lg\@xs {
    font-size: 1.44em;
  }
  .text-xl\@xs {
    font-size: 1.728em;
  }
  .text-xxl\@xs {
    font-size: 2.074em;
  }
  .text-xxxl\@xs {
    font-size: 2.488em;
  }
  .text-xxxxl\@xs {
    font-size: 2.985em;
  }
  .hide\@xs {
    display: none !important;
  }
}

@media not all and (min-width: 40rem) {
  .has-margin\@xs {
    margin: 0 !important;
  }
  .has-padding\@xs {
    padding: 0 !important;
  }
  .display\@xs {
    display: none !important;
  }
}

@media (min-width: 48rem) {
  .flex\@sm {
    display: flex;
  }
  .inline-flex\@sm {
    display: inline-flex;
  }
  .flex-wrap\@sm {
    flex-wrap: wrap;
  }
  .flex-column\@sm {
    flex-direction: column;
  }
  .flex-column-reverse\@sm {
    flex-direction: column-reverse;
  }
  .flex-row\@sm {
    flex-direction: row;
  }
  .flex-row-reverse\@sm {
    flex-direction: row-reverse;
  }
  .flex-center\@sm {
    justify-content: center;
    align-items: center;
  }
  .flex-grow-0\@sm {
    flex-grow: 0;
  }
  .flex-grow\@sm {
    flex-grow: 1;
  }
  .flex-shrink-0\@sm {
    flex-shrink: 0;
  }
  .flex-shrink\@sm {
    flex-shrink: 1;
  }
  .justify-start\@sm {
    justify-content: flex-start;
  }
  .justify-end\@sm {
    justify-content: flex-end;
  }
  .justify-center\@sm {
    justify-content: center;
  }
  .justify-between\@sm {
    justify-content: space-between;
  }
  .items-center\@sm {
    align-items: center;
  }
  .items-start\@sm {
    align-items: flex-start;
  }
  .items-end\@sm {
    align-items: flex-end;
  }
  .items-baseline\@sm {
    align-items: baseline;
  }
  .order-1\@sm {
    order: 1;
  }
  .order-2\@sm {
    order: 2;
  }
  .order-3\@sm {
    order: 3;
  }
  .block\@sm {
    display: block;
  }
  .inline-block\@sm {
    display: inline-block;
  }
  .inline\@sm {
    display: inline;
  }
  .text-center\@sm {
    text-align: center;
  }
  .text-left\@sm {
    text-align: left;
  }
  .text-right\@sm {
    text-align: right;
  }
  .text-justify\@sm {
    text-align: justify;
  }
  .text-xs\@sm {
    font-size: 0.694em;
  }
  .text-sm\@sm {
    font-size: 0.833em;
  }
  .text-base\@sm {
    font-size: 1em;
  }
  .text-md\@sm {
    font-size: 1.2em;
  }
  .text-lg\@sm {
    font-size: 1.44em;
  }
  .text-xl\@sm {
    font-size: 1.728em;
  }
  .text-xxl\@sm {
    font-size: 2.074em;
  }
  .text-xxxl\@sm {
    font-size: 2.488em;
  }
  .text-xxxxl\@sm {
    font-size: 2.985em;
  }
  .hide\@sm {
    display: none !important;
  }
}

@media not all and (min-width: 48rem) {
  .has-margin\@sm {
    margin: 0 !important;
  }
  .has-padding\@sm {
    padding: 0 !important;
  }
  .display\@sm {
    display: none !important;
  }
}

@media (min-width: 64rem) {
  .flex\@md {
    display: flex;
  }
  .inline-flex\@md {
    display: inline-flex;
  }
  .flex-wrap\@md {
    flex-wrap: wrap;
  }
  .flex-column\@md {
    flex-direction: column;
  }
  .flex-column-reverse\@md {
    flex-direction: column-reverse;
  }
  .flex-row\@md {
    flex-direction: row;
  }
  .flex-row-reverse\@md {
    flex-direction: row-reverse;
  }
  .flex-center\@md {
    justify-content: center;
    align-items: center;
  }
  .flex-grow-0\@md {
    flex-grow: 0;
  }
  .flex-grow\@md {
    flex-grow: 1;
  }
  .flex-shrink-0\@md {
    flex-shrink: 0;
  }
  .flex-shrink\@md {
    flex-shrink: 1;
  }
  .justify-start\@md {
    justify-content: flex-start;
  }
  .justify-end\@md {
    justify-content: flex-end;
  }
  .justify-center\@md {
    justify-content: center;
  }
  .justify-between\@md {
    justify-content: space-between;
  }
  .items-center\@md {
    align-items: center;
  }
  .items-start\@md {
    align-items: flex-start;
  }
  .items-end\@md {
    align-items: flex-end;
  }
  .items-baseline\@md {
    align-items: baseline;
  }
  .order-1\@md {
    order: 1;
  }
  .order-2\@md {
    order: 2;
  }
  .order-3\@md {
    order: 3;
  }
  .block\@md {
    display: block;
  }
  .inline-block\@md {
    display: inline-block;
  }
  .inline\@md {
    display: inline;
  }
  .text-center\@md {
    text-align: center;
  }
  .text-left\@md {
    text-align: left;
  }
  .text-right\@md {
    text-align: right;
  }
  .text-justify\@md {
    text-align: justify;
  }
  .text-xs\@md {
    font-size: 0.694em;
  }
  .text-sm\@md {
    font-size: 0.833em;
  }
  .text-base\@md {
    font-size: 1em;
  }
  .text-md\@md {
    font-size: 1.2em;
  }
  .text-lg\@md {
    font-size: 1.44em;
  }
  .text-xl\@md {
    font-size: 1.728em;
  }
  .text-xxl\@md {
    font-size: 2.074em;
  }
  .text-xxxl\@md {
    font-size: 2.488em;
  }
  .text-xxxxl\@md {
    font-size: 2.985em;
  }
  .hide\@md {
    display: none !important;
  }
}

@media not all and (min-width: 64rem) {
  .has-margin\@md {
    margin: 0 !important;
  }
  .has-padding\@md {
    padding: 0 !important;
  }
  .display\@md {
    display: none !important;
  }
}

@media (min-width: 80rem) {
  .flex\@lg {
    display: flex;
  }
  .inline-flex\@lg {
    display: inline-flex;
  }
  .flex-wrap\@lg {
    flex-wrap: wrap;
  }
  .flex-column\@lg {
    flex-direction: column;
  }
  .flex-column-reverse\@lg {
    flex-direction: column-reverse;
  }
  .flex-row\@lg {
    flex-direction: row;
  }
  .flex-row-reverse\@lg {
    flex-direction: row-reverse;
  }
  .flex-center\@lg {
    justify-content: center;
    align-items: center;
  }
  .flex-grow-0\@lg {
    flex-grow: 0;
  }
  .flex-grow\@lg {
    flex-grow: 1;
  }
  .flex-shrink-0\@lg {
    flex-shrink: 0;
  }
  .flex-shrink\@lg {
    flex-shrink: 1;
  }
  .justify-start\@lg {
    justify-content: flex-start;
  }
  .justify-end\@lg {
    justify-content: flex-end;
  }
  .justify-center\@lg {
    justify-content: center;
  }
  .justify-between\@lg {
    justify-content: space-between;
  }
  .items-center\@lg {
    align-items: center;
  }
  .items-start\@lg {
    align-items: flex-start;
  }
  .items-end\@lg {
    align-items: flex-end;
  }
  .items-baseline\@lg {
    align-items: baseline;
  }
  .order-1\@lg {
    order: 1;
  }
  .order-2\@lg {
    order: 2;
  }
  .order-3\@lg {
    order: 3;
  }
  .block\@lg {
    display: block;
  }
  .inline-block\@lg {
    display: inline-block;
  }
  .inline\@lg {
    display: inline;
  }
  .text-center\@lg {
    text-align: center;
  }
  .text-left\@lg {
    text-align: left;
  }
  .text-right\@lg {
    text-align: right;
  }
  .text-justify\@lg {
    text-align: justify;
  }
  .text-xs\@lg {
    font-size: 0.694em;
  }
  .text-sm\@lg {
    font-size: 0.833em;
  }
  .text-base\@lg {
    font-size: 1em;
  }
  .text-md\@lg {
    font-size: 1.2em;
  }
  .text-lg\@lg {
    font-size: 1.44em;
  }
  .text-xl\@lg {
    font-size: 1.728em;
  }
  .text-xxl\@lg {
    font-size: 2.074em;
  }
  .text-xxxl\@lg {
    font-size: 2.488em;
  }
  .text-xxxxl\@lg {
    font-size: 2.985em;
  }
  .hide\@lg {
    display: none !important;
  }
}

@media not all and (min-width: 80rem) {
  .has-margin\@lg {
    margin: 0 !important;
  }
  .has-padding\@lg {
    padding: 0 !important;
  }
  .display\@lg {
    display: none !important;
  }
}

@media (min-width: 90rem) {
  .flex\@xl {
    display: flex;
  }
  .inline-flex\@xl {
    display: inline-flex;
  }
  .flex-wrap\@xl {
    flex-wrap: wrap;
  }
  .flex-column\@xl {
    flex-direction: column;
  }
  .flex-column-reverse\@xl {
    flex-direction: column-reverse;
  }
  .flex-row\@xl {
    flex-direction: row;
  }
  .flex-row-reverse\@xl {
    flex-direction: row-reverse;
  }
  .flex-center\@xl {
    justify-content: center;
    align-items: center;
  }
  .flex-grow-0\@xl {
    flex-grow: 0;
  }
  .flex-grow\@xl {
    flex-grow: 1;
  }
  .flex-shrink-0\@xl {
    flex-shrink: 0;
  }
  .flex-shrink\@xl {
    flex-shrink: 1;
  }
  .justify-start\@xl {
    justify-content: flex-start;
  }
  .justify-end\@xl {
    justify-content: flex-end;
  }
  .justify-center\@xl {
    justify-content: center;
  }
  .justify-between\@xl {
    justify-content: space-between;
  }
  .items-center\@xl {
    align-items: center;
  }
  .items-start\@xl {
    align-items: flex-start;
  }
  .items-end\@xl {
    align-items: flex-end;
  }
  .items-baseline\@xl {
    align-items: baseline;
  }
  .order-1\@xl {
    order: 1;
  }
  .order-2\@xl {
    order: 2;
  }
  .order-3\@xl {
    order: 3;
  }
  .block\@xl {
    display: block;
  }
  .inline-block\@xl {
    display: inline-block;
  }
  .inline\@xl {
    display: inline;
  }
  .text-center\@xl {
    text-align: center;
  }
  .text-left\@xl {
    text-align: left;
  }
  .text-right\@xl {
    text-align: right;
  }
  .text-justify\@xl {
    text-align: justify;
  }
  .text-xs\@xl {
    font-size: 0.694em;
  }
  .text-sm\@xl {
    font-size: 0.833em;
  }
  .text-base\@xl {
    font-size: 1em;
  }
  .text-md\@xl {
    font-size: 1.2em;
  }
  .text-lg\@xl {
    font-size: 1.44em;
  }
  .text-xl\@xl {
    font-size: 1.728em;
  }
  .text-xxl\@xl {
    font-size: 2.074em;
  }
  .text-xxxl\@xl {
    font-size: 2.488em;
  }
  .text-xxxxl\@xl {
    font-size: 2.985em;
  }
  .hide\@xl {
    display: none !important;
  }
}

@media not all and (min-width: 90rem) {
  .has-margin\@xl {
    margin: 0 !important;
  }
  .has-padding\@xl {
    padding: 0 !important;
  }
  .display\@xl {
    display: none !important;
  }
}

:root {
  --heading-line-height: 1.2;
  --body-line-height: 1.4;
}

body {
  font-size: var(--text-base-size, 1em);
  font-family: var(--font-primary, sans-serif);
  color: var(--color-white);
}

h1, h2, h3, h4 {
  line-height: var(--heading-line-height, 1.2);
}

.text-xxxxl {
  font-size: var(--text-xxxxl, 2.985em);
}

.text-xxxl {
  font-size: var(--text-xxxl, 2.488em);
}

h1, .text-xxl {
  font-size: var(--text-xxl, 2.074em);
}

h2, .text-xl {
  font-size: var(--text-xl, 1.728em);
}

h3, .text-lg {
  font-size: var(--text-lg, 1.44em);
}

h4, .text-md {
  font-size: var(--text-md, 1.2em);
}

.text-base {
  font-size: 1em;
}

small, .text-sm {
  font-size: var(--text-sm, 0.833em);
}

.text-xs {
  font-size: var(--text-xs, 0.694em);
}

a, .link {
  color: var(--color-primary, #2a6df4);
}

strong {
  font-weight: bold;
}

s {
  text-decoration: line-through;
}

u {
  text-decoration: underline;
}

.text-component h1, .text-component h2, .text-component h3, .text-component h4 {
  line-height: calc(var(--heading-line-height) * var(--line-height-multiplier, 1));
  margin-bottom: calc(var(--space-unit) * 0.25 * var(--text-vspace-multiplier, 1));
}

.text-component h2, .text-component h3, .text-component h4 {
  margin-top: calc(var(--space-unit) * 0.75 * var(--text-vspace-multiplier, 1));
}

.text-component p, .text-component blockquote, .text-component ul li, .text-component ol li {
  line-height: calc(var(--body-line-height) * var(--line-height-multiplier, 1));
}

.text-component ul, .text-component ol, .text-component p, .text-component blockquote, .text-component .text-component__block {
  margin-bottom: calc(var(--space-unit) * 0.75 * var(--text-vspace-multiplier, 1));
}

.text-component ul, .text-component ol {
  list-style-position: inside;
}

.text-component ul {
  list-style-type: disc;
}

.text-component ol {
  list-style-type: decimal;
}

.text-component img {
  display: block;
  margin: 0 auto;
}

.text-component figcaption {
  text-align: center;
  margin-top: calc(var(--space-unit) * 0.5);
}

.text-component em {
  font-style: italic;
}

.text-component hr {
  margin-top: calc(var(--space-unit) * var(--text-vspace-multiplier, 1));
  margin-bottom: calc(var(--space-unit) * var(--text-vspace-multiplier, 1));
  margin-left: auto;
  margin-right: auto;
}

.text-component > *:first-child {
  margin-top: 0;
}

.text-component > *:last-child {
  margin-bottom: 0;
}

.text-component__block--full-width {
  width: 100vw;
  margin-left: calc(50% - 50vw);
}

@media (min-width: 48rem) {
  .text-component__block--left,
  .text-component__block--right {
    width: 45%;
  }
  .text-component__block--left img,
  .text-component__block--right img {
    width: 100%;
  }
  .text-component__block--left {
    float: left;
    margin-right: calc(var(--space-unit) * 0.75 * var(--text-vspace-multiplier, 1));
  }
  .text-component__block--right {
    float: right;
    margin-left: calc(var(--space-unit) * 0.75 * var(--text-vspace-multiplier, 1));
  }
}

@media (min-width: 90rem) {
  .text-component__block--outset {
    width: calc(100% + 10.5 * var(--space-unit));
  }
  .text-component__block--outset img {
    width: 100%;
  }
  .text-component__block--outset:not(.text-component__block--right) {
    margin-left: calc(-5.25 * var(--space-unit));
  }
  .text-component__block--left, .text-component__block--right {
    width: 50%;
  }
  .text-component__block--right.text-component__block--outset {
    margin-right: calc(-5.25 * var(--space-unit));
  }
}

:root {
  --icon-xxxs: 8px;
  --icon-xxs:  12px;
  --icon-xs:   16px;
  --icon-sm:   24px;
  --icon-md:   32px;
  --icon-lg:   48px;
  --icon-xl:   64px;
  --icon-xxl:  96px;
  --icon-xxxl: 128px;
}

.icon {
  display: inline-block;
  color: inherit;
  fill: currentColor;
  height: 1em;
  width: 1em;
  line-height: 1;
  flex-shrink: 0;
  max-width: initial;
}

.icon--xxxs {
  font-size: var(--icon-xxxs);
}

.icon--xxs {
  font-size: var(--icon-xxs);
}

.icon--xs {
  font-size: var(--icon-xs);
}

.icon--sm {
  font-size: var(--icon-sm);
}

.icon--md {
  font-size: var(--icon-md);
}

.icon--lg {
  font-size: var(--icon-lg);
}

.icon--xl {
  font-size: var(--icon-xl);
}

.icon--xxl {
  font-size: var(--icon-xxl);
}

.icon--xxxl {
  font-size: var(--icon-xxxl);
}

.icon--is-spinning {
  animation: icon-spin 1s infinite linear;
}

@keyframes icon-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.icon use {
  color: inherit;
  fill: currentColor;
}

.btn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  text-decoration: none;
  line-height: 1;
  font-size: var(--btn-font-size, 1em);
  padding-top: var(--btn-padding-y, 0.5em);
  padding-bottom: var(--btn-padding-y, 0.5em);
  padding-left: var(--btn-padding-x, 0.75em);
  padding-right: var(--btn-padding-x, 0.75em);
  border-radius: var(--btn-radius, 0.25em);
}

.btn--sm {
  font-size: var(--btn-font-size-sm, 0.8em);
}

.btn--md {
  font-size: var(--btn-font-size-md, 1.2em);
}

.btn--lg {
  font-size: var(--btn-font-size-lg, 1.4em);
}

.btn--icon {
  padding: var(--btn-padding-y, 0.5em);
}

.form-control {
  padding-top: var(--form-control-padding-y, 0.5em);
  padding-bottom: var(--form-control-padding-y, 0.5em);
  padding-left: var(--form-control-padding-x, 0.75em);
  padding-right: var(--form-control-padding-x, 0.75em);
  border-radius: var(--form-control-radius, 0.25em);
}

.form-legend {
  color: var(--color-contrast-higher, #1c1c21);
  line-height: var(--heading-line-height, 1.2);
  font-size: var(--text-md, 1.2em);
  margin-bottom: var(--space-xxs);
}

.form-label {
  display: inline-block;
}

.form__msg-error, .form-error-msg {
  color: var(--color-error, #e02447);
  font-size: var(--text-sm, 0.833em);
  margin-top: var(--space-xxs);
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
}

.form__msg-error--is-visible, .form-error-msg--is-visible {
  position: relative;
  clip: auto;
}

.radio-list > *, .checkbox-list > * {
  position: relative;
  display: flex;
  align-items: baseline;
}

.radio-list label, .checkbox-list label {
  line-height: var(--body-line-height);
}

.radio-list input, .checkbox-list input {
  margin-right: var(--space-xxxs);
  flex-shrink: 0;
}

:root {
  --display: block;
}

.is-visible {
  display: var(--display) !important;
}

.is-hidden {
  display: none !important;
}

html:not(.js) .no-js\:is-hidden {
  display: none !important;
}

@media print {
  .print\:is-hidden {
    display: none !important;
  }
}

.sr-only {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  overflow: hidden;
  padding: 0;
  border: 0;
  white-space: nowrap;
}

:root, [data-theme="default"] {
  --color-primary-darker: hsl(209, 56%, 32%);
  --color-primary-darker-h: 209;
  --color-primary-darker-s: 56%;
  --color-primary-darker-l: 32%;
  --color-primary-dark: hsl(209, 56%, 42%);
  --color-primary-dark-h: 209;
  --color-primary-dark-s: 56%;
  --color-primary-dark-l: 42%;
  --color-primary: hsl(209, 56%, 52%);
  --color-primary-h: 209;
  --color-primary-s: 56%;
  --color-primary-l: 52%;
  --color-primary-light: hsl(209, 56%, 62%);
  --color-primary-light-h: 209;
  --color-primary-light-s: 56%;
  --color-primary-light-l: 62%;
  --color-primary-lighter: hsl(209, 56%, 72%);
  --color-primary-lighter-h: 209;
  --color-primary-lighter-s: 56%;
  --color-primary-lighter-l: 72%;
  --color-accent-darker: hsl(230, 42%, 34%);
  --color-accent-darker-h: 230;
  --color-accent-darker-s: 42%;
  --color-accent-darker-l: 34%;
  --color-accent-dark: hsl(230, 42%, 44%);
  --color-accent-dark-h: 230;
  --color-accent-dark-s: 42%;
  --color-accent-dark-l: 44%;
  --color-accent: hsl(230, 42%, 54%);
  --color-accent-h: 230;
  --color-accent-s: 42%;
  --color-accent-l: 54%;
  --color-accent-light: hsl(230, 42%, 64%);
  --color-accent-light-h: 230;
  --color-accent-light-s: 42%;
  --color-accent-light-l: 64%;
  --color-accent-lighter: hsl(230, 42%, 74%);
  --color-accent-lighter-h: 230;
  --color-accent-lighter-s: 42%;
  --color-accent-lighter-l: 74%;
  --color-black: hsl(213, 78%, 7%);
  --color-black-h: 213;
  --color-black-s: 78%;
  --color-black-l: 7%;
  --color-white: hsl(0, 0%, 100%);
  --color-white-h: 0;
  --color-white-s: 0%;
  --color-white-l: 100%;
  --color-warning-darker: hsl(26, 93%, 38%);
  --color-warning-darker-h: 26;
  --color-warning-darker-s: 93%;
  --color-warning-darker-l: 38%;
  --color-warning-dark: hsl(26, 93%, 48%);
  --color-warning-dark-h: 26;
  --color-warning-dark-s: 93%;
  --color-warning-dark-l: 48%;
  --color-warning: hsl(26, 93%, 58%);
  --color-warning-h: 26;
  --color-warning-s: 93%;
  --color-warning-l: 58%;
  --color-warning-light: hsl(26, 93%, 68%);
  --color-warning-light-h: 26;
  --color-warning-light-s: 93%;
  --color-warning-light-l: 68%;
  --color-warning-lighter: hsl(26, 93%, 78%);
  --color-warning-lighter-h: 26;
  --color-warning-lighter-s: 93%;
  --color-warning-lighter-l: 78%;
  --color-success-darker: hsl(165, 31%, 30%);
  --color-success-darker-h: 165;
  --color-success-darker-s: 31%;
  --color-success-darker-l: 30%;
  --color-success-dark: hsl(165, 31%, 40%);
  --color-success-dark-h: 165;
  --color-success-dark-s: 31%;
  --color-success-dark-l: 40%;
  --color-success: hsl(165, 31%, 50%);
  --color-success-h: 165;
  --color-success-s: 31%;
  --color-success-l: 50%;
  --color-success-light: hsl(165, 31%, 60%);
  --color-success-light-h: 165;
  --color-success-light-s: 31%;
  --color-success-light-l: 60%;
  --color-success-lighter: hsl(165, 31%, 70%);
  --color-success-lighter-h: 165;
  --color-success-lighter-s: 31%;
  --color-success-lighter-l: 70%;
  --color-error-darker: hsl(333, 77%, 40%);
  --color-error-darker-h: 333;
  --color-error-darker-s: 77%;
  --color-error-darker-l: 40%;
  --color-error-dark: hsl(333, 77%, 50%);
  --color-error-dark-h: 333;
  --color-error-dark-s: 77%;
  --color-error-dark-l: 50%;
  --color-error: hsl(333, 77%, 60%);
  --color-error-h: 333;
  --color-error-s: 77%;
  --color-error-l: 60%;
  --color-error-light: hsl(333, 77%, 70%);
  --color-error-light-h: 333;
  --color-error-light-s: 77%;
  --color-error-light-l: 70%;
  --color-error-lighter: hsl(333, 77%, 80%);
  --color-error-lighter-h: 333;
  --color-error-lighter-s: 77%;
  --color-error-lighter-l: 80%;
  --color-bg: hsl(214, 78%, 7%);
  --color-bg-h: 214;
  --color-bg-s: 78%;
  --color-bg-l: 7%;
  --color-contrast-lower: hsl(218, 41%, 11%);
  --color-contrast-lower-h: 218;
  --color-contrast-lower-s: 41%;
  --color-contrast-lower-l: 11%;
  --color-contrast-low: hsl(220, 20%, 21%);
  --color-contrast-low-h: 220;
  --color-contrast-low-s: 20%;
  --color-contrast-low-l: 21%;
  --color-contrast-medium: hsl(222, 6%, 55%);
  --color-contrast-medium-h: 222;
  --color-contrast-medium-s: 6%;
  --color-contrast-medium-l: 55%;
  --color-contrast-high: hsl(220, 5%, 88%);
  --color-contrast-high-h: 220;
  --color-contrast-high-s: 5%;
  --color-contrast-high-l: 88%;
  --color-contrast-higher: hsl(0, 0%, 100%);
  --color-contrast-higher-h: 0;
  --color-contrast-higher-s: 0%;
  --color-contrast-higher-l: 100%;
  --gradient-primary-accent: linear-gradient(to right top, var(--color-primary), var(--color-accent));
}

.bg--gradient-animated {
  background-color: var(--color-primary);
  background-image: linear-gradient(to right top, var(--color-primary), var(--color-accent), var(--color-success));
  background-size: 300% 300%;
  animation: animated-gradient 10s ease infinite;
}

@keyframes animated-gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

:root {
  --radius: 0.5em;
}

.container {
  max-width: 1350px;
  margin-left: auto;
  margin-right: auto;
}

.container {
  margin-top: 4em;
  margin-bottom: 4em;
  padding-left: 2em;
  padding-right: 2em;
}

.container > *:first-child {
  margin-top: 0;
}

hr {
  opacity: 0.2;
  border-top: 1px solid #888;
  height: 1px;
  width: 100%;
  margin: 1.5rem 0;
}

:root {
  --font-primary: 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  --text-base-size: 1em;
  --text-scale-ratio: 1.3076605;
  --body-line-height: 1.4;
  --heading-line-height: 1.2;
  --font-primary-capital-letter: 0.8;
  --text-unit: 1em;
}

:root, * {
  --text-xs: calc((var(--text-unit) / var(--text-scale-ratio)) / var(--text-scale-ratio));
  --text-sm: calc(var(--text-xs) * var(--text-scale-ratio));
  --text-md: calc(var(--text-sm) * var(--text-scale-ratio) * var(--text-scale-ratio));
  --text-lg: calc(var(--text-md) * var(--text-scale-ratio));
  --text-xl: calc(var(--text-lg) * var(--text-scale-ratio));
  --text-xxl: calc(var(--text-xl) * var(--text-scale-ratio));
  --text-xxxl: calc(var(--text-xxl) * var(--text-scale-ratio));
  --text-xxxxl: calc(var(--text-xxxl) * var(--text-scale-ratio));
}

html {
  font-size: calc(8px + 0.22222vw + 0.11111vh);
}

body {
  font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
}

b, strong, .bold {
  font-weight: bold;
}

.font-size--6,
h5 {
  font-family: "Jost", sans-serif, serif;
  word-wrap: break-word;
  font-weight: 500;
  font-size: 1.42857rem;
  line-height: 1.4;
  padding: 0.035rem 0;
  margin-top: 1.4em;
}

.font-size--6:first-child,
h5:first-child {
  margin-top: 0;
}

.font-size--6::before, .font-size--6::after,
h5::before,
h5::after {
  content: "";
  display: block;
  height: 0;
}

.font-size--6::before,
h5::before {
  margin-top: -0.0325rem;
}

.font-size--6::after,
h5::after {
  margin-bottom: -0.0375rem;
}

@media (min-width: 40rem) {
  .font-size--6,
  h5 {
    font-weight: 500;
    font-size: 1.42857rem;
    line-height: 1.4;
    padding: 0.035rem 0;
    margin-top: 1.4em;
  }
  .font-size--6::before, .font-size--6::after,
  h5::before,
  h5::after {
    content: "";
    display: block;
    height: 0;
  }
  .font-size--6::before,
  h5::before {
    margin-top: -0.0325rem;
  }
  .font-size--6::after,
  h5::after {
    margin-bottom: -0.0375rem;
  }
}

.font-size--5,
h4 {
  font-family: "Jost", sans-serif, serif;
  word-wrap: break-word;
  font-weight: 500;
  font-size: 2.5rem;
  line-height: 1.1;
  padding: 0.02rem 0;
  margin-top: 1.1em;
}

.font-size--5:first-child,
h4:first-child {
  margin-top: 0;
}

.font-size--5::before, .font-size--5::after,
h4::before,
h4::after {
  content: "";
  display: block;
  height: 0;
}

.font-size--5::before,
h4::before {
  margin-top: -0.0175rem;
}

.font-size--5::after,
h4::after {
  margin-bottom: -0.0225rem;
}

@media (min-width: 40rem) {
  .font-size--5,
  h4 {
    font-weight: 500;
    font-size: 3.07143rem;
    line-height: 1.02558;
    padding: 0.01628rem 0;
    margin-top: 1.02558em;
  }
  .font-size--5::before, .font-size--5::after,
  h4::before,
  h4::after {
    content: "";
    display: block;
    height: 0;
  }
  .font-size--5::before,
  h4::before {
    margin-top: -0.01378rem;
  }
  .font-size--5::after,
  h4::after {
    margin-bottom: -0.01878rem;
  }
}

.font-size--4,
h3 {
  font-family: "Jost", sans-serif, serif;
  word-wrap: break-word;
  font-weight: 500;
  font-size: 3.57143rem;
  line-height: 0.98;
  padding: 0.014rem 0;
  margin-top: 0.98em;
}

.font-size--4:first-child,
h3:first-child {
  margin-top: 0;
}

.font-size--4::before, .font-size--4::after,
h3::before,
h3::after {
  content: "";
  display: block;
  height: 0;
}

.font-size--4::before,
h3::before {
  margin-top: -0.0115rem;
}

.font-size--4::after,
h3::after {
  margin-bottom: -0.0165rem;
}

@media (min-width: 40rem) {
  .font-size--4,
  h3 {
    font-weight: 500;
    font-size: 4.71429rem;
    line-height: 0.91212;
    padding: 0.01061rem 0;
    margin-top: 0.91212em;
  }
  .font-size--4::before, .font-size--4::after,
  h3::before,
  h3::after {
    content: "";
    display: block;
    height: 0;
  }
  .font-size--4::before,
  h3::before {
    margin-top: -0.00811rem;
  }
  .font-size--4::after,
  h3::after {
    margin-bottom: -0.01311rem;
  }
}

.font-size--3,
h2 {
  font-family: "Jost", sans-serif, serif;
  word-wrap: break-word;
  font-weight: 500;
  font-size: 4.64286rem;
  line-height: 0.91538;
  padding: 0.01077rem 0;
  margin-top: 0.91538em;
}

.font-size--3:first-child,
h2:first-child {
  margin-top: 0;
}

.font-size--3::before, .font-size--3::after,
h2::before,
h2::after {
  content: "";
  display: block;
  height: 0;
}

.font-size--3::before,
h2::before {
  margin-top: -0.00827rem;
}

.font-size--3::after,
h2::after {
  margin-bottom: -0.01327rem;
}

@media (min-width: 40rem) {
  .font-size--3,
  h2 {
    font-weight: 500;
    font-size: 6.35714rem;
    line-height: 0.8573;
    padding: 0.00787rem 0;
    margin-top: 0.8573em;
  }
  .font-size--3::before, .font-size--3::after,
  h2::before,
  h2::after {
    content: "";
    display: block;
    height: 0;
  }
  .font-size--3::before,
  h2::before {
    margin-top: -0.00537rem;
  }
  .font-size--3::after,
  h2::after {
    margin-bottom: -0.01037rem;
  }
}

.font-size--2,
h1 {
  font-family: "Jost", sans-serif, serif;
  word-wrap: break-word;
  font-weight: 500;
  font-size: 5.71429rem;
  line-height: 0.875;
  padding: 0.00875rem 0;
  margin-top: 0.875em;
}

.font-size--2:first-child,
h1:first-child {
  margin-top: 0;
}

.font-size--2::before, .font-size--2::after,
h1::before,
h1::after {
  content: "";
  display: block;
  height: 0;
}

.font-size--2::before,
h1::before {
  margin-top: -0.00625rem;
}

.font-size--2::after,
h1::after {
  margin-bottom: -0.01125rem;
}

@media (min-width: 40rem) {
  .font-size--2,
  h1 {
    font-weight: 500;
    font-size: 8rem;
    line-height: 0.825;
    padding: 0.00625rem 0;
    margin-top: 0.825em;
  }
  .font-size--2::before, .font-size--2::after,
  h1::before,
  h1::after {
    content: "";
    display: block;
    height: 0;
  }
  .font-size--2::before,
  h1::before {
    margin-top: -0.00375rem;
  }
  .font-size--2::after,
  h1::after {
    margin-bottom: -0.00875rem;
  }
}

.font-size--1,
h0 {
  font-family: "Jost", sans-serif, serif;
  word-wrap: break-word;
  font-weight: 500;
  font-size: 6.78571rem;
  line-height: 0.84737;
  padding: 0.00737rem 0;
  margin-top: 0.84737em;
}

.font-size--1:first-child,
h0:first-child {
  margin-top: 0;
}

.font-size--1::before, .font-size--1::after,
h0::before,
h0::after {
  content: "";
  display: block;
  height: 0;
}

.font-size--1::before,
h0::before {
  margin-top: -0.00487rem;
}

.font-size--1::after,
h0::after {
  margin-bottom: -0.00987rem;
}

@media (min-width: 40rem) {
  .font-size--1,
  h0 {
    font-weight: 500;
    font-size: 9.64286rem;
    line-height: 0.8037;
    padding: 0.00519rem 0;
    margin-top: 0.8037em;
  }
  .font-size--1::before, .font-size--1::after,
  h0::before,
  h0::after {
    content: "";
    display: block;
    height: 0;
  }
  .font-size--1::before,
  h0::before {
    margin-top: -0.00269rem;
  }
  .font-size--1::after,
  h0::after {
    margin-bottom: -0.00769rem;
  }
}

.btn {
  font-family: "Jost", sans-serif, serif;
  font-weight: 500;
  font-size: 1.64286rem;
  line-height: 1.3087;
  padding: 0.03043rem 0;
  margin-top: 1.3087em;
  padding: .7em 1.1em;
}

.btn::before, .btn::after {
  content: "";
  display: block;
  height: 0;
}

.btn::before {
  margin-top: -0.02793rem;
}

.btn::after {
  margin-bottom: -0.03293rem;
}

p, ul, ol, dl, .font-size--0 {
  font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-weight: 300;
  font-size: 1.40659rem;
  line-height: 1.77734;
  padding: 0.03555rem 0;
  margin-top: 1.77734em;
  letter-spacing: 0.03em;
}

p::before, p::after, ul::before, ul::after, ol::before, ol::after, dl::before, dl::after, .font-size--0::before, .font-size--0::after {
  content: "";
  display: block;
  height: 0;
}

p::before, ul::before, ol::before, dl::before, .font-size--0::before {
  margin-top: -0.02187rem;
}

p::after, ul::after, ol::after, dl::after, .font-size--0::after {
  margin-bottom: -0.04922rem;
}

p > i {
  font-style: italic;
}

:root {
  --btn-font-size: var(--text-md);
  --btn-padding-x: 1.3em;
  --btn-padding-y: 0.8em;
  --btn-radius: 10em;
}

.btn {
  cursor: pointer;
  font-family: "Jost", sans-serif, serif;
  font-weight: 400;
  text-decoration: none;
  line-height: 1;
  text-shadow: 0px -1px 0px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.25);
  box-shadow: var(--shadow-xs);
  transition: all 0.2s ease;
  will-change: transform;
  margin-top: 1em;
  letter-spacing: 0.01em;
}

.btn:hover {
  box-shadow: var(--shadow-sm);
}

.btn:focus {
  box-shadow: 0px 0px 0px 2px hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.15);
  outline: none;
}

.btn:active {
  transform: translateY(2px);
}

.btn--primary {
  background: var(--color-primary);
  color: var(--color-white);
}

.btn--primary:hover {
  background: var(--color-primary-dark);
}

.btn--primary:focus {
  box-shadow: 0px 0px 0px 2px hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.2);
}

.btn--subtle {
  background: hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), 0.1);
  color: var(--color-contrast-higher);
}

.btn--accent {
  background: var(--color-accent);
}

.btn--accent:hover {
  background: var(--color-accent-dark);
}

.btn--accent:focus {
  box-shadow: 0px 0px 0px 2px hsla(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l), 0.2);
}

.btn--disabled, .btn[disabled], .btn[readonly] {
  opacity: 0.6;
  cursor: not-allowed;
}

.btn--sm {
  font-size: 0.8em;
}

.btn--md {
  font-size: 1.2em;
}

.btn--lg {
  font-size: 1.4em;
}

.btn--gradient {
  background: var(--color-primary);
  background-image: var(--gradient-primary-accent);
  color: var(--color-white);
}

.btn--animated-gradient {
  background-color: var(--color-primary);
  background-image: linear-gradient(to right top, var(--color-primary), var(--color-accent), var(--color-success));
  background-size: 300% 300%;
  animation: animated-gradient 10s ease infinite;
  color: var(--color-white);
  position: relative;
  overflow: hidden;
}

.btn--animated-gradient:before {
  top: 0;
  left: -3%;
  content: '';
  position: absolute;
  width: 106%;
  height: 100%;
  background-color: hsla(var(--color-success-h), var(--color-success-s), var(--color-success-l), 0);
  transform: translateX(-100%) skew(30deg, 0deg);
  transition: all 0.5s ease;
  z-index: -1;
}

.btn--animated-gradient:hover:before {
  transform: translateX(0) skew(30deg, 0deg);
  background-color: hsla(var(--color-success-h), var(--color-success-s), var(--color-success-l), 0.7);
}

:root {
  --form-control-font-size: var(--text-sm);
  --form-control-padding-x: var(--space-xs);
  --form-control-padding-y: var(--space-xs);
  --form-control-radius: 0.3em;
}

.form-control {
  background: hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), 0.4);
  border: none;
  line-height: 1.2;
  transition: all 0.2s ease;
  color: var(--color-contrast-lower);
}

.form-control::placeholder {
  opacity: 1;
  color: var(--color-contrast-low);
}

.form-control:focus {
  background: hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), 0.6);
  box-shadow: 0px 0px 5px 1px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.3);
  outline: none;
}

.form-control--disabled, .form-control[disabled], .form-control[readonly] {
  cursor: not-allowed;
}

.form-control[aria-invalid="true"], .form-control--error {
  border-color: var(--color-error);
}

.form-control[aria-invalid="true"]:focus, .form-control--error:focus {
  box-shadow: 0px 0px 0px 1px hsla(var(--color-error-h), var(--color-error-s), var(--color-error-l), 0.2);
}

.form-legend {
  font-size: var(--text-md);
}

.form-label {
  font-size: var(--text-base-size);
}

.logo {
  font-size: 14px;
  position: relative;
  text-align: center;
  display: inline-block;
  width: 10em;
  height: 3.6em;
}

.logo:before {
  display: inline-block;
  vertical-align: middle;
  content: '';
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.logo--tcos:before {
  background-image: url("/assets/images/TCOS_Logo_white.png");
  background-image: url("/assets/images/TCOS_Logo_white.svg"), linear-gradient(transparent, transparent);
}

.logo--tcos-txt:before {
  background-image: url("/assets/images/TCOS_Logo_words-white.png");
  background-image: url("/assets/images/TCOS_Logo_words-white.svg"), linear-gradient(transparent, transparent);
}

.blob-shape {
  animation-name: blob-turn;
  animation-duration: unset;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  fill: #313135;
  position: relative;
  transform-origin: center;
}

.blob-shape svg {
  animation-name: blob-skew;
  animation-duration: unset;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  transform-origin: center;
}

.blob-shape svg path {
  animation-name: blob-scale;
  animation-duration: unset;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  transform-origin: center;
}

.blob__container {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
}

.blob__container .icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 25%;
  z-index: 1;
}

.blob__container .blob-shape {
  position: absolute;
  width: 80%;
  height: 80%;
  opacity: 0.2;
  transition: opacity 0.5s linear;
}

.dark-theme .blob__container .blob-shape {
  opacity: 0.8;
}

.blob__container .blob-shape:nth-child(odd) {
  top: 0;
  left: 0;
}

.blob__container .blob-shape:nth-child(even) {
  bottom: 0;
  right: 0;
}

@keyframes blob-turn {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes blob-skew {
  0% {
    transform: skewY(0deg);
  }
  13% {
    transform: skewY(calc( (1.8deg) * 1.5));
  }
  18% {
    transform: skewY(calc( (2.2deg) * 1.5));
  }
  24% {
    transform: skewY(calc( (2.48deg) * 1.5));
  }
  25% {
    transform: skewY(calc( (2.5deg) * 1.5));
  }
  26% {
    transform: skewY(calc( (2.48deg) * 1.5));
  }
  32% {
    transform: skewY(calc( (2.2deg) * 1.5));
  }
  37% {
    transform: skewY(calc( (1.8deg) * 1.5));
  }
  50% {
    transform: skewY(0deg);
  }
  63% {
    transform: skewY(calc( (-1.8deg) * 1.5));
  }
  68% {
    transform: skewY(calc( (-2.2deg) * 1.5));
  }
  74% {
    transform: skewY(calc( (-2.48deg) * 1.5));
  }
  75% {
    transform: skewY(calc( (-2.5deg) * 1.5));
  }
  76% {
    transform: skewY(calc( (-2.48deg) * 1.5));
  }
  82% {
    transform: skewY(calc( (-2.2deg) * 1.5));
  }
  87% {
    transform: skewY(calc( (-1.8deg) * 1.5));
  }
  100% {
    transform: skewY(0deg);
  }
}

@keyframes blob-scale {
  0% {
    transform: scaleX(0.9) scaleY(1);
  }
  25% {
    transform: scaleX(0.9) scaleY(0.9);
  }
  50% {
    transform: scaleX(1) scaleY(0.9);
  }
  75% {
    transform: scaleX(0.9) scaleY(0.9);
  }
  100% {
    transform: scaleX(0.9) scaleY(1);
  }
}

.site-header__menu-icon {
  width: 100%;
  padding: 0 0 2em;
  display: block;
  position: relative;
  font-size: 1rem;
  cursor: pointer;
  transition: 0.2s all;
  -webkit-tap-highlight-color: transparent;
}

.site-header__menu-icon:before,
.site-header__menu-icon span, .site-header__menu-icon:after {
  pointer-events: none;
  display: block;
  content: '';
  width: 100%;
  height: 12.5%;
  border-radius: 0.5em;
  background-color: white;
  position: absolute;
  transform-origin: 50%;
  transform: rotate(0deg) translate(0, 0);
  transition: 0.2s bottom, 0.2s top, 0.2s transform, 0.4s background-color 0.1s;
  min-height: 3px;
}

.site-header #site-header__toggle:checked ~ .site-header__menu-icon:before, .site-header #site-header__toggle:checked ~
.site-header__menu-icon span, .site-header #site-header__toggle:checked ~ .site-header__menu-icon:after {
  transition: 0.2s bottom 0.1s, 0.2s top 0.1s, 0.2s transform 0.1s, 0.4s background-color;
}

.site-header__menu-icon:before {
  top: 0;
}

.site-header #site-header__toggle:checked ~ .site-header__menu-icon:before {
  top: 50%;
  transform: translate(0, -50%) rotate(45deg);
}

.site-header__menu-icon span {
  top: 50%;
  transform: rotate(0deg) translate(0, -50%);
}

.site-header #site-header__toggle:checked ~ .site-header__menu-icon span {
  background-color: transparent;
  transform: rotate(0deg) translate(0, 0);
}

.site-header__menu-icon:after {
  bottom: 0;
}

.site-header #site-header__toggle:checked ~ .site-header__menu-icon:after {
  bottom: 50%;
  transform: translate(0, 50%) rotate(-45deg);
}

/* --------------------------------

File#: _1_details-list
Title: Details List
Descr: Pairs of related information displayed in a list
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --details-list-border-width: 1px;
  --details-list-border-opacity: 0.15;
}

.details-list {
  width: 100%;
}

.details-list--rows .details-list__item {
  border-bottom: var(--details-list-border-width) solid hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), var(--details-list-border-opacity));
}

.details-list--rows .details-list__item:last-child {
  border-bottom: none;
}

.details-list--cols .details-list__item {
  border-right: var(--details-list-border-width) solid hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), var(--details-list-border-opacity));
}

.details-list--cols .details-list__item:last-child {
  border-right: none;
}

@media (min-width: 40rem) {
  .details-list--rows\@xs .details-list__item {
    border-right: 0;
    border-bottom: var(--details-list-border-width) solid hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), var(--details-list-border-opacity));
  }
  .details-list--rows\@xs .details-list__item:last-child {
    border-bottom: none;
  }
  .details-list--cols\@xs .details-list__item {
    border-bottom: 0;
    border-right: var(--details-list-border-width) solid hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), var(--details-list-border-opacity));
  }
  .details-list--cols\@xs .details-list__item:last-child {
    border-right: none;
  }
}

@media (min-width: 48rem) {
  .details-list--rows\@sm .details-list__item {
    border-right: 0;
    border-bottom: var(--details-list-border-width) solid hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), var(--details-list-border-opacity));
  }
  .details-list--rows\@sm .details-list__item:last-child {
    border-bottom: none;
  }
  .details-list--cols\@sm .details-list__item {
    border-bottom: 0;
    border-right: var(--details-list-border-width) solid hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), var(--details-list-border-opacity));
  }
  .details-list--cols\@sm .details-list__item:last-child {
    border-right: none;
  }
}

@media (min-width: 64rem) {
  .details-list--rows\@md .details-list__item {
    border-right: 0;
    border-bottom: var(--details-list-border-width) solid hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), var(--details-list-border-opacity));
  }
  .details-list--rows\@md .details-list__item:last-child {
    border-bottom: none;
  }
  .details-list--cols\@md .details-list__item {
    border-bottom: 0;
    border-right: var(--details-list-border-width) solid hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), var(--details-list-border-opacity));
  }
  .details-list--cols\@md .details-list__item:last-child {
    border-right: none;
  }
}

@media (min-width: 80rem) {
  .details-list--rows\@lg .details-list__item {
    border-right: 0;
    border-bottom: var(--details-list-border-width) solid hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), var(--details-list-border-opacity));
  }
  .details-list--rows\@lg .details-list__item:last-child {
    border-bottom: none;
  }
  .details-list--cols\@lg .details-list__item {
    border-bottom: 0;
    border-right: var(--details-list-border-width) solid hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), var(--details-list-border-opacity));
  }
  .details-list--cols\@lg .details-list__item:last-child {
    border-right: none;
  }
}

@media (min-width: 90rem) {
  .details-list--rows\@xl .details-list__item {
    border-right: 0;
    border-bottom: var(--details-list-border-width) solid hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), var(--details-list-border-opacity));
  }
  .details-list--rows\@xl .details-list__item:last-child {
    border-bottom: none;
  }
  .details-list--cols\@xl .details-list__item {
    border-bottom: 0;
    border-right: var(--details-list-border-width) solid hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), var(--details-list-border-opacity));
  }
  .details-list--cols\@xl .details-list__item:last-child {
    border-right: none;
  }
}

.feature-list {
  flex-wrap: wrap;
}

.feature-list__item {
  flex: 0 0 100%;
  padding: 3em 0;
  flex-direction: column;
  text-align: center;
}

.feature-list__icon, .feature-list figure > img {
  display: block;
  width: 140px;
  padding: 0 1.25em 1.25em;
  height: 100%;
  margin: 0 auto;
}

.feature-list__icon {
  height: 100%;
}

.feature-list__text {
  max-width: 300px;
  margin: 0 auto;
}

.feature-list figure {
  flex-shrink: 0;
}

@media (min-width: 40rem) {
  .feature-list__item {
    flex: 0 0 33.33%;
    padding: 2.75% 3.5%;
  }
}

@media (min-width: 48rem) {
  .feature-list__item {
    flex: 0 0 33.33%;
  }
}

.footer {
  height: 5.5555vh;
  padding: 0 13.3333rem;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.footer nav {
  flex: 1;
}

.footer nav ul {
  display: flex;
  margin: 0;
  list-style: none;
}

.footer nav ul li {
  flex: 1;
  margin: 0;
  padding: 0 1rem;
  text-align: right;
}

.footer {
  bottom: 0;
  box-shadow: 0 -4px 40px 0px rgba(22, 34, 69, 0.19);
}

.grid {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 1;
  top: 0;
  min-height: 100vh;
  z-index: 100;
}

.gridline {
  stroke-width: 1;
  stroke: rgba(0, 180, 255, 0.2);
}

.canon {
  /* display: none; */
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.canon.c2 {
  width: 100vh;
  transform: rotate(-90deg);
}

.canon rect {
  fill: rgba(0, 0, 0, 0);
  stroke: #969696;
  stroke-width: 1;
  width: 500;
  height: 500;
}

.canon line,
line.line--col-red {
  width: 100%;
  stroke: #ff0050;
  stroke-width: 0.2;
}

line.line--col-blue {
  stroke: #00b4ff;
}

line.line--col-yellow {
  stroke: #ffb400;
}

line.line--col-teal {
  stroke: rgba(0, 180, 255, 0.9);
}

.site-header {
  padding: 0;
  position: fixed;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 100;
}

.site-header__menu, .site-header__mask {
  position: fixed;
  background-color: var(--color-black);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  transform: translate(-100%, 0);
  opacity: 0;
  will-change: transform, opacity;
  transition: 0.4s transform ease-in-out, opacity 0.4s ease-out 0.1s;
}

.site-header__menu:after, .site-header__mask:after {
  content: "";
  width: 10em;
  height: 100%;
  left: 100%;
  top: 0;
  display: block;
  position: absolute;
  background: -moz-linear-gradient(left, #041020 0%, rgba(4, 16, 32, 0) 100%);
  background: -webkit-linear-gradient(left, #041020 0%, rgba(4, 16, 32, 0) 100%);
  background: linear-gradient(to right, #041020 0%, rgba(4, 16, 32, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#041020', endColorstr='#00041020',GradientType=1 );
}

.site-header #site-header__toggle:checked ~ .site-header__menu, .site-header #site-header__toggle:checked ~ .site-header__mask {
  transition: 0.5s transform cubic-bezier(0, 0, 0.3, 1), opacity 0.6s cubic-bezier(0, 0.65, 0, 0.65);
  transform: translate(0, 0);
  opacity: 0.8;
}

.site-header__mask {
  z-index: -1;
}

.site-header__menu .chunk {
  margin: 0;
}

.site-header__menu li {
  font-family: Jost,sans-serif,serif;
  word-wrap: break-word;
  font-weight: 500;
  font-size: 2.5em;
}

.site-header__menu li .chunk {
  margin: 0;
  position: absolute;
}

.site-header__menu li a {
  text-decoration: none;
  color: #FFF;
}

.site-header__logo, .site-header__menu-icon, .site-header__toggle {
  z-index: 1;
  position: relative;
}

.site-header__logo {
  display: flex;
  flex-direction: column;
}

.site-header .logo {
  position: relative;
  width: 220%;
  height: unset;
  padding-bottom: 500%;
  transform: translateX(-28%);
  margin-top: 60%;
}

.site-header .logo:before {
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  left: -50%;
  top: 0;
  position: absolute;
  width: 200%;
}

.site-header .logo.logo--tcos-txt {
  width: 248%;
  margin-top: 50%;
}

/* -------------------------------- 

File#: _1_testimonial
Title: Testimonial
Descr: A testimonial quote 
Usage: codyhouse.co/license

-------------------------------- */
.testimonial__block-wrapper {
  position: relative;
}

.testimonial__block-wrapper blockquote {
  position: relative;
  z-index: 1;
}

.testimonial__block-wrapper .icon {
  position: absolute;
  top: -0.5em;
  left: -0.5em;
}

.typewrite {
  margin-top: 1em;
}

.typewrite {
  border-right: 0.04em solid #FFF;
  display: block;
  margin-top: 0.09em;
  vertical-align: bottom;
  margin-right: -0.07em;
  white-space: nowrap;
  position: relative;
}

.typewrite:after {
  content: '';
  height: 0.8em;
  width: 0.04em;
  display: inline-block;
  border-right: 0.04em solid #FFF;
  position: absolute;
  top: 0em;
  right: -0.07em;
  animation: blinker 0.8s linear infinite;
}

.typewrite .wrap {
  position: absolute;
  padding-right: 0.04em;
  right: 0;
  top: 0;
}

@keyframes blinker {
  0% {
    opacity: 0.6;
  }
  40% {
    opacity: 0.6;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

:root {
  --section-divider-width: 1920;
  --section-divider-height: 60;
  --section-divider-ratio: calc(100% * var(--section-divider-height) / var(--section-divider-width));
}

.intro {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.intro .btn {
  margin: 0 auto;
  display: inline-block;
  box-shadow: 0 3px 10px 2px #035;
}

.intro--overlay:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-bg);
  opacity: 0.5;
  z-index: 0;
  box-shadow: #07F 0px -40px 80px -60px inset;
}

.intro__text {
  text-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
}

.intro__text p {
  font-size: 1.2em;
}

.intro > .container {
  padding: 12% 1em;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
}

.intro > .container h1 {
  margin-bottom: 0.25em;
  font-size: 2.75em;
  margin-bottom: -0.1em;
}

[class*="has-section-divider"] {
  position: relative;
}

.has-section-dividers {
  padding-top: var(--section-divider-ratio);
  padding-bottom: var(--section-divider-ratio);
}

.has-section-dividers .section-divider + .section-divider {
  bottom: auto;
  top: -1px;
  transform: scaleY(-1);
}

.has-section-divider-top {
  padding-top: var(--section-divider-ratio);
}

.has-section-divider-top .section-divider {
  bottom: auto;
  top: -1px;
  transform: scaleY(-1);
}

.curved-bottom {
  border-radius: 0% 0% 50% 50% / 0% 0% 10% 10%;
}

.section-divider {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  overflow: hidden;
  fill: #FFF;
  transition: fill .5s ease;
}

.dark-theme .section-divider {
  fill: var(--color-bg);
}

.section-divider svg {
  position: relative;
  display: block;
  height: auto;
  max-width: none;
  width: 102%;
  left: -1%;
}

.body-inner {
  height: 100%;
  overflow: auto;
  position: relative;
  overflow-x: hidden;
}

.svg-filter {
  position: fixed;
  width: 0;
  height: 0;
}

.max-w26rem {
  max-width: 26rem;
}

.max-w30rem {
  max-width: 30rem;
}

.max-w0rem {
  max-width: 0rem;
}

.chunk.chunk--w0 {
  flex: 0 0 0%;
}

.chunk.chunk--h0vh {
  min-height: 0vh;
}

.max-w1rem {
  max-width: 1rem;
}

.chunk.chunk--w1 {
  flex: 0 0 1%;
}

.chunk.chunk--h1vh {
  min-height: 1vh;
}

.max-w2rem {
  max-width: 2rem;
}

.chunk.chunk--w2 {
  flex: 0 0 2%;
}

.chunk.chunk--h2vh {
  min-height: 2vh;
}

.max-w3rem {
  max-width: 3rem;
}

.chunk.chunk--w3 {
  flex: 0 0 3%;
}

.chunk.chunk--h3vh {
  min-height: 3vh;
}

.max-w4rem {
  max-width: 4rem;
}

.chunk.chunk--w4 {
  flex: 0 0 4%;
}

.chunk.chunk--h4vh {
  min-height: 4vh;
}

.max-w5rem {
  max-width: 5rem;
}

.chunk.chunk--w5 {
  flex: 0 0 5%;
}

.chunk.chunk--h5vh {
  min-height: 5vh;
}

.max-w6rem {
  max-width: 6rem;
}

.chunk.chunk--w6 {
  flex: 0 0 6%;
}

.chunk.chunk--h6vh {
  min-height: 6vh;
}

.max-w7rem {
  max-width: 7rem;
}

.chunk.chunk--w7 {
  flex: 0 0 7%;
}

.chunk.chunk--h7vh {
  min-height: 7vh;
}

.max-w8rem {
  max-width: 8rem;
}

.chunk.chunk--w8 {
  flex: 0 0 8%;
}

.chunk.chunk--h8vh {
  min-height: 8vh;
}

.max-w9rem {
  max-width: 9rem;
}

.chunk.chunk--w9 {
  flex: 0 0 9%;
}

.chunk.chunk--h9vh {
  min-height: 9vh;
}

.max-w10rem {
  max-width: 10rem;
}

.chunk.chunk--w10 {
  flex: 0 0 10%;
}

.chunk.chunk--h10vh {
  min-height: 10vh;
}

.max-w11rem {
  max-width: 11rem;
}

.chunk.chunk--w11 {
  flex: 0 0 11%;
}

.chunk.chunk--h11vh {
  min-height: 11vh;
}

.max-w12rem {
  max-width: 12rem;
}

.chunk.chunk--w12 {
  flex: 0 0 12%;
}

.chunk.chunk--h12vh {
  min-height: 12vh;
}

.max-w13rem {
  max-width: 13rem;
}

.chunk.chunk--w13 {
  flex: 0 0 13%;
}

.chunk.chunk--h13vh {
  min-height: 13vh;
}

.max-w14rem {
  max-width: 14rem;
}

.chunk.chunk--w14 {
  flex: 0 0 14%;
}

.chunk.chunk--h14vh {
  min-height: 14vh;
}

.max-w15rem {
  max-width: 15rem;
}

.chunk.chunk--w15 {
  flex: 0 0 15%;
}

.chunk.chunk--h15vh {
  min-height: 15vh;
}

.max-w16rem {
  max-width: 16rem;
}

.chunk.chunk--w16 {
  flex: 0 0 16%;
}

.chunk.chunk--h16vh {
  min-height: 16vh;
}

.max-w17rem {
  max-width: 17rem;
}

.chunk.chunk--w17 {
  flex: 0 0 17%;
}

.chunk.chunk--h17vh {
  min-height: 17vh;
}

.max-w18rem {
  max-width: 18rem;
}

.chunk.chunk--w18 {
  flex: 0 0 18%;
}

.chunk.chunk--h18vh {
  min-height: 18vh;
}

.max-w19rem {
  max-width: 19rem;
}

.chunk.chunk--w19 {
  flex: 0 0 19%;
}

.chunk.chunk--h19vh {
  min-height: 19vh;
}

.max-w20rem {
  max-width: 20rem;
}

.chunk.chunk--w20 {
  flex: 0 0 20%;
}

.chunk.chunk--h20vh {
  min-height: 20vh;
}

.max-w21rem {
  max-width: 21rem;
}

.chunk.chunk--w21 {
  flex: 0 0 21%;
}

.chunk.chunk--h21vh {
  min-height: 21vh;
}

.max-w22rem {
  max-width: 22rem;
}

.chunk.chunk--w22 {
  flex: 0 0 22%;
}

.chunk.chunk--h22vh {
  min-height: 22vh;
}

.max-w23rem {
  max-width: 23rem;
}

.chunk.chunk--w23 {
  flex: 0 0 23%;
}

.chunk.chunk--h23vh {
  min-height: 23vh;
}

.max-w24rem {
  max-width: 24rem;
}

.chunk.chunk--w24 {
  flex: 0 0 24%;
}

.chunk.chunk--h24vh {
  min-height: 24vh;
}

.max-w25rem {
  max-width: 25rem;
}

.chunk.chunk--w25 {
  flex: 0 0 25%;
}

.chunk.chunk--h25vh {
  min-height: 25vh;
}

.max-w26rem {
  max-width: 26rem;
}

.chunk.chunk--w26 {
  flex: 0 0 26%;
}

.chunk.chunk--h26vh {
  min-height: 26vh;
}

.max-w27rem {
  max-width: 27rem;
}

.chunk.chunk--w27 {
  flex: 0 0 27%;
}

.chunk.chunk--h27vh {
  min-height: 27vh;
}

.max-w28rem {
  max-width: 28rem;
}

.chunk.chunk--w28 {
  flex: 0 0 28%;
}

.chunk.chunk--h28vh {
  min-height: 28vh;
}

.max-w29rem {
  max-width: 29rem;
}

.chunk.chunk--w29 {
  flex: 0 0 29%;
}

.chunk.chunk--h29vh {
  min-height: 29vh;
}

.max-w30rem {
  max-width: 30rem;
}

.chunk.chunk--w30 {
  flex: 0 0 30%;
}

.chunk.chunk--h30vh {
  min-height: 30vh;
}

.max-w31rem {
  max-width: 31rem;
}

.chunk.chunk--w31 {
  flex: 0 0 31%;
}

.chunk.chunk--h31vh {
  min-height: 31vh;
}

.max-w32rem {
  max-width: 32rem;
}

.chunk.chunk--w32 {
  flex: 0 0 32%;
}

.chunk.chunk--h32vh {
  min-height: 32vh;
}

.max-w33rem {
  max-width: 33rem;
}

.chunk.chunk--w33 {
  flex: 0 0 33%;
}

.chunk.chunk--h33vh {
  min-height: 33vh;
}

.max-w34rem {
  max-width: 34rem;
}

.chunk.chunk--w34 {
  flex: 0 0 34%;
}

.chunk.chunk--h34vh {
  min-height: 34vh;
}

.max-w35rem {
  max-width: 35rem;
}

.chunk.chunk--w35 {
  flex: 0 0 35%;
}

.chunk.chunk--h35vh {
  min-height: 35vh;
}

.max-w36rem {
  max-width: 36rem;
}

.chunk.chunk--w36 {
  flex: 0 0 36%;
}

.chunk.chunk--h36vh {
  min-height: 36vh;
}

.max-w37rem {
  max-width: 37rem;
}

.chunk.chunk--w37 {
  flex: 0 0 37%;
}

.chunk.chunk--h37vh {
  min-height: 37vh;
}

.max-w38rem {
  max-width: 38rem;
}

.chunk.chunk--w38 {
  flex: 0 0 38%;
}

.chunk.chunk--h38vh {
  min-height: 38vh;
}

.max-w39rem {
  max-width: 39rem;
}

.chunk.chunk--w39 {
  flex: 0 0 39%;
}

.chunk.chunk--h39vh {
  min-height: 39vh;
}

.max-w40rem {
  max-width: 40rem;
}

.chunk.chunk--w40 {
  flex: 0 0 40%;
}

.chunk.chunk--h40vh {
  min-height: 40vh;
}

.max-w41rem {
  max-width: 41rem;
}

.chunk.chunk--w41 {
  flex: 0 0 41%;
}

.chunk.chunk--h41vh {
  min-height: 41vh;
}

.max-w42rem {
  max-width: 42rem;
}

.chunk.chunk--w42 {
  flex: 0 0 42%;
}

.chunk.chunk--h42vh {
  min-height: 42vh;
}

.max-w43rem {
  max-width: 43rem;
}

.chunk.chunk--w43 {
  flex: 0 0 43%;
}

.chunk.chunk--h43vh {
  min-height: 43vh;
}

.max-w44rem {
  max-width: 44rem;
}

.chunk.chunk--w44 {
  flex: 0 0 44%;
}

.chunk.chunk--h44vh {
  min-height: 44vh;
}

.max-w45rem {
  max-width: 45rem;
}

.chunk.chunk--w45 {
  flex: 0 0 45%;
}

.chunk.chunk--h45vh {
  min-height: 45vh;
}

.max-w46rem {
  max-width: 46rem;
}

.chunk.chunk--w46 {
  flex: 0 0 46%;
}

.chunk.chunk--h46vh {
  min-height: 46vh;
}

.max-w47rem {
  max-width: 47rem;
}

.chunk.chunk--w47 {
  flex: 0 0 47%;
}

.chunk.chunk--h47vh {
  min-height: 47vh;
}

.max-w48rem {
  max-width: 48rem;
}

.chunk.chunk--w48 {
  flex: 0 0 48%;
}

.chunk.chunk--h48vh {
  min-height: 48vh;
}

.max-w49rem {
  max-width: 49rem;
}

.chunk.chunk--w49 {
  flex: 0 0 49%;
}

.chunk.chunk--h49vh {
  min-height: 49vh;
}

.max-w50rem {
  max-width: 50rem;
}

.chunk.chunk--w50 {
  flex: 0 0 50%;
}

.chunk.chunk--h50vh {
  min-height: 50vh;
}

.max-w51rem {
  max-width: 51rem;
}

.chunk.chunk--w51 {
  flex: 0 0 51%;
}

.chunk.chunk--h51vh {
  min-height: 51vh;
}

.max-w52rem {
  max-width: 52rem;
}

.chunk.chunk--w52 {
  flex: 0 0 52%;
}

.chunk.chunk--h52vh {
  min-height: 52vh;
}

.max-w53rem {
  max-width: 53rem;
}

.chunk.chunk--w53 {
  flex: 0 0 53%;
}

.chunk.chunk--h53vh {
  min-height: 53vh;
}

.max-w54rem {
  max-width: 54rem;
}

.chunk.chunk--w54 {
  flex: 0 0 54%;
}

.chunk.chunk--h54vh {
  min-height: 54vh;
}

.max-w55rem {
  max-width: 55rem;
}

.chunk.chunk--w55 {
  flex: 0 0 55%;
}

.chunk.chunk--h55vh {
  min-height: 55vh;
}

.max-w56rem {
  max-width: 56rem;
}

.chunk.chunk--w56 {
  flex: 0 0 56%;
}

.chunk.chunk--h56vh {
  min-height: 56vh;
}

.max-w57rem {
  max-width: 57rem;
}

.chunk.chunk--w57 {
  flex: 0 0 57%;
}

.chunk.chunk--h57vh {
  min-height: 57vh;
}

.max-w58rem {
  max-width: 58rem;
}

.chunk.chunk--w58 {
  flex: 0 0 58%;
}

.chunk.chunk--h58vh {
  min-height: 58vh;
}

.max-w59rem {
  max-width: 59rem;
}

.chunk.chunk--w59 {
  flex: 0 0 59%;
}

.chunk.chunk--h59vh {
  min-height: 59vh;
}

.max-w60rem {
  max-width: 60rem;
}

.chunk.chunk--w60 {
  flex: 0 0 60%;
}

.chunk.chunk--h60vh {
  min-height: 60vh;
}

.max-w61rem {
  max-width: 61rem;
}

.chunk.chunk--w61 {
  flex: 0 0 61%;
}

.chunk.chunk--h61vh {
  min-height: 61vh;
}

.max-w62rem {
  max-width: 62rem;
}

.chunk.chunk--w62 {
  flex: 0 0 62%;
}

.chunk.chunk--h62vh {
  min-height: 62vh;
}

.max-w63rem {
  max-width: 63rem;
}

.chunk.chunk--w63 {
  flex: 0 0 63%;
}

.chunk.chunk--h63vh {
  min-height: 63vh;
}

.max-w64rem {
  max-width: 64rem;
}

.chunk.chunk--w64 {
  flex: 0 0 64%;
}

.chunk.chunk--h64vh {
  min-height: 64vh;
}

.max-w65rem {
  max-width: 65rem;
}

.chunk.chunk--w65 {
  flex: 0 0 65%;
}

.chunk.chunk--h65vh {
  min-height: 65vh;
}

.max-w66rem {
  max-width: 66rem;
}

.chunk.chunk--w66 {
  flex: 0 0 66%;
}

.chunk.chunk--h66vh {
  min-height: 66vh;
}

.max-w67rem {
  max-width: 67rem;
}

.chunk.chunk--w67 {
  flex: 0 0 67%;
}

.chunk.chunk--h67vh {
  min-height: 67vh;
}

.max-w68rem {
  max-width: 68rem;
}

.chunk.chunk--w68 {
  flex: 0 0 68%;
}

.chunk.chunk--h68vh {
  min-height: 68vh;
}

.max-w69rem {
  max-width: 69rem;
}

.chunk.chunk--w69 {
  flex: 0 0 69%;
}

.chunk.chunk--h69vh {
  min-height: 69vh;
}

.max-w70rem {
  max-width: 70rem;
}

.chunk.chunk--w70 {
  flex: 0 0 70%;
}

.chunk.chunk--h70vh {
  min-height: 70vh;
}

.max-w71rem {
  max-width: 71rem;
}

.chunk.chunk--w71 {
  flex: 0 0 71%;
}

.chunk.chunk--h71vh {
  min-height: 71vh;
}

.max-w72rem {
  max-width: 72rem;
}

.chunk.chunk--w72 {
  flex: 0 0 72%;
}

.chunk.chunk--h72vh {
  min-height: 72vh;
}

.max-w73rem {
  max-width: 73rem;
}

.chunk.chunk--w73 {
  flex: 0 0 73%;
}

.chunk.chunk--h73vh {
  min-height: 73vh;
}

.max-w74rem {
  max-width: 74rem;
}

.chunk.chunk--w74 {
  flex: 0 0 74%;
}

.chunk.chunk--h74vh {
  min-height: 74vh;
}

.max-w75rem {
  max-width: 75rem;
}

.chunk.chunk--w75 {
  flex: 0 0 75%;
}

.chunk.chunk--h75vh {
  min-height: 75vh;
}

.max-w76rem {
  max-width: 76rem;
}

.chunk.chunk--w76 {
  flex: 0 0 76%;
}

.chunk.chunk--h76vh {
  min-height: 76vh;
}

.max-w77rem {
  max-width: 77rem;
}

.chunk.chunk--w77 {
  flex: 0 0 77%;
}

.chunk.chunk--h77vh {
  min-height: 77vh;
}

.max-w78rem {
  max-width: 78rem;
}

.chunk.chunk--w78 {
  flex: 0 0 78%;
}

.chunk.chunk--h78vh {
  min-height: 78vh;
}

.max-w79rem {
  max-width: 79rem;
}

.chunk.chunk--w79 {
  flex: 0 0 79%;
}

.chunk.chunk--h79vh {
  min-height: 79vh;
}

.max-w80rem {
  max-width: 80rem;
}

.chunk.chunk--w80 {
  flex: 0 0 80%;
}

.chunk.chunk--h80vh {
  min-height: 80vh;
}

.max-w81rem {
  max-width: 81rem;
}

.chunk.chunk--w81 {
  flex: 0 0 81%;
}

.chunk.chunk--h81vh {
  min-height: 81vh;
}

.max-w82rem {
  max-width: 82rem;
}

.chunk.chunk--w82 {
  flex: 0 0 82%;
}

.chunk.chunk--h82vh {
  min-height: 82vh;
}

.max-w83rem {
  max-width: 83rem;
}

.chunk.chunk--w83 {
  flex: 0 0 83%;
}

.chunk.chunk--h83vh {
  min-height: 83vh;
}

.max-w84rem {
  max-width: 84rem;
}

.chunk.chunk--w84 {
  flex: 0 0 84%;
}

.chunk.chunk--h84vh {
  min-height: 84vh;
}

.max-w85rem {
  max-width: 85rem;
}

.chunk.chunk--w85 {
  flex: 0 0 85%;
}

.chunk.chunk--h85vh {
  min-height: 85vh;
}

.max-w86rem {
  max-width: 86rem;
}

.chunk.chunk--w86 {
  flex: 0 0 86%;
}

.chunk.chunk--h86vh {
  min-height: 86vh;
}

.max-w87rem {
  max-width: 87rem;
}

.chunk.chunk--w87 {
  flex: 0 0 87%;
}

.chunk.chunk--h87vh {
  min-height: 87vh;
}

.max-w88rem {
  max-width: 88rem;
}

.chunk.chunk--w88 {
  flex: 0 0 88%;
}

.chunk.chunk--h88vh {
  min-height: 88vh;
}

.max-w89rem {
  max-width: 89rem;
}

.chunk.chunk--w89 {
  flex: 0 0 89%;
}

.chunk.chunk--h89vh {
  min-height: 89vh;
}

.max-w90rem {
  max-width: 90rem;
}

.chunk.chunk--w90 {
  flex: 0 0 90%;
}

.chunk.chunk--h90vh {
  min-height: 90vh;
}

.max-w91rem {
  max-width: 91rem;
}

.chunk.chunk--w91 {
  flex: 0 0 91%;
}

.chunk.chunk--h91vh {
  min-height: 91vh;
}

.max-w92rem {
  max-width: 92rem;
}

.chunk.chunk--w92 {
  flex: 0 0 92%;
}

.chunk.chunk--h92vh {
  min-height: 92vh;
}

.max-w93rem {
  max-width: 93rem;
}

.chunk.chunk--w93 {
  flex: 0 0 93%;
}

.chunk.chunk--h93vh {
  min-height: 93vh;
}

.max-w94rem {
  max-width: 94rem;
}

.chunk.chunk--w94 {
  flex: 0 0 94%;
}

.chunk.chunk--h94vh {
  min-height: 94vh;
}

.max-w95rem {
  max-width: 95rem;
}

.chunk.chunk--w95 {
  flex: 0 0 95%;
}

.chunk.chunk--h95vh {
  min-height: 95vh;
}

.max-w96rem {
  max-width: 96rem;
}

.chunk.chunk--w96 {
  flex: 0 0 96%;
}

.chunk.chunk--h96vh {
  min-height: 96vh;
}

.max-w97rem {
  max-width: 97rem;
}

.chunk.chunk--w97 {
  flex: 0 0 97%;
}

.chunk.chunk--h97vh {
  min-height: 97vh;
}

.max-w98rem {
  max-width: 98rem;
}

.chunk.chunk--w98 {
  flex: 0 0 98%;
}

.chunk.chunk--h98vh {
  min-height: 98vh;
}

.max-w99rem {
  max-width: 99rem;
}

.chunk.chunk--w99 {
  flex: 0 0 99%;
}

.chunk.chunk--h99vh {
  min-height: 99vh;
}

.max-w100rem {
  max-width: 100rem;
}

.chunk.chunk--w100 {
  flex: 0 0 100%;
}

.chunk.chunk--h100vh {
  min-height: 100vh;
}

.site-header {
  width: 9rem;
  padding: 3.5rem;
}

@media (min-width: 40rem) {
  .site-header {
    width: 9rem;
    padding: 3.5rem;
  }
}

.chunky {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 0;
  position: relative;
}

.chunk {
  flex: 1;
  padding: 0;
  padding: 9.9999vh 3.5rem;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
}

.chunk--absolute {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.chunk--absolute svg {
  margin-top: 0.15rem;
}

.chunk.chunk--w100, .chunk:nth-child(odd) {
  padding-left: 9rem;
}

@media (min-width: 40rem) {
  .chunk.chunk--w100, .chunk:nth-child(odd) {
    padding-left: 9rem;
  }
}

.chunk.align-center {
  align-items: center;
}

.chunk.align-right {
  align-items: flex-end;
}

.chunk > * {
  position: relative;
}

.chunk__sticky-top {
  position: sticky;
  top: 0;
}

.chunk__sticky-middle {
  position: sticky;
  top: 50%;
}

.chunk--nopad {
  padding: 0;
}

.chunk--nopad [class$="sticky-top"] {
  top: 0;
}

.chunk--h100vh {
  min-height: 100vh;
}

.chunk__bg-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit;
}

.chunk__bg-image:last-child {
  z-index: 0;
}

.chunk--bg-image {
  background: inherit;
}

.chunk--bg-image-blur {
  overflow: hidden;
}

.chunk--bg-image-blur:before, .chunk--bg-image:before {
  content: '';
  background: inherit;
  background-size: cover;
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.4;
  z-index: 0;
  top: 0;
  left: 0;
}

.chunk--bg-image-blur:before {
  filter: url(#svg-filter__blur);
  height: 150%;
  width: 150%;
  top: -25%;
  left: -25%;
  opacity: 0.20;
}

.chunk .btn {
  margin-top: 1.5em;
}

/*# sourceMappingURL=style.css.map */
