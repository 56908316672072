.typewrite {
 margin-top: 1em;
}

.typewrite {
  border-right: 0.04em solid #FFF;
  display: block;
  margin-top: 0.09em;
  vertical-align: bottom;
  margin-right: -0.07em;
  white-space: nowrap;
  position: relative;
  &:after {
    content: '';
    height: 0.8em;
    width: 0.04em;
    display: inline-block;
    // margin-top: 0.9em;
    border-right: 0.04em solid #FFF;
    position: absolute;
    top: 0em;
    right: -0.07em;
    animation: blinker 0.8s linear infinite;
  }
  .wrap {
    position: absolute;
    padding-right: 0.04em;
    right: 0;
    top: 0;
  }
}
@keyframes blinker {
  0% {opacity: 0.6}
  40% {opacity: 0.6}
  50% {opacity: 0}
  100% {opacity: 0}
}
