.logo {
  font-size: 14px;
  position: relative;
  text-align: center;
  display: inline-block;
  width: 10em;
  height: 3.6em;
}
.logo:before {
  display: inline-block;
  vertical-align: middle;
  content: '';
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.logo--tcos:before {
  background-image: url('/assets/images/TCOS_Logo_white.png');
  background-image: url('/assets/images/TCOS_Logo_white.svg'),
  linear-gradient(transparent, transparent);
}
.logo--tcos-txt:before {
  background-image: url('/assets/images/TCOS_Logo_words-white.png');
  background-image: url('/assets/images/TCOS_Logo_words-white.svg'),
  linear-gradient(transparent, transparent);
}
