@if variable-exists('cody-base-partials') == false or index($cody-base-partials, 'util') {
  // --------------------------------
  // Flexbox
  // --------------------------------

  .flex { display: flex; }
  .inline-flex { display: inline-flex; }
  .flex-wrap { flex-wrap: wrap; }
  .flex-column { flex-direction: column; }
  .flex-column-reverse { flex-direction: column-reverse; }
  .flex-row { flex-direction: row; }
  .flex-row-reverse { flex-direction: row-reverse; }
  .flex-center { justify-content: center; align-items: center; }

  // flex items
  .flex-grow { flex-grow: 1; }
  .flex-shrink-0 { flex-shrink: 0; }
  .flex-basis-0 { flex-basis: 0; }

  // --------------------------------
  // Justify Content
  // --------------------------------

  .justify-start { justify-content: flex-start; }
  .justify-end { justify-content: flex-end; }
  .justify-center { justify-content: center; }
  .justify-between { justify-content: space-between; }

  // --------------------------------
  // Align Items
  // --------------------------------

  .items-center { align-items: center; }
  .items-start { align-items: flex-start; }
  .items-end { align-items: flex-end; }
  .items-baseline { align-items: baseline; }

  // --------------------------------
  // Order
  // --------------------------------

  .order-1 { order: 1; }
  .order-2 { order: 2; }
  .order-3 { order: 3; }

  // --------------------------------
  // Display
  // --------------------------------

  .block { display: block; }
  .inline-block { display: inline-block; }
  .inline { display: inline; }

  // --------------------------------
  // Margin
  // --------------------------------

  .margin-xxxxs { margin: var(--space-xxxxs); }
  .margin-xxxs  { margin: var(--space-xxxs); }
  .margin-xxs   { margin: var(--space-xxs); }
  .margin-xs    { margin: var(--space-xs); }
  .margin-sm    { margin: var(--space-sm); }
  .margin-md    { margin: var(--space-md); }
  .margin-lg    { margin: var(--space-lg); }
  .margin-xl    { margin: var(--space-xl); }
  .margin-xxl   { margin: var(--space-xxl); }
  .margin-xxxl  { margin: var(--space-xxxl); }
  .margin-xxxxl { margin: var(--space-xxxxl); }
  .margin-auto  { margin: auto; }
  .margin-0     { margin: 0; }

  .margin-top-xxxxs { margin-top: var(--space-xxxxs); }
  .margin-top-xxxs  { margin-top: var(--space-xxxs); }
  .margin-top-xxs   { margin-top: var(--space-xxs); }
  .margin-top-xs    { margin-top: var(--space-xs); }
  .margin-top-sm    { margin-top: var(--space-sm); }
  .margin-top-md    { margin-top: var(--space-md); }
  .margin-top-lg    { margin-top: var(--space-lg); }
  .margin-top-xl    { margin-top: var(--space-xl); }
  .margin-top-xxl   { margin-top: var(--space-xxl); }
  .margin-top-xxxl  { margin-top: var(--space-xxxl); }
  .margin-top-xxxxl { margin-top: var(--space-xxxxl); }
  .margin-top-auto  { margin-top: auto; }
  .margin-top-0     { margin-top: 0; }

  .margin-bottom-xxxxs { margin-bottom: var(--space-xxxxs); }
  .margin-bottom-xxxs  { margin-bottom: var(--space-xxxs); }
  .margin-bottom-xxs   { margin-bottom: var(--space-xxs); }
  .margin-bottom-xs    { margin-bottom: var(--space-xs); }
  .margin-bottom-sm    { margin-bottom: var(--space-sm); }
  .margin-bottom-md    { margin-bottom: var(--space-md); }
  .margin-bottom-lg    { margin-bottom: var(--space-lg); }
  .margin-bottom-xl    { margin-bottom: var(--space-xl); }
  .margin-bottom-xxl   { margin-bottom: var(--space-xxl); }
  .margin-bottom-xxxl  { margin-bottom: var(--space-xxxl); }
  .margin-bottom-xxxxl { margin-bottom: var(--space-xxxxl); }
  .margin-bottom-auto  { margin-bottom: auto; }
  .margin-bottom-0     { margin-bottom: 0; }

  .margin-right-xxxxs { margin-right: var(--space-xxxxs); }
  .margin-right-xxxs  { margin-right: var(--space-xxxs); }
  .margin-right-xxs   { margin-right: var(--space-xxs); }
  .margin-right-xs    { margin-right: var(--space-xs); }
  .margin-right-sm    { margin-right: var(--space-sm); }
  .margin-right-md    { margin-right: var(--space-md); }
  .margin-right-lg    { margin-right: var(--space-lg); }
  .margin-right-xl    { margin-right: var(--space-xl); }
  .margin-right-xxl   { margin-right: var(--space-xxl); }
  .margin-right-xxxl  { margin-right: var(--space-xxxl); }
  .margin-right-xxxxl { margin-right: var(--space-xxxxl); }
  .margin-right-auto  { margin-right: auto; }
  .margin-right-0     { margin-right: 0; }

  .margin-left-xxxxs { margin-left: var(--space-xxxxs); }
  .margin-left-xxxs  { margin-left: var(--space-xxxs); }
  .margin-left-xxs   { margin-left: var(--space-xxs); }
  .margin-left-xs    { margin-left: var(--space-xs); }
  .margin-left-sm    { margin-left: var(--space-sm); }
  .margin-left-md    { margin-left: var(--space-md); }
  .margin-left-lg    { margin-left: var(--space-lg); }
  .margin-left-xl    { margin-left: var(--space-xl); }
  .margin-left-xxl   { margin-left: var(--space-xxl); }
  .margin-left-xxxl  { margin-left: var(--space-xxxl); }
  .margin-left-xxxxl { margin-left: var(--space-xxxxl); }
  .margin-left-auto  { margin-left: auto; }
  .margin-left-0     { margin-left: 0; }

  .margin-x-xxxxs { margin-left: var(--space-xxxxs); margin-right: var(--space-xxxxs); }
  .margin-x-xxxs  { margin-left: var(--space-xxxs); margin-right: var(--space-xxxs); }
  .margin-x-xxs   { margin-left: var(--space-xxs); margin-right: var(--space-xxs); }
  .margin-x-xs    { margin-left: var(--space-xs); margin-right: var(--space-xs); }
  .margin-x-sm    { margin-left: var(--space-sm); margin-right: var(--space-sm); }
  .margin-x-md    { margin-left: var(--space-md); margin-right: var(--space-md); }
  .margin-x-lg    { margin-left: var(--space-lg); margin-right: var(--space-lg); }
  .margin-x-xl    { margin-left: var(--space-xl); margin-right: var(--space-xl); }
  .margin-x-xxl   { margin-left: var(--space-xxl); margin-right: var(--space-xxl); }
  .margin-x-xxxl  { margin-left: var(--space-xxxl); margin-right: var(--space-xxxl); }
  .margin-x-xxxxl { margin-left: var(--space-xxxxl); margin-right: var(--space-xxxxl); }
  .margin-x-auto  { margin-left: auto; margin-right: auto; }
  .margin-x-0     { margin-left: 0; margin-right: 0; }

  .margin-y-xxxxs { margin-top: var(--space-xxxxs); margin-bottom: var(--space-xxxxs); }
  .margin-y-xxxs  { margin-top: var(--space-xxxs); margin-bottom: var(--space-xxxs); }
  .margin-y-xxs   { margin-top: var(--space-xxs); margin-bottom: var(--space-xxs); }
  .margin-y-xs    { margin-top: var(--space-xs); margin-bottom: var(--space-xs); }
  .margin-y-sm    { margin-top: var(--space-sm); margin-bottom: var(--space-sm); }
  .margin-y-md    { margin-top: var(--space-md); margin-bottom: var(--space-md); }
  .margin-y-lg    { margin-top: var(--space-lg); margin-bottom: var(--space-lg); }
  .margin-y-xl    { margin-top: var(--space-xl); margin-bottom: var(--space-xl); }
  .margin-y-xxl   { margin-top: var(--space-xxl); margin-bottom: var(--space-xxl); }
  .margin-y-xxxl  { margin-top: var(--space-xxxl); margin-bottom: var(--space-xxxl); }
  .margin-y-xxxxl { margin-top: var(--space-xxxxl); margin-bottom: var(--space-xxxxl); }
  .margin-y-auto  { margin-top: auto; margin-bottom: auto; }
  .margin-y-0     { margin-top: 0; margin-bottom: 0; }

  // --------------------------------
  // Padding
  // --------------------------------

  .padding-xxxxs { padding: var(--space-xxxxs); }
  .padding-xxxs  { padding: var(--space-xxxs); }
  .padding-xxs   { padding: var(--space-xxs); }
  .padding-xs    { padding: var(--space-xs); }
  .padding-sm    { padding: var(--space-sm); }
  .padding-md    { padding: var(--space-md); }
  .padding-lg    { padding: var(--space-lg); }
  .padding-xl    { padding: var(--space-xl); }
  .padding-xxl   { padding: var(--space-xxl); }
  .padding-xxxl  { padding: var(--space-xxxl); }
  .padding-xxxxl { padding: var(--space-xxxxl); }
  .padding-0     { padding: 0; }
  .padding-component { padding: var(--component-padding); }

  .padding-top-xxxxs { padding-top: var(--space-xxxxs); }
  .padding-top-xxxs  { padding-top: var(--space-xxxs); }
  .padding-top-xxs   { padding-top: var(--space-xxs); }
  .padding-top-xs    { padding-top: var(--space-xs); }
  .padding-top-sm    { padding-top: var(--space-sm); }
  .padding-top-md    { padding-top: var(--space-md); }
  .padding-top-lg    { padding-top: var(--space-lg); }
  .padding-top-xl    { padding-top: var(--space-xl); }
  .padding-top-xxl   { padding-top: var(--space-xxl); }
  .padding-top-xxxl  { padding-top: var(--space-xxxl); }
  .padding-top-xxxxl { padding-top: var(--space-xxxxl); }
  .padding-top-0     { padding-top: 0; }
  .padding-top-component { padding-top: var(--component-padding); }

  .padding-bottom-xxxxs { padding-bottom: var(--space-xxxxs); }
  .padding-bottom-xxxs  { padding-bottom: var(--space-xxxs); }
  .padding-bottom-xxs   { padding-bottom: var(--space-xxs); }
  .padding-bottom-xs    { padding-bottom: var(--space-xs); }
  .padding-bottom-sm    { padding-bottom: var(--space-sm); }
  .padding-bottom-md    { padding-bottom: var(--space-md); }
  .padding-bottom-lg    { padding-bottom: var(--space-lg); }
  .padding-bottom-xl    { padding-bottom: var(--space-xl); }
  .padding-bottom-xxl   { padding-bottom: var(--space-xxl); }
  .padding-bottom-xxxl  { padding-bottom: var(--space-xxxl); }
  .padding-bottom-xxxxl { padding-bottom: var(--space-xxxxl); }
  .padding-bottom-0     { padding-bottom: 0; }
  .padding-bottom-component { padding-bottom: var(--component-padding); }

  .padding-right-xxxxs { padding-right: var(--space-xxxxs); }
  .padding-right-xxxs  { padding-right: var(--space-xxxs); }
  .padding-right-xxs   { padding-right: var(--space-xxs); }
  .padding-right-xs    { padding-right: var(--space-xs); }
  .padding-right-sm    { padding-right: var(--space-sm); }
  .padding-right-md    { padding-right: var(--space-md); }
  .padding-right-lg    { padding-right: var(--space-lg); }
  .padding-right-xl    { padding-right: var(--space-xl); }
  .padding-right-xxl   { padding-right: var(--space-xxl); }
  .padding-right-xxxl  { padding-right: var(--space-xxxl); }
  .padding-right-xxxxl { padding-right: var(--space-xxxxl); }
  .padding-right-0     { padding-right: 0; }
  .padding-right-component { padding-right: var(--component-padding); }

  .padding-left-xxxxs { padding-left: var(--space-xxxxs); }
  .padding-left-xxxs  { padding-left: var(--space-xxxs); }
  .padding-left-xxs   { padding-left: var(--space-xxs); }
  .padding-left-xs    { padding-left: var(--space-xs); }
  .padding-left-sm    { padding-left: var(--space-sm); }
  .padding-left-md    { padding-left: var(--space-md); }
  .padding-left-lg    { padding-left: var(--space-lg); }
  .padding-left-xl    { padding-left: var(--space-xl); }
  .padding-left-xxl   { padding-left: var(--space-xxl); }
  .padding-left-xxxl  { padding-left: var(--space-xxxl); }
  .padding-left-xxxxl { padding-left: var(--space-xxxxl); }
  .padding-left-0     { padding-left: 0; }
  .padding-left-component { padding-left: var(--component-padding); }

  .padding-x-xxxxs { padding-left: var(--space-xxxxs); padding-right: var(--space-xxxxs); }
  .padding-x-xxxs  { padding-left: var(--space-xxxs); padding-right: var(--space-xxxs); }
  .padding-x-xxs   { padding-left: var(--space-xxs); padding-right: var(--space-xxs); }
  .padding-x-xs    { padding-left: var(--space-xs); padding-right: var(--space-xs); }
  .padding-x-sm    { padding-left: var(--space-sm); padding-right: var(--space-sm); }
  .padding-x-md    { padding-left: var(--space-md); padding-right: var(--space-md); }
  .padding-x-lg    { padding-left: var(--space-lg); padding-right: var(--space-lg); }
  .padding-x-xl    { padding-left: var(--space-xl); padding-right: var(--space-xl); }
  .padding-x-xxl   { padding-left: var(--space-xxl); padding-right: var(--space-xxl); }
  .padding-x-xxxl  { padding-left: var(--space-xxxl); padding-right: var(--space-xxxl); }
  .padding-x-xxxxl { padding-left: var(--space-xxxxl); padding-right: var(--space-xxxxl); }
  .padding-x-0     { padding-left: 0; padding-right: 0; }
  .padding-x-component { padding-left: var(--component-padding); padding-right: var(--component-padding); }

  .padding-y-xxxxs { padding-top: var(--space-xxxxs); padding-bottom: var(--space-xxxxs); }
  .padding-y-xxxs  { padding-top: var(--space-xxxs); padding-bottom: var(--space-xxxs); }
  .padding-y-xxs   { padding-top: var(--space-xxs); padding-bottom: var(--space-xxs); }
  .padding-y-xs    { padding-top: var(--space-xs); padding-bottom: var(--space-xs); }
  .padding-y-sm    { padding-top: var(--space-sm); padding-bottom: var(--space-sm); }
  .padding-y-md    { padding-top: var(--space-md); padding-bottom: var(--space-md); }
  .padding-y-lg    { padding-top: var(--space-lg); padding-bottom: var(--space-lg); }
  .padding-y-xl    { padding-top: var(--space-xl); padding-bottom: var(--space-xl); }
  .padding-y-xxl   { padding-top: var(--space-xxl); padding-bottom: var(--space-xxl); }
  .padding-y-xxxl  { padding-top: var(--space-xxxl); padding-bottom: var(--space-xxxl); }
  .padding-y-xxxxl { padding-top: var(--space-xxxxl); padding-bottom: var(--space-xxxxl); }
  .padding-y-0     { padding-top: 0; padding-bottom: 0; }
  .padding-y-component { padding-top: var(--component-padding); padding-bottom: var(--component-padding); }

  // --------------------------------
  // Vertical Align
  // --------------------------------

  .align-baseline { vertical-align: baseline; }
  .align-top      { vertical-align: top; }
  .align-middle   { vertical-align: middle; }
  .align-bottom   { vertical-align: bottom; }

  // --------------------------------
  // Typography
  // --------------------------------

  .truncate, .text-truncate { // truncate text if it exceeds its parent
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .text-replace { // replace text with bg img
    overflow: hidden;
    color: transparent;
    text-indent: 100%;
    white-space: nowrap;
  }

  // --------------------------------
  // Text Transform
  // --------------------------------

  .text-uppercase  { text-transform: uppercase; }
  .text-capitalize { text-transform: capitalize; }

  // --------------------------------
  // Letter Spacing
  // --------------------------------

  .letter-spacing-sm { letter-spacing: -0.05em; }
  .letter-spacing-md { letter-spacing: 0.05em; }
  .letter-spacing-lg { letter-spacing: 0.1em; }

  // --------------------------------
  // Font Weight
  // --------------------------------

  .font-light    { font-weight: 300; }
  .font-normal   { font-weight: 400; }
  .font-medium   { font-weight: 500; }
  .font-semibold { font-weight: 600; }
  .font-bold, .text-bold { font-weight: 700; }

  // --------------------------------
  // Font Style
  // --------------------------------

  .font-italic { font-style: italic; }

  // --------------------------------
  // Font Smooth
  // --------------------------------

  .font-smooth {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  // --------------------------------
  // Font Family
  // --------------------------------

  .font-primary { font-family: var(--font-primary); }

  // --------------------------------
  // Text Align
  // --------------------------------

  .text-center  { text-align: center; }
  .text-left    { text-align: left; }
  .text-right   { text-align: right; }
  .text-justify { text-align: justify; }

  // --------------------------------
  // Text Decoration
  // --------------------------------

  .text-line-through    { text-decoration: line-through; }
  .text-underline       { text-decoration: underline; }
  .text-decoration-none { text-decoration: none; }

  // --------------------------------
  // .text-component vertical spacing
  // --------------------------------

  .v-space-xxs { --text-vspace-multiplier: 0.25 !important; }
  .v-space-xs  { --text-vspace-multiplier: 0.5 !important; }
  .v-space-sm  { --text-vspace-multiplier: 0.75 !important; }
  .v-space-md  { --text-vspace-multiplier: 1.25 !important; }
  .v-space-lg  { --text-vspace-multiplier: 1.5 !important; }
  .v-space-xl  { --text-vspace-multiplier: 1.75 !important; }
  .v-space-xxl { --text-vspace-multiplier: 2 !important; }

  // --------------------------------
  // Line Height
  // --------------------------------

  .line-height-xs {
    --heading-line-height: 1;
    --body-line-height: 1;

    &:not(.text-component) {
      line-height: 1;
    }
  }

  .line-height-sm {
    --heading-line-height: 1.1;
    --body-line-height: 1.2;

    &:not(.text-component) {
      line-height: 1.2;
    }
  }

  .line-height-md {
    --heading-line-height: 1.15;
    --body-line-height: 1.4;

    &:not(.text-component) {
      line-height: 1.4;
    }
  }

  .line-height-lg {
    --heading-line-height: 1.22;
    --body-line-height: 1.58;

    &:not(.text-component) {
      line-height: 1.58;
    }
  }

  .line-height-xl {
    --heading-line-height: 1.3;
    --body-line-height: 1.72;

    &:not(.text-component) {
      line-height: 1.72;
    }
  }

  .line-height-body    { line-height: var(--body-line-height); }
  .line-height-heading { line-height: var(--heading-line-height); }

  // --------------------------------
  // White Space
  // --------------------------------

  .ws-nowrap, .text-nowrap { white-space: nowrap; }

  // --------------------------------
  // Cursor
  // --------------------------------

  .cursor-pointer { cursor: pointer; }
  .cursor-default { cursor: default; }

  // --------------------------------
  // Pointer Events
  // --------------------------------

  .pointer-events-auto { pointer-events: auto; }
  .pointer-events-none { pointer-events: none; }

  // --------------------------------
  // User Select
  // --------------------------------

  .user-select-none { user-select: none; }
  .user-select-all  { user-select: all; }

  // --------------------------------
  // Color
  // --------------------------------

  [class*="color-"] { --color-o: 1; }

  .color-inherit { color: inherit; }
  .color-bg { color: alpha(var(--color-bg), var(--color-o, 1)); }
  .color-contrast-lower { color: alpha(var(--color-contrast-lower), var(--color-o, 1)); }
  .color-contrast-low { color: alpha(var(--color-contrast-low), var(--color-o, 1)); }
  .color-contrast-medium { color: alpha(var(--color-contrast-medium), var(--color-o, 1)); }
  .color-contrast-high { color: alpha(var(--color-contrast-high), var(--color-o, 1)); }
  .color-contrast-higher { color: alpha(var(--color-contrast-higher), var(--color-o, 1)); }
  .color-primary-darker { color: alpha(var(--color-primary-darker), var(--color-o, 1)); }
  .color-primary-dark { color: alpha(var(--color-primary-dark), var(--color-o, 1)); }
  .color-primary { color: alpha(var(--color-primary), var(--color-o, 1)); }
  .color-primary-light { color: alpha(var(--color-primary-light), var(--color-o, 1)); }
  .color-primary-lighter { color: alpha(var(--color-primary-lighter), var(--color-o, 1)); }
  .color-accent-darker { color: alpha(var(--color-accent-darker), var(--color-o, 1)); }
  .color-accent-dark { color: alpha(var(--color-accent-dark), var(--color-o, 1)); }
  .color-accent { color: alpha(var(--color-accent), var(--color-o, 1)); }
  .color-accent-light { color: alpha(var(--color-accent-light), var(--color-o, 1)); }
  .color-accent-lighter { color: alpha(var(--color-accent-lighter), var(--color-o, 1)); }
  .color-success-darker { color: alpha(var(--color-success-darker), var(--color-o, 1)); }
  .color-success-dark { color: alpha(var(--color-success-dark), var(--color-o, 1)); }
  .color-success { color: alpha(var(--color-success), var(--color-o, 1)); }
  .color-success-light { color: alpha(var(--color-success-light), var(--color-o, 1)); }
  .color-success-lighter { color: alpha(var(--color-success-lighter), var(--color-o, 1)); }
  .color-warning-darker { color: alpha(var(--color-warning-darker), var(--color-o, 1)); }
  .color-warning-dark { color: alpha(var(--color-warning-dark), var(--color-o, 1)); }
  .color-warning { color: alpha(var(--color-warning), var(--color-o, 1)); }
  .color-warning-light { color: alpha(var(--color-warning-light), var(--color-o, 1)); }
  .color-warning-lighter { color: alpha(var(--color-warning-lighter), var(--color-o, 1)); }
  .color-error-darker { color: alpha(var(--color-error-darker), var(--color-o, 1)); }
  .color-error-dark { color: alpha(var(--color-error-dark), var(--color-o, 1)); }
  .color-error { color: alpha(var(--color-error), var(--color-o, 1)); }
  .color-error-light { color: alpha(var(--color-error-light), var(--color-o, 1)); }
  .color-error-lighter { color: alpha(var(--color-error-lighter), var(--color-o, 1)); }
  .color-white { color: alpha(var(--color-white), var(--color-o, 1)); }
  .color-black { color: alpha(var(--color-black), var(--color-o, 1)); }


  .color-opacity-0    { --color-o: 0; }
  .color-opacity-10\% { --color-o: 0.1; }
  .color-opacity-20\% { --color-o: 0.2; }
  .color-opacity-30\% { --color-o: 0.3; }
  .color-opacity-40\% { --color-o: 0.4; }
  .color-opacity-50\% { --color-o: 0.5; }
  .color-opacity-60\% { --color-o: 0.6; }
  .color-opacity-70\% { --color-o: 0.7; }
  .color-opacity-80\% { --color-o: 0.8; }
  .color-opacity-90\% { --color-o: 0.9; }


  // --------------------------------
  // Width
  // --------------------------------

  .width-xxxxs { width: var(--size-xxxxs, 0.25rem); }
  .width-xxxs  { width: var(--size-xxxs, 0.5rem); }
  .width-xxs   { width: var(--size-xxs, 0.75rem); }
  .width-xs    { width: var(--size-xs, 1rem); }
  .width-sm    { width: var(--size-sm, 1.5rem); }
  .width-md    { width: var(--size-md, 2rem); }
  .width-lg    { width: var(--size-lg, 3rem); }
  .width-xl    { width: var(--size-xl, 4rem); }
  .width-xxl   { width: var(--size-xxl, 6rem); }
  .width-xxxl  { width: var(--size-xxxl, 8rem); }
  .width-xxxxl { width: var(--size-xxxxl, 16rem); }
  .width-0     { width: 0; }
  .width-10\%  { width: 10%; }
  .width-20\%  { width: 20%; }
  .width-25\%  { width: 25%; }
  .width-30\%  { width: 30%; }
  .width-33\%  { width: calc(100% / 3); }
  .width-40\%  { width: 40%; }
  .width-50\%  { width: 50%; }
  .width-60\%  { width: 60%; }
  .width-66\%  { width: calc(100% / 1.5); }
  .width-70\%  { width: 70%; }
  .width-75\%  { width: 75%; }
  .width-80\%  { width: 80%; }
  .width-90\%  { width: 90%; }
  .width-100\% { width: 100%; }
  .width-100vw { width: 100vw; }
  .width-auto  { width: auto; }

  // --------------------------------
  // Height
  // --------------------------------

  .height-xxxxs { height: var(--size-xxxxs, 0.25rem); }
  .height-xxxs  { height: var(--size-xxxs, 0.5rem); }
  .height-xxs   { height: var(--size-xxs, 0.75rem); }
  .height-xs    { height: var(--size-xs, 1rem); }
  .height-sm    { height: var(--size-sm, 1.5rem); }
  .height-md    { height: var(--size-md, 2rem); }
  .height-lg    { height: var(--size-lg, 3rem); }
  .height-xl    { height: var(--size-xl, 4rem); }
  .height-xxl   { height: var(--size-xxl, 6rem); }
  .height-xxxl  { height: var(--size-xxxl, 8rem); }
  .height-xxxxl { height: var(--size-xxxxl, 16rem); }
  .height-0     { height: 0; }
  .height-10\%  { height: 10%; }
  .height-20\%  { height: 20%; }
  .height-25\%  { height: 25%; }
  .height-30\%  { height: 30%; }
  .height-33\%  { height: calc(100% / 3); }
  .height-40\%  { height: 40%; }
  .height-50\%  { height: 50%; }
  .height-60\%  { height: 60%; }
  .height-66\%  { height: calc(100% / 1.5); }
  .height-70\%  { height: 70%; }
  .height-75\%  { height: 75%; }
  .height-80\%  { height: 80%; }
  .height-90\%  { height: 90%; }
  .height-100\% { height: 100%; }
  .height-100vh { height: 100vh; }
  .height-auto  { height: auto; }

  // --------------------------------
  // Min-Width
  // --------------------------------

  .min-width-0     { min-width: 0; }
  .min-width-25\%  { min-width: 25%; }
  .min-width-33\%  { min-width: calc(100% / 3); }
  .min-width-50\%  { min-width: 50%; }
  .min-width-66\%  { min-width: calc(100% / 1.5); }
  .min-width-75\%  { min-width: 75%; }
  .min-width-100\% { min-width: 100%; }
  .min-width-100vw { min-width: 100vw; }

  // --------------------------------
  // Min-Height
  // --------------------------------

  .min-height-100\% { min-height: 100%; }
  .min-height-100vh { min-height: 100vh; }

  // --------------------------------
  // Max-Width
  // --------------------------------

  :root {
    --max-width-xxxxs: 20rem;  // ~320px
    --max-width-xxxs:  26rem;  // ~416px
    --max-width-xxs:   32rem;  // ~512px
    --max-width-xs:    38rem;  // ~608px
    --max-width-sm:    48rem;  // ~768px
    --max-width-md:    64rem;  // ~1024px
    --max-width-lg:    80rem;  // ~1280px
    --max-width-xl:    90rem;  // ~1440px
    --max-width-xxl:   100rem; // ~1600px
    --max-width-xxxl:  120rem; // ~1920px
    --max-width-xxxxl: 150rem; // ~2400px
  }

  .max-width-xxxxs { max-width: var(--max-width-xxxxs); }
  .max-width-xxxs  { max-width: var(--max-width-xxxs); }
  .max-width-xxs   { max-width: var(--max-width-xxs); }
  .max-width-xs    { max-width: var(--max-width-xs); }
  .max-width-sm    { max-width: var(--max-width-sm); }
  .max-width-md    { max-width: var(--max-width-md); }
  .max-width-lg    { max-width: var(--max-width-lg); }
  .max-width-xl    { max-width: var(--max-width-xl); }
  .max-width-xxl   { max-width: var(--max-width-xxl); }
  .max-width-xxxl  { max-width: var(--max-width-xxxl); }
  .max-width-xxxxl { max-width: var(--max-width-xxxxl); }
  .max-width-100\% { max-width: 100%; }

  // alt approach - max-width is equal to current breakpoint
  $breakpointsNr: length($breakpoints);
  @each $breakpoint, $value in $breakpoints {
    $i: index($breakpoints, $breakpoint $value);
    @if $i == 1 {
      [class*="max-width-adaptive"] {
        max-width: map-get($map: $breakpoints, $key: #{$breakpoint});
      }
    } @else {
      $classList : '';
      @each $subBreakpoint, $subValue in $breakpoints {
        $j: index($breakpoints, $subBreakpoint $subValue);
        @if $j == $i {
          $classList: '.max-width-adaptive-#{$subBreakpoint}';
        } @else if $j > $i {
          $classList: $classList+', .max-width-adaptive-#{$subBreakpoint}';
        }
      }
      @if $i < $breakpointsNr {
        $classList: $classList+', .max-width-adaptive';
      }
      @include breakpoint(#{$breakpoint}) {
        #{$classList} {
          max-width: map-get($map: $breakpoints, $key: #{$breakpoint});
        }
      }
    }
  }

  // --------------------------------
  // Max-Height
  // --------------------------------

  .max-height-100\% { max-height: 100%; }
  .max-height-100vh { max-height: 100vh; }

  // --------------------------------
  // Position
  // --------------------------------

  .position-relative { position: relative; }
  .position-absolute { position: absolute; }
  .position-fixed    { position: fixed; }
  .position-sticky   { position: sticky; }

  .top-0    { top: 0; }
  .bottom-0 { bottom: 0; }
  .left-0   { left: 0; }
  .right-0  { right: 0; }

  // --------------------------------
  // Z-Index
  // --------------------------------

  .z-index-header { z-index: var(--zindex-header); }
  .z-index-popover { z-index: var(--zindex-popover); }
  .z-index-fixed-element { z-index: var(--zindex-fixed-element); }
  .z-index-overlay { z-index: var(--zindex-overlay); }

  .zindex-1, .z-index-1 { z-index: 1; }
  .zindex-2, .z-index-2 { z-index: 2; }
  .zindex-3, .z-index-3 { z-index: 3; }

  // --------------------------------
  // Overflow
  // --------------------------------

  .overflow-hidden { overflow: hidden; }
  .overflow-auto   { overflow: auto; }
  .momentum-scrolling { -webkit-overflow-scrolling: touch; }

  // overscroll-behavior
  .overscroll-contain { overscroll-behavior: contain; }

  // --------------------------------
  // Opacity
  // --------------------------------

  .opacity-0    { opacity: 0; }
  .opacity-10\% { opacity: 0.1; }
  .opacity-20\% { opacity: 0.2; }
  .opacity-30\% { opacity: 0.3; }
  .opacity-40\% { opacity: 0.4; }
  .opacity-50\% { opacity: 0.5; }
  .opacity-60\% { opacity: 0.6; }
  .opacity-70\% { opacity: 0.7; }
  .opacity-80\% { opacity: 0.8; }
  .opacity-90\% { opacity: 0.9; }

  // --------------------------------
  // Media Wrapper - make iframe responsive + prevent img jump when page loads
  // --------------------------------

  $media-wrapper-aspect-ratios: (
    (4 3),
    (1 1)
  ) !default;

  .media-wrapper {
    position: relative;
    height: 0;
    padding-bottom: 56.25%; // 16:9 aspect ratio

    > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @each $aspect-ratio in $media-wrapper-aspect-ratios {
    $aspect-ratio-x: nth($aspect-ratio, 1);
    $aspect-ratio-y: nth($aspect-ratio, 2);

    .media-wrapper--#{$aspect-ratio-x}\:#{$aspect-ratio-y} {
      padding-bottom: calc((#{$aspect-ratio-y} / #{$aspect-ratio-x}) * 100%);
    }
  }

  // --------------------------------
  // Float
  // --------------------------------

  .float-left  { float: left; }
  .float-right { float: right; }

  .clearfix::after {
    content: "";
    display: table;
    clear: both;
  }

  // --------------------------------
  // Border
  // --------------------------------

  [class*="border-"] { --border-o: 1; }

  .border { border: 1px solid alpha(var(--color-contrast-low), var(--border-o, 1)); }
  .border-top { border-top: 1px solid alpha(var(--color-contrast-low), var(--border-o, 1)); }
  .border-bottom { border-bottom: 1px solid alpha(var(--color-contrast-low), var(--border-o, 1)); }
  .border-left { border-left: 1px solid alpha(var(--color-contrast-low), var(--border-o, 1)); }
  .border-right { border-right: 1px solid alpha(var(--color-contrast-low), var(--border-o, 1)); }
  .border-2 { border-width: 2px; }
  .border-3 { border-width: 3px; }
  .border-4 { border-width: 4px; }

  .border-bg { border-color: alpha(var(--color-bg), var(--border-o, 1)); }
  .border-contrast-lower { border-color: alpha(var(--color-contrast-lower), var(--border-o, 1)); }
  .border-contrast-low { border-color: alpha(var(--color-contrast-low), var(--border-o, 1)); }
  .border-contrast-medium { border-color: alpha(var(--color-contrast-medium), var(--border-o, 1)); }
  .border-contrast-high { border-color: alpha(var(--color-contrast-high), var(--border-o, 1)); }
  .border-contrast-higher { border-color: alpha(var(--color-contrast-higher), var(--border-o, 1)); }
  .border-primary-darker { border-color: alpha(var(--color-primary-darker), var(--border-o, 1)); }
  .border-primary-dark { border-color: alpha(var(--color-primary-dark), var(--border-o, 1)); }
  .border-primary { border-color: alpha(var(--color-primary), var(--border-o, 1)); }
  .border-primary-light { border-color: alpha(var(--color-primary-light), var(--border-o, 1)); }
  .border-primary-lighter { border-color: alpha(var(--color-primary-lighter), var(--border-o, 1)); }
  .border-accent-darker { border-color: alpha(var(--color-accent-darker), var(--border-o, 1)); }
  .border-accent-dark { border-color: alpha(var(--color-accent-dark), var(--border-o, 1)); }
  .border-accent { border-color: alpha(var(--color-accent), var(--border-o, 1)); }
  .border-accent-light { border-color: alpha(var(--color-accent-light), var(--border-o, 1)); }
  .border-accent-lighter { border-color: alpha(var(--color-accent-lighter), var(--border-o, 1)); }
  .border-success-darker { border-color: alpha(var(--color-success-darker), var(--border-o, 1)); }
  .border-success-dark { border-color: alpha(var(--color-success-dark), var(--border-o, 1)); }
  .border-success { border-color: alpha(var(--color-success), var(--border-o, 1)); }
  .border-success-light { border-color: alpha(var(--color-success-light), var(--border-o, 1)); }
  .border-success-lighter { border-color: alpha(var(--color-success-lighter), var(--border-o, 1)); }
  .border-warning-darker { border-color: alpha(var(--color-warning-darker), var(--border-o, 1)); }
  .border-warning-dark { border-color: alpha(var(--color-warning-dark), var(--border-o, 1)); }
  .border-warning { border-color: alpha(var(--color-warning), var(--border-o, 1)); }
  .border-warning-light { border-color: alpha(var(--color-warning-light), var(--border-o, 1)); }
  .border-warning-lighter { border-color: alpha(var(--color-warning-lighter), var(--border-o, 1)); }
  .border-error-darker { border-color: alpha(var(--color-error-darker), var(--border-o, 1)); }
  .border-error-dark { border-color: alpha(var(--color-error-dark), var(--border-o, 1)); }
  .border-error { border-color: alpha(var(--color-error), var(--border-o, 1)); }
  .border-error-light { border-color: alpha(var(--color-error-light), var(--border-o, 1)); }
  .border-error-lighter { border-color: alpha(var(--color-error-lighter), var(--border-o, 1)); }
  .border-white { border-color: alpha(var(--color-white), var(--border-o, 1)); }
  .border-black { border-color: alpha(var(--color-black), var(--border-o, 1)); }


  .border-opacity-0    { --border-o: 0; }
  .border-opacity-10\% { --border-o: 0.1; }
  .border-opacity-20\% { --border-o: 0.2; }
  .border-opacity-30\% { --border-o: 0.3; }
  .border-opacity-40\% { --border-o: 0.4; }
  .border-opacity-50\% { --border-o: 0.5; }
  .border-opacity-60\% { --border-o: 0.6; }
  .border-opacity-70\% { --border-o: 0.7; }
  .border-opacity-80\% { --border-o: 0.8; }
  .border-opacity-90\% { --border-o: 0.9; }


  // --------------------------------
  // Border Radius
  // --------------------------------

  .radius-sm { border-radius: var(--radius-sm); }
  .radius-md { border-radius: var(--radius-md); }
  .radius-lg { border-radius: var(--radius-lg); }
  .radius-50\% { border-radius: 50%; }
  .radius-full { border-radius: 50em; }

  // --------------------------------
  // Box-Shadow
  // --------------------------------

  .shadow-xs { box-shadow: var(--shadow-xs); }
  .shadow-sm { box-shadow: var(--shadow-sm); }
  .shadow-md { box-shadow: var(--shadow-md); }
  .shadow-lg { box-shadow: var(--shadow-lg); }
  .shadow-xl { box-shadow: var(--shadow-xl); }

  // --------------------------------
  // Background
  // --------------------------------

  .bg, [class*="bg-"] { --bg-o: 1; }

  .bg-transparent { background-color: transparent; }
  .bg-inherit { background-color: inherit; }
  .bg { background-color: alpha(var(--color-bg), var(--bg-o)); }
  .bg-contrast-lower { background-color: alpha(var(--color-contrast-lower), var(--bg-o, 1)); }
  .bg-contrast-low { background-color: alpha(var(--color-contrast-low), var(--bg-o, 1)); }
  .bg-contrast-medium { background-color: alpha(var(--color-contrast-medium), var(--bg-o, 1)); }
  .bg-contrast-high { background-color: alpha(var(--color-contrast-high), var(--bg-o, 1)); }
  .bg-contrast-higher { background-color: alpha(var(--color-contrast-higher), var(--bg-o, 1)); }
  .bg-primary-darker { background-color: alpha(var(--color-primary-darker), var(--bg-o, 1)); }
  .bg-primary-dark { background-color: alpha(var(--color-primary-dark), var(--bg-o, 1)); }
  .bg-primary { background-color: alpha(var(--color-primary), var(--bg-o, 1)); }
  .bg-primary-light { background-color: alpha(var(--color-primary-light), var(--bg-o, 1)); }
  .bg-primary-lighter { background-color: alpha(var(--color-primary-lighter), var(--bg-o, 1)); }
  .bg-accent-darker { background-color: alpha(var(--color-accent-darker), var(--bg-o, 1)); }
  .bg-accent-dark { background-color: alpha(var(--color-accent-dark), var(--bg-o, 1)); }
  .bg-accent { background-color: alpha(var(--color-accent), var(--bg-o, 1)); }
  .bg-accent-light { background-color: alpha(var(--color-accent-light), var(--bg-o, 1)); }
  .bg-accent-lighter { background-color: alpha(var(--color-accent-lighter), var(--bg-o, 1)); }
  .bg-success-darker { background-color: alpha(var(--color-success-darker), var(--bg-o, 1)); }
  .bg-success-dark { background-color: alpha(var(--color-success-dark), var(--bg-o, 1)); }
  .bg-success { background-color: alpha(var(--color-success), var(--bg-o, 1)); }
  .bg-success-light { background-color: alpha(var(--color-success-light), var(--bg-o, 1)); }
  .bg-success-lighter { background-color: alpha(var(--color-success-lighter), var(--bg-o, 1)); }
  .bg-warning-darker { background-color: alpha(var(--color-warning-darker), var(--bg-o, 1)); }
  .bg-warning-dark { background-color: alpha(var(--color-warning-dark), var(--bg-o, 1)); }
  .bg-warning { background-color: alpha(var(--color-warning), var(--bg-o, 1)); }
  .bg-warning-light { background-color: alpha(var(--color-warning-light), var(--bg-o, 1)); }
  .bg-warning-lighter { background-color: alpha(var(--color-warning-lighter), var(--bg-o, 1)); }
  .bg-error-darker { background-color: alpha(var(--color-error-darker), var(--bg-o, 1)); }
  .bg-error-dark { background-color: alpha(var(--color-error-dark), var(--bg-o, 1)); }
  .bg-error { background-color: alpha(var(--color-error), var(--bg-o, 1)); }
  .bg-error-light { background-color: alpha(var(--color-error-light), var(--bg-o, 1)); }
  .bg-error-lighter { background-color: alpha(var(--color-error-lighter), var(--bg-o, 1)); }
  .bg-white { background-color: alpha(var(--color-white), var(--bg-o, 1)); }
  .bg-black { background-color: alpha(var(--color-black), var(--bg-o, 1)); }

  .bg-opacity-0    { --bg-o: 0; }
  .bg-opacity-10\% { --bg-o: 0.1; }
  .bg-opacity-20\% { --bg-o: 0.2; }
  .bg-opacity-30\% { --bg-o: 0.3; }
  .bg-opacity-40\% { --bg-o: 0.4; }
  .bg-opacity-50\% { --bg-o: 0.5; }
  .bg-opacity-60\% { --bg-o: 0.6; }
  .bg-opacity-70\% { --bg-o: 0.7; }
  .bg-opacity-80\% { --bg-o: 0.8; }
  .bg-opacity-90\% { --bg-o: 0.9; }

  .bg-cover { background-size: cover; }
  .bg-center { background-position: center; }
  .bg-no-repeat { background-repeat: no-repeat; }

  // --------------------------------
  // Backdrop Filter
  // --------------------------------

  .backdrop-blur-10 { backdrop-filter: blur(10px); }
  .backdrop-blur-20 { backdrop-filter: blur(20px); }

  // --------------------------------
  // Mix-Blend Mode
  // --------------------------------

  .isolate { isolation: isolate; }
  .blend-multiply { mix-blend-mode: multiply; }
  .blend-overlay  { mix-blend-mode: overlay; }

  // --------------------------------
  // Object-Fit
  // --------------------------------

  .object-contain { object-fit: contain; }
  .object-cover   { object-fit: cover; }

  // --------------------------------
  // Transform
  // --------------------------------

  .flip   { transform: scale(-1); }
  .flip-x { transform: scaleX(-1); }
  .flip-y { transform: scaleY(-1); }

  // --------------------------------
  // Responsive Variations
  // --------------------------------

  @each $breakpoint, $value in $breakpoints {
    @include breakpoint(#{$breakpoint}) {
      // flexbox
      .flex\@#{$breakpoint} { display: flex; }
      .inline-flex\@#{$breakpoint} { display: inline-flex; }
      .flex-wrap\@#{$breakpoint} { flex-wrap: wrap; }
      .flex-column\@#{$breakpoint} { flex-direction: column; }
      .flex-column-reverse\@#{$breakpoint} { flex-direction: column-reverse; }
      .flex-row\@#{$breakpoint} { flex-direction: row; }
      .flex-row-reverse\@#{$breakpoint} { flex-direction: row-reverse; }
      .flex-center\@#{$breakpoint} { justify-content: center; align-items: center; }

      .flex-grow-0\@#{$breakpoint} { flex-grow: 0; }
      .flex-grow\@#{$breakpoint} { flex-grow: 1; }
      .flex-shrink-0\@#{$breakpoint} { flex-shrink: 0; }
      .flex-shrink\@#{$breakpoint} { flex-shrink: 1; }

      // justify-content
      .justify-start\@#{$breakpoint} { justify-content: flex-start; }
      .justify-end\@#{$breakpoint} { justify-content: flex-end; }
      .justify-center\@#{$breakpoint} { justify-content: center; }
      .justify-between\@#{$breakpoint} { justify-content: space-between; }

      // align-items
      .items-center\@#{$breakpoint} { align-items: center; }
      .items-start\@#{$breakpoint} { align-items: flex-start; }
      .items-end\@#{$breakpoint} { align-items: flex-end; }
      .items-baseline\@#{$breakpoint} { align-items: baseline; }

      // order
      .order-1\@#{$breakpoint}  { order: 1; }
      .order-2\@#{$breakpoint}  { order: 2; }
      .order-3\@#{$breakpoint}  { order: 3; }

      // display
      .block\@#{$breakpoint} { display: block; }
      .inline-block\@#{$breakpoint} { display: inline-block; }
      .inline\@#{$breakpoint} { display: inline; }

      // text-align
      .text-center\@#{$breakpoint} { text-align: center; }
      .text-left\@#{$breakpoint}   { text-align: left; }
      .text-right\@#{$breakpoint}  { text-align: right; }
      .text-justify\@#{$breakpoint}  { text-align: justify; }

      // font-size
      .text-xs\@#{$breakpoint}   { font-size: 0.694em; }
      .text-sm\@#{$breakpoint}   { font-size: 0.833em; }
      .text-base\@#{$breakpoint} { font-size: 1em; }
      .text-md\@#{$breakpoint}   { font-size: 1.2em; }
      .text-lg\@#{$breakpoint}   { font-size: 1.44em; }
      .text-xl\@#{$breakpoint}   { font-size: 1.728em; }
      .text-xxl\@#{$breakpoint}  { font-size: 2.074em; }
      .text-xxxl\@#{$breakpoint} { font-size: 2.488em; }
      .text-xxxxl\@#{$breakpoint} { font-size: 2.985em; }

      // visibility
      .hide\@#{$breakpoint} { display: none !important; }
    }

    @include breakpoint(#{$breakpoint}, "not all") {
      .has-margin\@#{$breakpoint} { margin: 0 !important; }
      .has-padding\@#{$breakpoint} { padding: 0 !important; }
      .display\@#{$breakpoint} { display: none !important; }
    }
  }
}
