

.site-header {
  padding: 0;
  position: fixed;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 100;
  // background-color: rgba(0,0,0,0.1)
}
.site-header {
  &__menu,
  &__mask {
    &:after {
      content: "";
      width: 10em;
      height: 100%;
      left: 100%;
      top: 0;
      display: block;
      position: absolute;
      background: -moz-linear-gradient(left, rgba(4,16,32,1) 0%, rgba(4,16,32,0) 100%);
      background: -webkit-linear-gradient(left, rgba(4,16,32,1) 0%,rgba(4,16,32,0) 100%);
      background: linear-gradient(to right, rgba(4,16,32,1) 0%,rgba(4,16,32,0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#041020', endColorstr='#00041020',GradientType=1 );
    }
    position: fixed;
    background-color: var(--color-black);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transform: translate(-100%, 0);
    opacity: 0;
    will-change: transform, opacity;
    transition: 0.4s transform ease-in-out, opacity 0.4s ease-out 0.1s;
    .site-header #site-header__toggle:checked ~ & {
      transition: 0.5s transform cubic-bezier(0, 0, 0.3, 1), opacity 0.6s cubic-bezier(0, 0.65, 0, 0.65);
      transform: translate(0, 0);
      opacity: 0.8;
    }
  }
  &__mask {
    z-index: -1;
  }
  &__menu {
    .chunk {
      margin: 0;
    }
  }
  &__menu li {
    .chunk {
      margin: 0;
      position: absolute;
    }
    font-family: Jost,sans-serif,serif;
    word-wrap: break-word;
    font-weight: 500;
    font-size: 2.5em;


    a {
      text-decoration: none;
      color: #FFF;
    }
  }
}
.site-header {
  &__logo,
  &__menu-icon,
  &__toggle {
    z-index: 1;
    position: relative;
  }
  &__logo {
    display: flex;
    flex-direction: column;
  }
  .logo {
    position: relative;
    width: 220%;
    height: unset;
    padding-bottom: 500%;
    transform: translateX(-28%);
    margin-top: 60%;
    &:before {
      transform: rotate(-90deg);
      transform-origin: 50% 50%;
      left: -50%;
      top: 0;
      position: absolute;
      width: 200%;
    }
    &.logo--tcos-txt {
      width: 248%;
      margin-top: 50%;
    }
  }
}
