// Typography
// get cap sizes from:
// https://seek-oss.github.io/capsize/

:root {
  // font family
  --font-primary: 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;

  // font size
  --text-base-size: 1em; // body font-size
  --text-scale-ratio: 1.3076605; // multiplier used to generate the type scale values 👇

  // line-height
  --body-line-height: 1.4;
  --heading-line-height: 1.2;

  // capital letters - used in combo with the lhCrop mixin
  --font-primary-capital-letter: 0.8;

  // unit - don't modify unless you want to change the typography unit (e.g., from Em to Rem units)
  --text-unit: 1em; // if not Em units → --text-unit: var(--text-base-size);
}

:root, * {
  // type scale
  --text-xs: calc((var(--text-unit) / var(--text-scale-ratio)) / var(--text-scale-ratio));
  --text-sm: calc(var(--text-xs) * var(--text-scale-ratio));
  --text-md: calc(var(--text-sm) * var(--text-scale-ratio) * var(--text-scale-ratio));
  --text-lg: calc(var(--text-md) * var(--text-scale-ratio));
  --text-xl: calc(var(--text-lg) * var(--text-scale-ratio));
  --text-xxl: calc(var(--text-xl) * var(--text-scale-ratio));
  --text-xxxl: calc(var(--text-xxl) * var(--text-scale-ratio));
  --text-xxxxl: calc(var(--text-xxxl) * var(--text-scale-ratio));
}


// html {
//   font-size: calc(1vh / 0.6);
// }

html {
  font-size: calc(8px + 0.22222vw + 0.11111vh);
}


body {
  font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
}

b, strong, .bold {
  font-weight: bold;
}

@mixin capsize (
    // font metrics
    $lineGap,
    $weight,
    $ascent,
    $descent,
    $emUnits,
    $capHeight,
    // font options
    $size: 1,
    $lineSpace: 1,
    $headerScale: 1,
    $headerMinSize: 0,
    $vertAlign: 'middle',
  ) {
  $capHeightScale: $capHeight / $emUnits;
  $descentScale: abs($descent) / $emUnits;
  $ascentScale: $ascent / $emUnits;
  $lineGapScale: $lineGap / $emUnits;
  $lineHeightScale: ($ascent + $lineGap + abs($descent)) / $emUnits;

  $capSize: $headerMinSize + $size * $headerScale;
  $fontSize: $capSize / ($capHeight / $emUnits);
  $lineHeight: ($capSize + $lineSpace) / $fontSize;
  $lineHeightNormal: $lineHeightScale * $fontSize;
  $lineHeightOffset: ($lineHeightNormal - $capSize) / $fontSize / 2;

  $preventCollapse: 0.05 / $fontSize;

  $offsetTopScale: $ascentScale - $capHeightScale + $lineGapScale / 2;
  $offsetBtmScale: $descentScale + $lineGapScale / 2;

  $offsetTop: ($offsetTopScale - $lineHeightOffset + $preventCollapse) * -1;
  $offsetBtm: ($offsetBtmScale - $lineHeightOffset + $preventCollapse) * -1;

  $offsetLeft: $capSize / (185 / $emUnits);

  font-weight: $weight;
  font-size: #{$fontSize}rem;
  line-height: $lineHeight;
  padding: #{$preventCollapse}rem 0;

  margin-top: #{$lineHeight}em;

  &::before,
  &::after {
    content: "";
    display: block;
    height: 0;
  }
  &::before{ margin-top: #{$offsetTop}rem }
  &::after { margin-bottom: #{$offsetBtm}rem }
}


@for $i from 1 through 6 {
  .font-size--#{7 - $i},
  h#{6 - $i} {
    font-family: "Jost", sans-serif, serif;
    word-wrap: break-word;
    &:first-child {
      margin-top: 0;
    }

    @include capsize(
      // font metrics
      $lineGap: 0,
      $weight: 500,
      $ascent: 1070,
      $descent: -375,
      $emUnits: 1000,
      $capHeight: 700,
      // font options
      $size: $i - 1,
      $lineSpace: 1,
      $headerScale: 0.75,
      $headerMinSize: 1,
      $vertAlign: 'top'
    )

    @include breakpoint(xs) {
      @include capsize(
        // font metrics
        $lineGap: 0,
        $weight: 500,
        $ascent: 1070,
        $descent: -375,
        $emUnits: 1000,
        $capHeight: 700,
        // font options
        $size: $i - 1,
        $lineSpace: 1,
        $headerScale: 1.15,
        $headerMinSize: 1,
        $vertAlign: 'top'
      )
    }
  }
}

.btn {
  font-family: "Jost", sans-serif, serif;
  @include capsize(
    // font metrics
    $lineGap: 0,
    $weight: 500,
    $ascent: 1070,
    $descent: -375,
    $emUnits: 1000,
    $capHeight: 700,
    // font options
    $size: 1,
    $lineSpace: 1,
    $headerScale: 1.15,
    $headerMinSize: 0,
    $vertAlign: 'top'
  )
  padding: .7em 1.1em;
}


p, ul, ol, dl, .font-size--0 {
  font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  @include capsize (
    // font metrics
    $lineGap: 0,
    $weight: 300,
    $ascent: 1900,
    $descent: -500,
    $emUnits: 2048,
    $capHeight: 1456,
    $size: 1,
    $lineSpace: 1.5,
  )
  letter-spacing: 0.03em;
}

p > i {
  font-style: italic;
}
