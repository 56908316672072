.grid {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 1;
  top: 0;
  min-height: 100vh;
  z-index: 100;
}
.gridline {
  stroke-width: 1;
  stroke: rgba(0,180,255,0.2);
}

.canon {
  /* display: none; */
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.canon.c2 {
  width: 100vh;
  transform: rotate(-90deg);
}

.canon rect {
  fill: rgba(0,0,0,0);
  stroke: rgb(150,150,150);
  stroke-width: 1;
  width: 500;
  height: 500;
}

.canon line,
line.line--col-red {
  width: 100%;
  stroke: rgb(255,0,80);
  stroke-width: 0.2
}

line.line--col-blue{
  stroke: rgb(0,180,255);
}

line.line--col-yellow{
  stroke: rgb(255,180,0);
}

line.line--col-teal{
  stroke: rgba(0,180,255,0.9);
}
