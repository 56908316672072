// --------------------------------
// Container - center content on x-axis
// --------------------------------

// .container {
//   width: calc(100% - 2*var(--component-padding));
//   margin-left: auto;
//   margin-right: auto;
// }

// --------------------------------
// Grid System
// --------------------------------

.grid {
  --gap: 0px;
  --offset: var(--gap);
  display: flex;
  flex-wrap: wrap;

  > * {
    flex-basis: 100%;
    max-width: 100%;
  }
}

[class*="gap-xxxxs"], [class*="gap-xxxs"], [class*="gap-xxs"], [class*="gap-xs"], [class*="gap-sm"], [class*="gap-md"], [class*="gap-lg"], [class*="gap-xl"], [class*="gap-xxl"], [class*="gap-xxxl"], [class*="gap-xxxxl"], [class*="grid-gap-"], [class*="flex-gap-"] {
  margin-bottom: -0.75em;
  margin-left: -0.75em;

  > * {
    margin-bottom: 0.75em;
    margin-left: 0.75em;
  }
}

.col { // auto-sized column
  @include autoSizedColumn;
}

@for $i from 1 through $grid-columns {
  .col-#{$i} {
    flex-basis: calc( #{round-width($i)} - 0.01px - var(--gap, 0.75em));
    max-width: calc( #{round-width($i)} - 0.01px - var(--gap, 0.75em));
  }
}

.col-content {
  flex-basis: initial;
  max-width: initial;
}

@for $i from 1 through $grid-columns - 1 {
  .offset-#{$i} {
    --offset: calc(#{round-width($i)} + var(--gap, 0.75em));
  }
}

@each $breakpoint, $value in $breakpoints {
  @include breakpoint(#{$breakpoint}) {
    .col\@#{$breakpoint} { // auto-sized column
      @include autoSizedColumn;
    }

    @for $i from 1 through $grid-columns {
      .col-#{$i}\@#{$breakpoint} {
        flex-basis: calc( #{round-width($i)} - 0.01px - var(--gap, 0.75em));
        max-width: calc( #{round-width($i)} - 0.01px - var(--gap, 0.75em));
      }
    }

    .col-content\@#{$breakpoint} {
      flex-basis: initial;
      max-width: initial;
    }

    @for $i from 1 through $grid-columns - 1 {
      .offset-#{$i}\@#{$breakpoint} {
        --offset: calc(#{round-width($i)} + var(--gap, 0.75em));
      }
    }
  }
}
