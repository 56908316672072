/* purgecss start ignore */
@import 'base/reset';
/* purgecss end ignore */
@import 'base/breakpoints';
@import 'base/mixins';
@import 'base/colors';
@import 'base/spacing';
@import 'base/grid-layout';
@import 'base/shared-styles';
@import 'base/z-index';
@import 'base/util';
@import 'base/typography';
@import 'base/icons';
@import 'base/buttons';
@import 'base/forms';
@import 'base/visibility';
@import 'base/accessibility';
@import 'custom-style/colors';
@import 'custom-style/shared-styles';
@import 'custom-style/typography';
@import 'custom-style/icons';
@import 'custom-style/buttons';
@import 'custom-style/forms';
@import 'custom-style/logo';
@import "components/_1_blob-shape.scss";
@import "components/_1_burger.scss";
@import "components/_1_details-list.scss";
@import "components/_1_feature-list.scss";
@import "components/_1_footer.scss";
@import "components/_1_grid.scss";
@import "components/_1_header.scss";
@import "components/_1_testimonial.scss";
@import "components/_3_typewriter-header.scss";
@import "components/_4_intro.scss";
@import "components/_4_ribbon.scss";

.body-inner {
  height: 100%;
  overflow: auto;
  // min-height: 100vh;
  position: relative;
  overflow-x: hidden;
}
.svg-filter {
  position: fixed;
  width: 0;
  height: 0;
}
.max-w26rem {
  max-width: 26rem;
}
.max-w30rem {
  max-width: 30rem;
}

@for $i from 0 through 100 {
  .max-w#{$i}rem {
    max-width: #{$i + 'rem'};
  }
  .chunk.chunk {
    &--w#{$i} {
      flex: 0 0 #{$i + '%'};
    }
    &--h#{$i}vh {
      min-height: #{$i + 'vh'};
    }
  }
}

$g-pad: 3.5rem;
$header-w: 2rem;

.site-header {
  width: $header-w + $g-pad * 2;
  padding: $g-pad;
  @include breakpoint(xs) {
    width: $header-w + $g-pad * 2;
    padding: $g-pad;
  }
}

.chunky {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 0;
  position: relative;
}
.chunk {
  flex: 1;
  padding: 0;
  padding: 9.9999vh $g-pad;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
  &--absolute {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    svg {
      margin-top: 0.15rem;
    }
  }
  &.chunk--w100,
  &:nth-child(odd) {
    padding-left: $header-w + $g-pad * 2;

    @include breakpoint(xs) {
      padding-left: $header-w + $g-pad * 2;
    }

  }
  &.align-center {
    align-items: center;
  }
  &.align-right {
    align-items: flex-end;
  }
  > * {
    position: relative;
  }
  &__sticky-top {
    position: sticky;
    top: 0;
  }
  &__sticky-middle {
    position: sticky;
    top: 50%;
  }
  &--nopad {
    padding: 0;
    & [class$="sticky-top"] {
      top: 0;
    }
  }
  &--h100vh {
    min-height: 100vh;
  }
  &__bg-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: inherit;
    &:last-child {
      z-index: 0;
    }
  }
  &--bg {
    &-image {
      background: inherit;
      &-blur {
        overflow: hidden;
      }
      &-blur:before,
      &:before {
        content: '';
        background: inherit;
        background-size: cover;
        display: block;
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: 0.4;
        z-index: 0;
        top: 0;
        left: 0;
      }
      &-blur:before {
        filter: url(#svg-filter__blur);
        // filter: blur(15px);
        height: 150%;
        width: 150%;
        top: -25%;
        left: -25%;
        opacity: 0.20;
      }
    }
  }
  .btn {
    margin-top: 1.5em;
  }
}
