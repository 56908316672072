:root {
  --btn-font-size: var(--text-md);
  --btn-padding-x: 1.3em;
  --btn-padding-y: 0.8em;
  --btn-radius: 10em;
}

.btn {
  cursor: pointer;
  font-family: "Jost", sans-serif, serif;
  font-weight: 400;
  text-decoration: none;
  line-height: 1;
  text-shadow: 0px -1px 0px alpha(var(--color-black), 0.25);
  box-shadow: var(--shadow-xs);
  transition: all 0.2s ease;
  will-change: transform;
  margin-top: 1em;
  letter-spacing: 0.01em;

  &:hover {
    box-shadow: var(--shadow-sm);
  }

  &:focus {
    box-shadow: 0px 0px 0px 2px alpha(var(--color-contrast-higher), 0.15);
    outline: none;
  }

  &:active {
    transform: translateY(2px);
  }
}

// themes
.btn--primary {
  background: var(--color-primary);
  color: var(--color-white);

  &:hover {
    background: var(--color-primary-dark);
  }

  &:focus {
    box-shadow: 0px 0px 0px 2px alpha(var(--color-primary), 0.2);
  }
}

.btn--subtle {
  background: alpha(var(--color-white), 0.1);
  color: var(--color-contrast-higher);
}

.btn--accent {
  background: var(--color-accent);

  &:hover {
    background: var(--color-accent-dark);
  }

  &:focus {
    box-shadow: 0px 0px 0px 2px alpha(var(--color-accent), 0.2);
  }
}

// feedback
.btn--disabled, .btn[disabled], .btn[readonly] {
  opacity: 0.6;
  cursor: not-allowed;
}

// size
.btn--sm {
  font-size: 0.8em;
}

.btn--md {
  font-size: 1.2em;
}

.btn--lg {
  font-size: 1.4em;
}

// Gradient

.btn--gradient {
  background: var(--color-primary);
  background-image: var(--gradient-primary-accent);
  color: var(--color-white);
}

.btn--animated-gradient {
  @include bg-animated-gradient
  color: var(--color-white);
  position: relative;
  overflow: hidden;
  &:before {
    top: 0;
    left: -3%;
    content: '';
    position: absolute;
    width: 106%;
    height: 100%;
    background-color: alpha(var(--color-success), 0);
    transform: translateX(-100%) skew(30deg, 0deg);
    transition: all 0.5s ease;
    z-index: -1;
  }
  &:hover:before {
    transform: translateX(0) skew(30deg, 0deg);
    background-color: alpha(var(--color-success), 0.7);
  }
}
