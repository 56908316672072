//
// .burger {
//   width: 100%;
//   padding: 0 0 2em;
//   display: block;
//   position: relative;
//   font-size: 1rem; // **
//   cursor: pointer;
//   transition: 0.2s all;
//   -webkit-tap-highlight-color: transparent;
//   &:before,
//   span,
//   &:after {
//     pointer-events: none;
//     display: block;
//     content: '';
//     width: 100%;
//     height: 12.5%;
//     border-radius: 0.5em;
//     background-color: white;
//     position: absolute;
//     transform-origin: 50%;
//     transform: rotate(0deg) translate(0, 0);
//     transition:  0.2s bottom, 0.2s top, 0.2s transform, 0.4s background-color 0.1s;
//     min-height: 3px;
//     .burger input:checked + & {
//       transition:  0.2s bottom 0.1s, 0.2s top 0.1s, 0.2s transform 0.1s, 0.4s background-color;
//     }
//   }
//   &:before {
//     top: 0;
//     .nav input:checked ~ & {
//       top: 50%;
//       transform: translate(0, -50%) rotate(45deg);
//     }
//   }
//   span {
//     top: 50%;
//     transform: rotate(0deg) translate(0, -50%);
//     .nav input:checked ~ & {
//       background-color: transparent;
//       transform: rotate(0deg) translate(0, 0);
//     }
//   }
//   &:after {
//     bottom: 0;
//     .nav input:checked ~ & {
//       bottom: 50%;
//       transform: translate(0, 50%) rotate(-45deg);
//     }
//   }
// }


.site-header {
  &__menu-icon {
    width: 100%;
    padding: 0 0 2em;
    display: block;
    position: relative;
    font-size: 1rem; // **
    cursor: pointer;
    transition: 0.2s all;
    -webkit-tap-highlight-color: transparent;
  }
  &__menu-icon:before,
  &__menu-icon span,
  &__menu-icon:after {
    pointer-events: none;
    display: block;
    content: '';
    width: 100%;
    height: 12.5%;
    border-radius: 0.5em;
    background-color: white;
    position: absolute;
    transform-origin: 50%;
    transform: rotate(0deg) translate(0, 0);
    transition:  0.2s bottom, 0.2s top, 0.2s transform, 0.4s background-color 0.1s;
    min-height: 3px;
    .site-header #site-header__toggle:checked ~ & {
      transition:  0.2s bottom 0.1s, 0.2s top 0.1s, 0.2s transform 0.1s, 0.4s background-color;
    }
  }
  &__menu-icon:before {
    top: 0;
    .site-header #site-header__toggle:checked ~ & {
      top: 50%;
      transform: translate(0, -50%) rotate(45deg);
    }
  }
  &__menu-icon span {
    top: 50%;
    transform: rotate(0deg) translate(0, -50%);
    .site-header #site-header__toggle:checked ~ & {
      background-color: transparent;
      transform: rotate(0deg) translate(0, 0);
    }
  }
  &__menu-icon:after {
    bottom: 0;
    .site-header #site-header__toggle:checked ~ & {
      bottom: 50%;
      transform: translate(0, 50%) rotate(-45deg);
    }
  }
}
